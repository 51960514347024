

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import urls from '../../../utils/urls';
import * as singleActions from '../../../store/actions/single';
import FormButtons from '../../Common/FormButtons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import StripeButton from '../../Common/StripeButton/StripeButton';

const schema = yup.object({
  name: yup.string().required('This field is required')
});

class EventAdvanced extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      pdf: '',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    //this.props.updateField('image', file);
    this.props.setValues({ image: file });
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(props, state) {
    if (props.single.single !== this.props.single.single) {
      this.init();
    }
  }

  init = () => {
    if (this.props.single.single.image) {
      if (this.props.single.single.image.path) {
        this.setState({
          preview: urls.getS3Url(
            this.props.single.single.image.thumb || this.props.single.single.image.path
          )
        });
      }
    } else if (this.state.preview) {
      this.setState({ preview: '' });
    }
  };

  handleSubmit = (values) => {
    let view = 'events';

    let data = new FormData();
    data.append('files', this.state.image);
    data.append('files', this.state.pdf);

    let params = { event: values };
    if (this.props.single.single._id) {
      params.eventId = this.props.single.single._id;
    }

    if (this.props.single.single._id) {
      params.event._id = this.props.single.single._id;
    }

    data.set('data', JSON.stringify(params));

    if (params.event?._id || params.eventId) {
      this.props.patch(view, params.event?._id ?? params.eventId, data, this.props.auth.token._id);
    } else {
      this.props.post(view, data, this.props.auth.token._id, (id) => {
        this.props.history.push('/app/events/' + id + '/edit/2');
      });
    }
  };

  render() {
    return (
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            name: this.props.single.single.name || '',
            content: this.props.single.single.content || '',
            inPersonContent: this.props.single.single.inPersonContent || '',
            alias: this.props.single.single.alias || {},
            emailContent: this.props.single.single.emailContent || ''
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormButtons
                back={(e) => {
                  e.preventDefault();
                  this.step(values, 'previous');
                }}
                showPrevious={false}
                showBack={true}
                onBack={(e) => {
                  if (this.props.single.single._id) {
                    this.props.history.push(`/app/event-dashboard/${this.props.single.single._id}`);
                  } else {
                    this.props.history.push(`/app/event-dashboard`);
                  }
                }}
                showRevert={false}
                showNext={false}
                confirm={(e) => {
                  e.preventDefault();
                  this.handleSubmit(values);
                }}
                showDefault={true}
              />
              <div className={'field-container'}>
                <label>Registration Page Content</label>
                <ReactQuill
                  theme="snow"
                  value={values.content}
                  onChange={(val) => {
                    setFieldValue('content', val);
                  }}
                />
              </div>

              <div className={'spacer'} />
              <div className={'divider'} />
              <div className={'spacer'} />

              <div className={'field-container'}>
                <label>Registration Email</label>
                <br />
                <i>
                  This content will be emailed to the dancer/parent upon payment. Remember to add
                  useful links, rules, and anything that the dancers should know.
                </i>
                <br />
                <ReactQuill
                  theme="snow"
                  value={values.emailContent}
                  onChange={(val) => {
                    setFieldValue('emailContent', val);
                  }}
                />
              </div>

              <div className={'spacer'} />
              <div className={'divider'} />
              <div className={'spacer'} />

              {this.props.single.single.inPerson ? (
                <div>
                  <div className={'field-container'}>
                    <label>Entry Page Content</label>
                    <ReactQuill
                      theme="snow"
                      value={values.inPersonContent}
                      onChange={(val) => {
                        setFieldValue('inPersonContent', val);
                      }}
                    />
                  </div>

                  <div className={'spacer'} />
                  <div className={'divider'} />
                  <div className={'spacer'} />
                </div>
              ) : null}

              <div className={'field-container'}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Aliases</Form.Label>
                </Form.Group>
              </div>

              <div className={'field-container'}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Grades</Form.Label>
                  <Form.Control
                    type="text"
                    name="judgingEnd"
                    placeholder="Enter Alias"
                    value={values.alias.grades}
                    onChange={(val) => {
                      setFieldValue('alias', {
                        ...values.alias,
                        grades: val.target.value
                      });
                    }}
                    onBlur={handleBlur}
                    className={`form-control`}
                  />
                </Form.Group>
              </div>

              <div className={'field-container'}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Championships</Form.Label>
                  <Form.Control
                    type="text"
                    name="judgingEnd"
                    placeholder="Enter Alias"
                    value={values.alias.championship}
                    onChange={(val) => {
                      setFieldValue('alias', {
                        ...values.alias,
                        championship: val.target.value
                      });
                    }}
                    onBlur={handleBlur}
                    className={`form-control`}
                  />
                </Form.Group>
              </div>

              <div className={'spacer'} />
              <div className={'divider'} />
              <div className={'spacer'} />

              <div className={'field-container'}>
                <label>Connect Stripe Account</label>
                <StripeButton
                  eventId={this.props.single.single._id}
                  stripeAccountId={this.props.single.single?.stripeAccountId}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect, true));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patchFormData(view, id, data, token, false, null, true));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('events', id, token));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EventAdvanced));

class EventAdvancedContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EventAdvancedContainer;
