

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import urls from '../../../utils/urls';
import placeholder from '../../../assets/images/placeholder-person.png';
import * as singleActions from '../../../store/actions/single';
import EntrySteps from './EntrySteps';
import EntryPay from './EntryPay';
import EntryCompetitions from './EntryCompetitions';
import EntryUpload from './EntryUpload';
import EntryInPerson from './EntryInPerson';
import Button from 'react-bootstrap/Button';

class AdminSingleEntry extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    //this.props.updateField('image', file);
    this.props.setValues({ image: file });
  };

  componentDidMount() {
    //this.init({single: {single: {}}});
    let loadBlank = false;

    if (this.props.match.params.view) {
      if (this.props.match.params.view === 'register') {
        loadBlank = true;
      }
    }

    if (loadBlank) {
      //this.props.loadEntryData(this.props.match.params.id, this.props.auth.token._id);
    } else {
      this.props.get(this.props.match.params.id, this.props.auth.token._id);
    }
  }

  componentDidUpdate(props) {
    if (this.props.single.event && !this.state.preview) {
      if (this.props.single.event.image) {
        if (this.props.single.event.image._id) {
          this.setState({
            preview: urls.getS3Url(
              this.props.single.event.image.thumb || this.props.single.event.image.path
            )
          });
        }
      }
    }
  }

  init = (props) => {};

  handleSubmit = (values) => {
    let view = 'schools';

    let data = new FormData();
    data.append('files', this.state.image);

    let params = { school: values };
    params.school.admins = values.admins.map((item) => item._id);
    params.school.moderators = values.moderators.map((item) => item._id);

    if (this.state.createAdmin) {
      params.createAdmin = this.state.createAdmin;
    }

    if (this.state.createModerator) {
      params.createModerator = this.state.createModerator;
    }

    if (this.props.single.single._id) {
      params.school._id = this.props.single.single._id;
    }

    data.set('data', JSON.stringify(params));

    if (params._id) {
      this.props.patch(view, params._id, data, this.props.auth.token._id);
    } else {
      if (this.props.onboarding) {
        this.props.postOnboarding('users/nextStep', data, this.props.auth.token._id, (id) => {
          this.setState({ disableNext: false });
        });
      } else {
        this.props.post(view, data, this.props.auth.token._id, (id) => {
          this.props.history.push('/admin/schools/' + id);
        });
      }
    }
  };

  render() {
    return (
      <div>
        {this.props.single.single ? (
          !isNaN(this.props.single.single.step) ? (
            <div>
              {this.props.single.event ? (
                <div className={'entry-title'}>
                  {this.state.preview ? (
                    <div
                      onClick={(e) => this.setState({ filePickerOpen: true })}
                      className={'artist-img avatar square'}
                      style={{
                        background: this.state.preview
                          ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                          : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                      }}></div>
                  ) : null}
                  <span className={'event-title'}>{this.props.single.event.name}</span>
                </div>
              ) : null}
              <EntrySteps />

              <Button
                variant={'flat'}
                className={'raised elevated'}
                onClick={(e) =>
                  this.props.history.push(
                    `/app/admin/users/${this.props.match.params.userId}/entry/${this.props.match.params.id}/edit`
                  )
                }>
                Edit
              </Button>

              <div
                className={`event-container ${this.props.user.onboardingLoading ? 'loading' : ''}`}>
                {this.props.single.single.step === 0 ? (
                  <div className={''}>
                    <EntryCompetitions />
                  </div>
                ) : null}

                {this.props.single.single.step === 1 ? (
                  <div className={'elevated rounded show-overflow'}>
                    <EntryPay />
                  </div>
                ) : null}

                {this.props.single.single.step === 2 ? (
                  this.props.single.single ? (
                    this.props.single.single.inPerson ? (
                      <EntryInPerson />
                    ) : (
                      <EntryUpload />
                    )
                  ) : null
                ) : null}
              </div>
            </div>
          ) : null
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patch(view, id, data, token));
  },
  get: (id, token) => {
    //dispatch(singleActions.getSingle('entries', id, token));
    dispatch(singleActions.getMultiSingle('entry', 'entries/getForAdmin', id, token));
  },
  setBlankSingle: (d) => {
    dispatch(singleActions.setSingleEntry(d));
  },
  loadEntryData: (eventId, token) => {
    dispatch(singleActions.loadEntryData(eventId, token));
  },
  loadEntryPay: (token) => {
    dispatch(singleActions.loadEntryPay(token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminSingleEntry));

class AdminSingleEntryContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default AdminSingleEntryContainer;
