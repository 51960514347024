

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../../store/actions/table';
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button';
import {CheckmarkDone} from 'react-ionicons';
import { ArrowForward } from 'react-ionicons';
import { ArrowBack } from 'react-ionicons';
import {NotificationManager} from 'react-notifications';

/*
Champ:
R1 HARD - Hornpipe or Treble Jig
R2 SOFT - Reel or Slip Jig
R3 HARD - Set Dance
white_check_mark
eyes
raised_hands
React
Reply

4:01
Grades
SOFT:
Reel
4:01
Light Jig
Single Jig
Slip Jig
4:01
Reel trophies and slip jig specials
4:01
HARD
Treble Jig
Hornpipe
Trad Set
Treble Reel Specials
 */
const hardShoe = ["Treble Jig", "Hornpipe", "Trad Set", "Treble Reel Specials"];
//const softShoe = ["Reel", "Light Jig", "Single Jig", "Slip Jig"];

const checks = [
    {
        label: 'Timing',
        shoe: '',
        key: 'timing'
    },
    {
        label: 'Turnout',
        shoe: '',
        key: 'turnout'
    },
    {
        label: 'Crossed feet',
        shoe: '',
        key: 'crossFeet'
    },
    {
        label: 'Carriage/Posture',
        shoe: '',
        key: 'carriage'
    },
    {
        label: 'Rhythm',
        shoe: 'hard',
        key: 'rhythm'
    },
    {
        label: 'Loud beats',
        shoe: 'hard',
        key: 'loadBeats'
    },
    {
        label: 'Attack',
        shoe: 'hard',
        key: 'attack'
    },
    {
        label: 'Lift/extension',
        shoe: 'soft',
        key: 'lift'
    },
    {
        label: 'Arch',
        shoe: 'soft',
        key: 'arch'
    },
    {
        label: 'Up on Toes',
        shoe: 'soft',
        key: 'onToes'
    },
    {
        label: 'Graceful',
        shoe: 'soft',
        key: 'graceful'
    },
    {
        label: 'Arms In',
        shoe: '',
        key: 'armsIn'
    },
    {
        label: 'Elevation',
        shoe: '',
        key: 'elevation'
    },
    {
        label: 'Movement',
        shoe: '',
        key: 'movement'
    },
    {
        label: 'Execution of Material',
        shoe: '',
        key: 'execution'
    },
    {
        label: 'Foot Placement',
        shoe: '',
        key: 'placement'
    },
    {
        label: 'Style',
        shoe: '',
        key: 'style'
    },
    {
        label: 'Energy',
        shoe: '',
        key: 'energy'
    },
    {
        label: 'Stamina',
        shoe: '',
        key: 'stamina'
    },{
        label: 'Performance',
        shoe: '',
        key: 'performance'
    }

]


class ScoreCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={"scorecard-container elevated rounded ex-pad"}>

                            <Row>
                                <Col xs={6}>
                                    <div className={"spacer"} />
                                    <div className={"spacer-mini"} />
                                    <h3 className={"pt-1"}>Dancer #{this.props.events.competition.single.dancerNumber?
                                        this.props.events.competition.single.dancerNumber
                                        : this.props.events.competition.single.entry ?
                                        this.props.events.competition.single.entry.sections ?
                                            this.props.events.competition.single.entry.sections[0] ?
                                                this.props.events.competition.single.entry.sections[0].dancerNumber
                                                : null
                                            : null

                                    : null}</h3>
                                </Col>
                                <Col xs={6}>

                                    <Form.Group controlId="score" className={""}>
                                        <label>Score</label>

                                        <Form.Control
                                            type="number"
                                            name="score"
                                            min={"0"}
                                            max={"100"}
                                            placeholder="Score"
                                            value={this.props.values.score}
                                            onChange={(e) => {
                                                if (parseInt(e.target.value) > 100) {
                                                    e.target.value = "100";
                                                } else if (parseInt(e.target.value) < 0) {
                                                    e.target.value = "0";
                                                }
                                                this.props.handleChange(e)
                                            }}
                                            onBlur={this.props.handleBlur}
                                            isValid={this.props.touched.score && !this.props.errors.score}

                                        />
                                        <Form.Control.Feedback></Form.Control.Feedback>
                                    </Form.Group>
                                </Col>

                            </Row>

                            <Row className={""}>
                                <Col sm={6}>

                                    Good Job
                                </Col>


                                <Col sm={6} className={"text-right"}>

                                    Needs Work
                                </Col>
                            </Row>
                <div className={"spacer"} />
                <div className={"divider"} />
                <div className={"spacer"} />

                {checks.map((c, i) => {
                    //let championship = false;
                    let shoe = "soft;"
                    if (this.props.events.competition.competition) {
                        if (this.props.events.competition.competition.grade) {
                            if (this.props.events.competition.competition.grade.type === "championship") {
                                //championship = true;
                                if (this.props.match.params.round === "1" || this.props.match.params.round === "3"){
                                    shoe = "hard";
                                } else {
                                    shoe = "soft";
                                }
                            } else {
                                if (this.props.events.competition.single) {
                                    if (this.props.events.competition.single.competition) {
                                        if (hardShoe.indexOf(this.props.events.competition.single.competition.row) > -1) {
                                            shoe = "hard";
                                        } else {
                                            shoe = "soft";
                                        }
                                    }
                                }

                            }
                        }
                    }

                    if(c.shoe === "" || c.shoe === shoe) {
                        return (
                            <div key={i}>
                            <Row className={"text-center"} key={i}>
                                <Col sm={2}>
                                    <Form.Check
                                        type="checkbox"
                                        className="mb-2"
                                        label=""
                                        checked={this.props.values.goodJob[c.key] || false}
                                        onChange={(v) => {
                                            //let round = this.props.match.params.round.toString();
                                            let gjVal = false;
                                            let nwVal = false;
                                            if (!this.props.values.goodJob[c.key]) {
                                                gjVal = true;
                                                nwVal = false;
                                            } else {
                                                gjVal = false;
                                                nwVal = false;
                                            }


                                            this.props.setFieldValue('goodJob',
                                                {
                                                    ...this.props.values.goodJob,
                                                    [c.key]: gjVal
                                                })

                                            this.props.setFieldValue('needsWork',
                                                {
                                                    ...this.props.values.needsWork,
                                                    [c.key]: nwVal
                                                })
                                        }}
                                    />
                                    <div className={"divider"} />
                                    <div className={"spacer-mini"} />
                                </Col>
                                <Col sm={8}>
                                    <label className={"scorecard-label"}>{c.label}</label>
                                    <div className={"divider"} />
                                    <div className={"spacer-mini"} />
                                </Col>
                                <Col sm={2} className={"text-right"}>
                                    <Form.Check
                                        type="checkbox"
                                        className="mb-2"
                                        label=""
                                        checked={this.props.values.needsWork[c.key] || false}
                                        onChange={(v) => {
                                            //let round = this.props.match.params.round.toString();
                                            let gjVal = false;
                                            let nwVal = false;
                                            if (!this.props.values.needsWork[c.key]) {
                                                gjVal = false;
                                                nwVal = true;
                                            } else {
                                                gjVal = false;
                                                nwVal = false;
                                            }


                                            this.props.setFieldValue('goodJob',
                                                {
                                                    ...this.props.values.goodJob,
                                                    [c.key]: gjVal
                                                })

                                            this.props.setFieldValue('needsWork',
                                                {
                                                    ...this.props.values.needsWork,
                                                    [c.key]: nwVal
                                                })

                                        }}
                                    />
                                    <div className={"divider"} />
                                    <div className={"spacer-mini"} />
                                </Col>

                            </Row>

                            </div>
                        )
                    }else {
                        return null;
                    }
                })}

                <Row>
                    <Col xs={12}>
                        <div className={"text-center"}>
                            <div className={"spacer"} />
                            <Button variant={"flat"} onClick={() => {
                                //let currentIndex = this.props.events.competition.entries.map(e => e._id).indexOf(this.props.match.params.id);
                                let url;
                                this.props.setFieldValue('nextUrl', url);

                                if (!this.props.values.score) {
                                    NotificationManager.error('You need to score this entry');
                                } else {
                                    this.props.handleSubmit(this.props.values, url)
                                }
                            }}><CheckmarkDone /> Save</Button>
                            <div className={"spacer"} />
                        </div>
                    </Col>
                </Row>
                            <Row>
                                <Col xs={6}>
                                    <div className={""}>
                                        <Button variant={"primary"} onClick={() => {
                                            let url;

                                            url = `/app/judge/${this.props.events.competition.competition.event}/${this.props.events.competition.competition._id}`;

                                            //console.log
                                            this.props.setFieldValue('nextUrl', url);

                                            if (!this.props.values.score) {
                                                NotificationManager.error('You need to score this entry');
                                            } else {
                                                this.props.handleSubmit(this.props.values, url)
                                            }
                                        }}><ArrowBack style={{fill: '#ffffff'}} />back to overview</Button>
                                        <div className={"spacer"} />
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className={"text-right"}>
                                        <Button variant={"primary"} onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            let currentIndex = this.props.events.competition.entries.map(e => e._id).indexOf(this.props.match.params.id);
                                            let nextVideo, url;
                                            if (this.props.events.competition.entries.length >= currentIndex) {
                                                nextVideo = this.props.events.competition.entries[currentIndex + 1];
                                                if (nextVideo) {
                                                    url = `/app/j/${this.props.match.params.round}/${nextVideo._id}`;
                                                } else {
                                                    if (this.props.events.competition.grade.rounds === 1) {
                                                        url = `/app/judge/${this.props.events.competition.competition.event}/${this.props.events.competition.competition._id}`;
                                                    } else {
                                                        let p = parseInt(this.props.match.params.round);
                                                        if (p < this.props.events.competition.competition.grade.rounds) {
                                                            url = `/app/j/${p + 1}/${this.props.events.competition.entries[0]._id}`;
                                                        } else {
                                                            url = `/app/judge/${this.props.events.competition.competition.event}/${this.props.events.competition.competition._id}`;
                                                        }
                                                    }
                                                }
                                            }
                                            //console.log
                                            this.props.setFieldValue('nextUrl', url);

                                            if (!this.props.values.score) {
                                                NotificationManager.error('You need to score this entry');
                                            } else {
                                                this.props.handleSubmit(this.props.values, url)
                                            }
                                        }}>Save & Next <ArrowForward style={{fill: '#ffffff'}} /></Button>
                                        <div className={"spacer"} />
                                    </div>
                                </Col>
                            </Row>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ScoreCard));

class ScoreCardContainer extends React.Component {
    render() {
        return (

                <Connected {...this.props} />

        )
    }
}

export default ScoreCardContainer;