

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../../store/actions/table';
import Leaderboard from './Leaderboard';
import Video from './Video';
import ScoreCard from './ScoreCard';
import {withFormik} from "formik";
import * as eventActions from "../../../../store/actions/events";


class Entry extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.get(this.props.match.params.id, this.props.auth.token._id);
        if (this.props.events.competition) {
            if (this.props.events.competition.judgeEvent && this.props.events.competition._id === this.props.match.params.id) {
                if (this.props.events.competition.judgeEvent.completed) {
                    if (this.props.events.competition.judgeEvent.completed.indexOf(this.props.match.params.competition) > -1) {
                        this.props.history.push(`/app/judge/${this.props.match.params.id}`);
                    }
                }
            }
        }
    }

    componentDidUpdate(props, state) {
        if (props.match.params.id !== this.props.match.params.id) {
            this.props.get(this.props.match.params.id, this.props.auth.token._id);
        }else if(props.events.competition && this.props.events.competition) {
            if (props.events.competition.single && this.props.events.competition.single) {
                if (props.events.competition.single !== this.props.events.competition.single) {
                    if (props.events.competition.single.nextUrl !== this.props.events.competition.single.nextUrl && this.props.events.competition.single.nextUrl) {
                        this.props.history.push(this.props.events.competition.single.nextUrl)
                    }
                }
            }
        }

        if (this.props.events.competition) {
            if (this.props.events.competition.judgeEvent) {
                if (this.props.events.competition.judgeEvent.completed) {
                    if (this.props.events.competition.single) {
                        if (this.props.events.competition.single.competition) {
                            if (this.props.events.competition.judgeEvent.completed.indexOf(this.props.events.competition.single.competition._id) > -1) {
                                this.props.history.push(`/app/judge/${this.props.events.competition.single.event._id}`);
                            }
                        }
                    }
                }
            }
        }

    }

    render() {
        if (this.props.events.competition.single && this.props.events.competition.competition) {
            if (this.props.events.competition.single._id === this.props.match.params.id) {
                return (
                    <div className={'page-container'}>
                        <h4>
                            Comp #{this.props.events.competition.competition.code ? this.props.events.competition.competition.code : ''} |&nbsp;
                            {this.props.events.competition.single.event ? this.props.events.competition.single.event.name : ''} |&nbsp;
                            {this.props.events.competition.competition.row ? this.props.events.competition.competition.rowTitle : ''} -&nbsp;
                            {this.props.events.competition.competition.grade ? this.props.events.competition.competition.grade.title : ''} -&nbsp;
                            {this.props.events.competition.competition.column ? this.props.events.competition.competition.columnTitle : ''}

                            {this.props.events.competition.single.rounds > 1 ?
                                <span> |&nbsp;
                                    {this.props.match.params.round === "1" ?
                                        <span>R1</span>
                                    :
                                        <Link to={`/app/j/1/${this.props.events.competition.single._id}`}>R1</Link>
                                    }&nbsp;
                                    {this.props.match.params.round === "2" ?
                                        <span>R2</span>
                                        :
                                        <Link to={`/app/j/2/${this.props.events.competition.single._id}`}>R2</Link>
                                    }&nbsp;
                                    {this.props.events.competition.single.rounds >= 3 ? this.props.match.params.round === "3" ?
                                        <span>R3</span>
                                        :
                                        <Link to={`/app/j/3/${this.props.events.competition.single._id}`}>R3</Link>
                                        : null
                                    }
                                </span>

                                : ''}
                        </h4>
                        <div className={"spacer"} />
                        <Leaderboard />
                        <Video {...this.props} />
                        <ScoreCard  {...this.props} onSubmit={(values) => {
                            //console.log
                            this.props.handleSubmit(values);
                        }} />




                    </div>
                )
            } else {
                return null;
            }
        }else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    single: state.single,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    get: (id, token) => {
        dispatch(eventActions.getSingle(id, token));
    },
    submitScore: (params, token) => {
        dispatch(eventActions.submitScore(params, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(withFormik({
    mapPropsToValues: (props) => ({
        comments: props.events.competition.single ?
            props.events.competition.single.score ? props.events.competition.single.score["round" + props.match.params.round.toString() + "Comments"] ?
                props.events.competition.single.score["round" + props.match.params.round.toString() + "Comments"] : '' : '' : '',
        _id: props.events.competition.single ?
            props.events.competition.single.score._id : '',
        needsWork: props.events.competition.single ?
            props.events.competition.single.score ? props.events.competition.single.score["round" + props.match.params.round.toString() + "NeedsWork"] ?
                props.events.competition.single.score["round" + props.match.params.round.toString() + "NeedsWork"] : {} : {} : {},
        goodJob: props.events.competition.single ?
            props.events.competition.single.score ? props.events.competition.single.score["round" + props.match.params.round.toString() + "GoodJob"] ?
                props.events.competition.single.score["round" + props.match.params.round.toString() + "GoodJob"] : {} : {} : {},
        score: props.events.competition.single ?
            props.events.competition.single.score ? props.events.competition.single.score["round" + props.match.params.round.toString() + "Score"] ?
                props.events.competition.single.score["round" + props.match.params.round.toString() + "Score"] : 0 : 0 : 0,
        nextUrl: '',
        round: props.match.params.round.toString()
    }),

    // Custom sync validation
    validate: values => {
        const errors = {};

        if (!values.score) {
            errors.score = 'Required';
            //NotificationManager.error('You need to add a score');
        }

        return errors;
    },
    enableReinitialize: true,

    handleSubmit: (values, { props, setSubmitting, url }) => {
        //console.log
        let params = {
            data: values,
            entries: props.events.competition.entries.map(e => e.score)
        }

        props.submitScore(params, props.auth.token._id);

    },

    displayName: 'BasicForm',
})(Entry)));

class EntryContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default EntryContainer;
