import React from 'react';
import { Text, View, StyleSheet, Font } from '@react-pdf/renderer';

const TableHeader = ({ judges, events, final }) => {
    //console.log(judges)
    let r = events.competition.competition.grade.scoring.rounds;
    let panelType = events.competition.competition.grade.scoring.judge_rotation_type;
    let judgeCountArray = events.competition.competition.grade.scoring.judge_count;
    let judgeCount1 = judgeCountArray[0];
    let judgeCount2 = (r >= 2) ? judgeCountArray[1] : 0;
    let judgeCount3 = (r >= 3) ? judgeCountArray[2] : 0;
    let rotating = panelType === "ROTATING";
    let set = panelType === "SET";
    let split = panelType === "SPLIT";
    let teams = events.competition.competition.grade.type.toLowerCase().includes('team');
    let finalJudgeNum = judgeCount1 + 1;
    let finalJudgeIndex = (judgeCount1 * 2); 
    let rotRound2JudgeNum = judgeCount1 + 1;
    let rotRound2JudgeIndex = judgeCount1;
    let rotRound3JudgeNum = judgeCount1 + judgeCount2 + 1;
    let rotRound3JudgeIndex = judgeCount1 + judgeCount2;
    let recall = events.competition.competition.grade.recallType !== 'NONE';
    let showIp = events.competition.competition.grade.scoring.showIp || false;
    let nq = events.competition.competition.grade.nationalQualifier;
    let wq = events.competition.competition.grade.worldQualifier;


  return (
    <View style={styles.wrap}>
      <View style={[styles.titleWrap, {width: "10%", borderLeft: "none"}]}>
          <Text style={styles.title}>Final Rank</Text>
      </View>
      <View style={[styles.titleWrap, {width: "10%"}]}>
        <Text style={styles.title}>Dancer</Text>
        <Text style={styles.title}>Number</Text>
      </View>
      {teams ? 
        <View style={[styles.titleWrap, { width: "20%" }]}>
          <Text style={styles.title}>Team</Text>
        </View>
          :
        <View style={[styles.titleWrap, { width: "20%" }]}>
          <Text style={styles.title}>Dancer Name</Text>
        </View>
        }
      <View style={[styles.titleWrap, { width: "25%" }]}>
        <Text style={styles.title}>School</Text>
      </View>
      {rotating ? 
        <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R1</Text>
            <Text style={styles.smallerFont}>ADJ 1 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[0]}</Text>
          </View>
        : null}
      {rotating && judgeCount1 >= 2 ? 
        <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R1</Text>
            <Text style={styles.smallerFont}>ADJ 2 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[1]}</Text>
          </View>
        : null}
      {rotating && judgeCount1 >= 3 ? 
        <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R1</Text>
            <Text style={styles.smallerFont}>ADJ 3 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[2]}</Text>
          </View>
        : null}
        {rotating && judgeCount1 >= 4 ? 
          <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R1</Text>
            <Text style={styles.smallerFont}>ADJ 4 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[3]}</Text>
          </View>
        : null}
        {rotating && judgeCount1 >= 5 ? 
          <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R1</Text>
            <Text style={styles.smallerFont}>ADJ 5 Score/IP</Text>
            <Text style={styles.judgeName}>{judges[4]}</Text>
          </View>
        : null}
      {rotating && showIp ? 
        <View style={[styles.titleWrap, {backgroundColor: "#FFE08C", width: "4%" }]}>
          <Text style={styles.title}>Total IP R1</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#FFD45E", width: "4%" }]}>
          <Text style={styles.title}>Rank after R1</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R2</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound2JudgeNum} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound2JudgeIndex]}</Text>
          </View>
        : null}
      {rotating && judgeCount2 >= 2 ? 
        <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R2</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound2JudgeNum + 1} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound2JudgeIndex + 1]}</Text>
          </View>
        : null}
      {rotating && judgeCount2 >= 3 ? 
        <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R2</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound2JudgeNum + 2} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound2JudgeIndex + 2]}</Text>
          </View>
        : null}
        {rotating && judgeCount2 >= 4 ? 
          <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R2</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound2JudgeNum + 3} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound2JudgeIndex + 3]}</Text>
          </View>
        : null}
        {rotating && judgeCount2 >= 5 ? 
          <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R2</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound2JudgeNum + 4} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound2JudgeIndex + 4]}</Text>
          </View>
        : null}
      {rotating && showIp ? 
        <View style={[styles.titleWrap, {backgroundColor: "#DBF7CD", width: "4%" }]}>
          <Text style={styles.title}>Total IP R2</Text>
        </View>
        : null}
      {rotating && showIp ? 
        <View style={[styles.titleWrap, {backgroundColor: "#C5F7AD", width: "4%" }]}>
          <Text style={styles.title}>Total IP R1 + R2</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#B6FF94", width: "4%" }]}>
          <Text style={styles.title}>Rank after R2</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#C7EBFC", width: "4%" }]}>
          <Text style={styles.title}>Recall Status (Y/N)</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R3</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound3JudgeNum} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound3JudgeIndex]}</Text>
          </View>
        : null}
      {rotating && judgeCount2 >= 2 ? 
        <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R3</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound3JudgeNum + 1} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound3JudgeIndex + 1]}</Text>
          </View>
        : null}
      {rotating && judgeCount2 >= 3 ? 
        <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R3</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound3JudgeNum + 2} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound3JudgeIndex + 2]}</Text>
          </View>
        : null}
        {rotating && judgeCount2 >= 4 ? 
          <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R3</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound3JudgeNum + 3} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound3JudgeIndex + 3]}</Text>
          </View>
        : null}
        {rotating && judgeCount2 >= 5 ? 
          <View style={[styles.titleWrap, { width: "4.5%" }]}>
            <Text style={styles.title}>R3</Text>
            <Text style={styles.smallerFont}>ADJ {rotRound3JudgeNum + 4} Score/IP</Text>
            <Text style={styles.judgeName}>{judges[rotRound3JudgeIndex + 4]}</Text>
          </View>
        : null}
      {rotating && showIp ? 
        <View style={[styles.titleWrap, {backgroundColor: "#FFCCE0", width: "4%" }]}>
          <Text style={styles.title}>Total IP R3</Text>
        </View>
        : null}
      {rotating && showIp ? 
        <View style={[styles.titleWrap, {backgroundColor: "#F7A8C7", width: "4.3%" }]}>
          <Text style={styles.title}>Total IP All Rounds</Text>
        </View>
        : null}
      {rotating ? 
        <View style={[styles.titleWrap, {backgroundColor: "#FF6EA5", width: "4%" }]}>
          <Text style={styles.title}>Placed Y/N</Text>
        </View>
        : null}
      {rotating && nq ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
          <Text style={styles.title}>NQ Y/N</Text>
        </View>
        : null}
      {rotating && wq? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
          <Text style={styles.title}>WQ Y/N</Text>
        </View>
        : null}
        {set && !final ?
          <View style={[styles.titleWrap, { width: "8%" }]}>
            <Text style={styles.title}>ADJ 1</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[0]}</Text>
          </View>
        : null}

        {set && !final && r >= 2 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 1</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[0]}</Text>
            </View>
        : null}

        {set && !final && r >= 3 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 1</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[0]}</Text>
            </View>
        : null}

        {split && !final && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>ADJ 1</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[0]}</Text>
          </View>
        : null}

        {set && !final && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "4%" }]}>
              <Text style={styles.title}>ADJ 1 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount1 > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "4.5%" }]}>
              <Text style={styles.title}>ADJ 1 IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFD45E", width: "4%" }]}>
              <Text style={styles.title}>ADJ 1 Rank</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "4%" }]}>
              <Text style={styles.title}>ADJ 1 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFD45E", width: "6%" }]}>
              <Text style={styles.title}>ADJ 1 Rank/IP</Text>
            </View>
        : null}

      {set && !final && judgeCount1 >= 2?
          <View style={[styles.titleWrap, { width: "3.5%" }]}>
            <Text style={styles.title}>ADJ 2</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[1]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 2 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 2</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[1]}</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 2 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 2</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[1]}</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 2 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>ADJ 2</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[1]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 2 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "4%" }]}>
              <Text style={styles.title}>ADJ 2 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount1 >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "4.5%" }]}>
              <Text style={styles.title}>ADJ 2 IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C5F7AD", width: "4%" }]}>
              <Text style={styles.title}>ADJ 2 Rank</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "4%" }]}>
              <Text style={styles.title}>ADJ 2 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 2 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C5F7AD", width: "6%" }]}>
              <Text style={styles.title}>ADJ 2 Rank/IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 3?
          <View style={[styles.titleWrap, { width: "3.5%" }]}>
            <Text style={styles.title}>ADJ 3</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[2]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 3 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 3</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[2]}</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 3 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 3</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[2]}</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 3 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>ADJ 3</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[2]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 3 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#D3F5F1", width: "4%" }]}>
              <Text style={styles.title}>ADJ 3 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount1 >= 3 ?
              <View style={[styles.titleWrap, { backgroundColor: "#D3F5F1", width: "4.5%" }]}>
              <Text style={styles.title}>ADJ 3 IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 3 ?
              <View style={[styles.titleWrap, { backgroundColor: "#B9FAF3", width: "4%" }]}>
              <Text style={styles.title}>ADJ 3 Rank</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 3 ?
              <View style={[styles.titleWrap, { backgroundColor: "#D3F5F1", width: "4%" }]}>
              <Text style={styles.title}>ADJ 3 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 3 ?
              <View style={[styles.titleWrap, { backgroundColor: "#B9FAF3", width: "6%" }]}>
              <Text style={styles.title}>ADJ 3 Rank/IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 4?
          <View style={[styles.titleWrap, { width: "3.5%" }]}>
            <Text style={styles.title}>ADJ 4</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[3]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 4 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 4</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[3]}</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 4 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 4</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[3]}</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 4 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>ADJ 4</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[3]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 4 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C7EBFC", width: "4%" }]}>
              <Text style={styles.title}>ADJ 4 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount1 >= 4 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C7EBFC", width: "4.5%" }]}>
              <Text style={styles.title}>ADJ 4 IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 4 ?
              <View style={[styles.titleWrap, { backgroundColor: "#ABDEF7", width: "4%" }]}>
              <Text style={styles.title}>ADJ 4 Rank</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 4 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C7EBFC", width: "4%" }]}>
              <Text style={styles.title}>ADJ 4 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 4 ?
              <View style={[styles.titleWrap, { backgroundColor: "#ABDEF7", width: "6%" }]}>
              <Text style={styles.title}>ADJ 4 Rank/IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 5?
          <View style={[styles.titleWrap, { width: "3.5%" }]}>
            <Text style={styles.title}>ADJ 5</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[4]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 5 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 5</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[4]}</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 5 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 5</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[4]}</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 5 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>ADJ 5</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[4]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 5 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#ECD4FA", width: "4%" }]}>
              <Text style={styles.title}>ADJ 5 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount1 >= 5 ?
              <View style={[styles.titleWrap, { backgroundColor: "#ECD4FA", width: "4.5%" }]}>
              <Text style={styles.title}>ADJ 5 IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 5 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DEB2F7", width: "4%" }]}>
              <Text style={styles.title}>ADJ 5 Rank</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 5 ?
              <View style={[styles.titleWrap, { backgroundColor: "#ECD4FA", width: "4%" }]}>
              <Text style={styles.title}>ADJ 5 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 5 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DEB2F7", width: "6%" }]}>
              <Text style={styles.title}>ADJ 5 Rank/IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 6?
          <View style={[styles.titleWrap, { width: "3.5%" }]}>
            <Text style={styles.title}>ADJ 6</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[5]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 6 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 6</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[5]}</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 6 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 6</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[5]}</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 6 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>ADJ 6</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[5]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 6 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "4%" }]}>
              <Text style={styles.title}>ADJ 6 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount1 >= 6 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "4.5%" }]}>
              <Text style={styles.title}>ADJ 6 IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 6 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFD45E", width: "4%" }]}>
              <Text style={styles.title}>ADJ 6 Rank</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 6 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "4%" }]}>
              <Text style={styles.title}>ADJ 6 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 6 ?
              <View style={[styles.titleWrap, { backgroundColor: "#FFD45E", width: "6%" }]}>
              <Text style={styles.title}>ADJ 6 Rank/IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 7?
          <View style={[styles.titleWrap, { width: "3.5%" }]}>
            <Text style={styles.title}>ADJ 7</Text>
            <Text style={styles.smallerFont}>R1 Score</Text>
            <Text style={styles.judgeName}>{judges[6]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 7 && r >= 2 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 7</Text>
                <Text style={styles.smallerFont}>R2 Score</Text>
                <Text style={styles.judgeName}>{judges[6]}</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 7 && r >= 3 ?
            <View style={[styles.titleWrap, { width: "3.5%" }]}>
                <Text style={styles.title}>ADJ 7</Text>
                <Text style={styles.smallerFont}>R3 Score</Text>
                <Text style={styles.judgeName}>{judges[6]}</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 7 && r >= 2 ?
          <View style={[styles.titleWrap, { width: "6%" }]}>
            <Text style={styles.title}>ADJ 7</Text>
            <Text style={styles.smallerFont}>R1/R2 Score</Text>
            <Text style={styles.judgeName}>{judges[6]}</Text>
          </View>
        : null}

        {set && !final && judgeCount1 >= 7 && r > 1 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "4%" }]}>
              <Text style={styles.title}>ADJ 7 Total Score</Text>
            </View>
        : null}

        {set && !final && showIp && judgeCount1 >= 7 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "4.5%" }]}>
              <Text style={styles.title}>ADJ 7 IP</Text>
            </View>
        : null}

        {set && !final && judgeCount1 >= 7 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C5F7AD", width: "4%" }]}>
              <Text style={styles.title}>ADJ 7 Rank</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 7 ?
              <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "4%" }]}>
              <Text style={styles.title}>ADJ 7 Subtotal</Text>
            </View>
        : null}

        {split && !final && judgeCount1 >= 7 ?
              <View style={[styles.titleWrap, { backgroundColor: "#C5F7AD", width: "6%" }]}>
              <Text style={styles.title}>ADJ 7 Rank/IP</Text>
            </View>
        : null}

      {set && !final && showIp ? 
        <View style={[styles.titleWrap, { backgroundColor: "#FFCCE0", width: "5%" }]}>
            <Text style={styles.title}>Total IP</Text>
        </View>
        : null }
      {split && !final ?
        <View style={[styles.titleWrap, { backgroundColor: "#FFCCE0", width: "5%" }]}>
        <Text style={styles.title}>Total IP 2 Rounds</Text>
        </View>
        : null
      }
      {split && !final ?
        <View style={[styles.titleWrap, { backgroundColor: "#F7A8C7", width: "3.3%" }]}>
          <Text style={styles.title}>2 Round Rank</Text>
        </View>
        : null
      }
      {set && !final ? 
        <View style={[styles.titleWrap, { width: "27%" }]}>
          <Text style={styles.title}>Comments</Text>
        </View> : null }

      {set && nq ? 
      <View style={[styles.titleWrap, { backgroundColor: "#FC1C97", width: "4%" }]}>
        <Text style={styles.title}>NQ Y/N</Text>
      </View> : null}
      {set && wq ? 
      <View style={[styles.titleWrap, { backgroundColor: "#FC1C97", width: "4%" }]}>
        <Text style={styles.title}>WQ Y/N</Text>
      </View> : null}

      {split && !final && recall ?
        <View style={[styles.titleWrap, { backgroundColor: "#FC1C97", width: "3.3%" }]}>
          <Text style={styles.title}>Recall Y/N</Text>
        </View> : null
        }

      {final ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>ADJ {finalJudgeNum}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex]}</Text>
        </View>  
        : null
      }

      {final ?
          <View style={[styles.titleWrap, { backgroundColor: "#FFE08C", width: "6%" }]}>
          <Text style={styles.title}>ADJ {finalJudgeNum} Rank/IP</Text>
        </View>
      : null}

      {final && judgeCount3 >= 2 ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>ADJ {finalJudgeNum + 1}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex + 1]}</Text>
        </View>  
        : null
      }

      {final && judgeCount3 >= 2 ?
          <View style={[styles.titleWrap, { backgroundColor: "#DBF7CD", width: "6%" }]}>
          <Text style={styles.title}>ADJ {finalJudgeNum + 1} Rank/IP</Text>
        </View>
      : null}

      {final && judgeCount3 >= 3 ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>ADJ {finalJudgeNum + 2}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex + 2]}</Text>
        </View>  
        : null
      }

      {final && judgeCount3 >= 3 ?
          <View style={[styles.titleWrap, { backgroundColor: "#D3F5F1", width: "6%" }]}>
          <Text style={styles.title}>ADJ {finalJudgeNum + 2} Rank/IP</Text>
        </View>
      : null}

      {final && judgeCount3 >= 4 ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>ADJ {finalJudgeNum + 3}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex + 3]}</Text>
        </View>  
        : null
      }

      {final && judgeCount3 >= 4 ?
          <View style={[styles.titleWrap, { backgroundColor: "#C7EBFC", width: "6%" }]}>
          <Text style={styles.title}>ADJ {finalJudgeNum + 3} Rank/IP</Text>
        </View>
      : null}

      {final && judgeCount3 >= 5 ? 
        <View style={[styles.titleWrap, { width: "4%" }]}>
            <Text style={styles.title}>ADJ {finalJudgeNum + 4}</Text>
            <Text style={styles.smallerFont}>R3 Score</Text>
            <Text style={styles.judgeName}>{judges[finalJudgeIndex + 4]}</Text>
        </View>  
        : null
      }

      {final && judgeCount3 >= 5 ?
          <View style={[styles.titleWrap, { backgroundColor: "#ECD4FA", width: "6%" }]}>
          <Text style={styles.title}>ADJ {finalJudgeNum + 4} Rank/IP</Text>
        </View>
      : null}

      {final ? 
        <View style={[styles.titleWrap, { backgroundColor: "#F7E9F1", width: "5%" }]}>
            <Text style={styles.title}>Total IP R1 & R2</Text>
        </View>
        : null }
      {final ?
        <View style={[styles.titleWrap, { backgroundColor: "#FFCCE0", width: "5%" }]}>
        <Text style={styles.title}>Total IP R3</Text>
        </View>
        : null
      }
      {final ? 
        <View style={[styles.titleWrap, { backgroundColor: "#F7A8C7", width: "6%" }]}>
          <Text style={styles.title}>Total IP All Rounds</Text>
        </View>
        : null }
      {final ?
        <View style={[styles.titleWrap, { backgroundColor: "#FF6EA5", width: "4%" }]}>
          <Text style={styles.title}>Final Rank</Text>
        </View>
        : null
      }
      {final ? 
        <View style={[styles.titleWrap, { backgroundColor: "#FC1C97", width: "4%" }]}>
          <Text style={styles.title}>Placed Y/N</Text>
        </View> : null }

      {final && nq ? 
      <View style={[styles.titleWrap, { backgroundColor: "#FC1C97", width: "4%" }]}>
        <Text style={styles.title}>NQ Y/N</Text>
      </View> : null}
      {final && wq ? 
      <View style={[styles.titleWrap, { backgroundColor: "#FC1C97", width: "4%" }]}>
        <Text style={styles.title}>WQ Y/N</Text>
      </View> : null}

    </View>
  )
}

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf"
});


// Create styles
const styles = StyleSheet.create({
  wrap: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  titleWrap: {
    borderBottom: "2px solid black",
    borderRight: "1px solid black",
    borderTop: "2px solid black",
    display: "flex",
    flexDirection: "column",
    height: "auto",
    textAlign: "center",
    width: "5%",
  },
  title: {
    //fontFamily: "Roboto",
    fontSize: 12,
    fontWeight: 900,
    padding: 0,
    paddingBottom: "3px",
  },
  smallerFont: {
    fontSize: 10,
    paddingBottom: "10px",
  },
  judgeName: {
    borderTop: "1px solid black",
    fontSize: 10,
    fontWeight: 900,
    padding: "5px 0",
  },
});

export default TableHeader;