

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

class Signup extends React.Component {
    render() {
        return (
            <div>
                <h1>Signup</h1>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Signup));

class SignupContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected/>
            </div>
        )
    }
}

export default SignupContainer;