

import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Formik} from 'formik';
import * as yup from 'yup';
import * as authActions from '../../../store/actions/auth';
import { ArrowForward } from 'react-ionicons';

const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("This field is required"),
    password: yup.string().required("This field is required")
});

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false
        }
    }

    handleSubmit = (values) => {
        this.setState({submitting: true});
        this.props.login(values);
    }

    componentDidUpdate(props) {
        if (props.auth.attempts < this.props.auth.attempts) {
            this.setState({submitting: false});
        }
    }

    render() {
        return (
            <div className={`login-form ex-pad`}>

                <h4 className="auth-title">Login</h4>
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        isValid,
                        errors,
                        isSubmitting
                    }) => (

                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}

                                />
                                <Form.Control.Feedback></Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.password && !errors.password}
                                />
                                <Form.Control.Feedback></Form.Control.Feedback>
                            </Form.Group>

                            <div className={"hint-text text-center auth-hint"}>
                                Don't have an account yet?  <Link to={"/a/register"}>Register here</Link>.<br />
                                Forgot your password?  <Link to={"/a/forgot-password"}>Click here</Link>.
                            </div>
                            <div className={`text-center mb-20`}>
                                <Button variant="primary" disabled={this.state.submitting} type="submit">
                                    Log in &nbsp; <ArrowForward />
                                </Button>
                            </div>
                        </Form>
                    )}

                </Formik>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    login: (credentials) => {
        dispatch(authActions.login(credentials));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));

class LoginContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected/>
            </div>
        )
    }
}

export default LoginContainer;