

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as tableActions from '../../../../store/actions/table';
import Button from 'react-bootstrap/Button';
import { ArrowBack, Clipboard, ClipboardOutline, CloudUpload, Refresh } from 'react-ionicons';
import { BarChart, BarChartOutline } from 'react-ionicons';
import { Trophy, TrophyOutline } from 'react-ionicons';
import { People, PeopleOutline } from 'react-ionicons';
import * as eventActions from '../../../../store/actions/events';
import { Reader, ReaderOutline } from 'react-ionicons';
import { Build, BuildOutline } from 'react-ionicons';
import urls from '../../../../utils/urls';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { NotificationManager } from 'react-notifications';
import * as reportActions from '../../../../store/actions/reports';

const iconStyle = {
  fontSize: '64px',
  height: '64px',
  width: '64px',
  marginTop: '50px'
};

class PrintMaterials extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      item: '',
      schedule: null,
      name: ''
    };
  }

  componentDidMount(props) {
    this.props.getSingleEvent(this.props.match.params.id, this.props.auth.token._id);
    this.props.getGeneratedData(this.props.match.params.id, this.props.auth.token._id);
    console.log(this.props.reports.generatedReports);
  }

  componentDidUpdate(props, state) {
    if (props.events.event) {
      if (props.events.event._id !== this.props.events.event._id) {
        this.props.getSingleEvent(this.props.match.params.id, this.props.auth.token._id);
      }
    }
  }

  exportTypes = () => [
    {
      name: 'Dancer Numbers',
      id: 'dancerNumbers'
    },
    {
      name: 'Judging Sheets',
      id: 'judgingSheets'
    },
    {
      name: 'Side Stage Checkins',
      id: 'checkins'
    },
    {
      name: 'Late Entries',
      id: 'lateEntries'
    },
    {
      name: 'Stage Headers',
      id: 'stageHeaders'
    }
  ];

  grid = [
    {
      title: 'Dancer Numbers',
      icon: <Build key={1} {...iconStyle} />,
      outline: <BuildOutline key={1} {...iconStyle} />,
      link: 'edit/1'
    },
    {
      title: 'Judging Sheets',
      icon: <BarChart key={1} {...iconStyle} />,
      outline: <BarChartOutline key={1} {...iconStyle} />,
      link: 'stats'
    },
    {
      title: 'Side Stage Checkins',
      icon: <Clipboard key={1} {...iconStyle} />,
      outline: <ClipboardOutline key={1} {...iconStyle} />,
      link: 'competitions'
    },
    {
      title: 'Late Entries',
      icon: <People key={1} {...iconStyle} />,
      outline: <PeopleOutline key={1} {...iconStyle} />,
      link: 'judges'
    },
    {
      title: 'Stage Headers',
      icon: <Trophy key={1} {...iconStyle} />,
      outline: <TrophyOutline key={1} {...iconStyle} />,
      link: 'results'
    }
  ];
  duration = 300;

  defaultStyle = {
    transition: `opacity ${this.duration}ms ease-in-out`,
    opacity: 0
  };

  transitionStyles = {
    entering: { opacity: 1 },
    entered: { opacity: 1 },
    exiting: { opacity: 0 },
    exited: { opacity: 0 }
  };

  handleSubmit = (values) => {
    let data = new FormData();

    if (!this.state.schedule) {
      NotificationManager.error('No valid file found.');
      return;
    }

    data.append('files', this.state.schedule);
    data.append('event', this.props.match.params.id);
    console.log(this.props.match.params.id, this.props.auth.token._id);
    this.props.uploadSchedule(this.props.match.params.id, data, this.props.auth.token._id);
  };

  render() {
    return (
      <div>
        <div className={'page-header'}>
          <Row>
            <Col>
              <h1>
                {this.props.location.pathname.split('/').length > 3 ? (
                  <Button
                    variant={'flat btn-round'}
                    onClick={(e) => {
                      this.props.history.push('/app/event-dashboard/' + this.props.match.params.id);
                    }}>
                    <ArrowBack />
                  </Button>
                ) : null}

                {this.props.events.event ? this.props.events.event.name : ''}
              </h1>
            </Col>
          </Row>
        </div>

        <div className={'text-center'}>
          {!this.props.events.event.schedule ? (
            <div>
              <h4>Upload Schedule</h4>
              <p>
                Before you will be able to generate print materials, you need to upload the FeisFWD
                schedule spreadsheet.
              </p>
              <div className={'spacer'} />
              <div className={'spacer'} />
            </div>
          ) : null}

          <Formik
            onSubmit={this.handleSubmit}
            enableReinitialize
            initialValues={{
              file: ''
            }}>
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
              isSubmitting,
              setFieldValue
            }) => (
              <Form noValidate onSubmit={handleSubmit} className={``}>
                <div className={'video-upload schedule-upload'}>
                  <div className={'video-upload-name'}>
                    <div>
                      <span className={'btn btn-flat btn-video-upload'}>Select File</span> &nbsp;{' '}
                      {this.state.name || 'No File Selected'}
                    </div>
                  </div>

                  <Form.Control
                    type="file"
                    name="image"
                    placeholder="Upload file"
                    onChange={(e) => {
                      console.log('Type', e.target.files[0].type);
                      if (e.target.files[0]) {
                        if (e.target.files[0].size * 0.000001 > 299) {
                          NotificationManager.error('File is too large');
                        } else if (e.target.files[0].name.indexOf('xlsx') === -1) {
                          NotificationManager.error('Not a valid file type');
                        } else {
                          this.setState({
                            //preview: URL.createObjectURL(e.target.files[0]),
                            schedule: e.target.files[0],
                            name: e.target.files[0].name
                          });
                          e.target.value = null;
                        }
                      }
                    }}
                    accept="application/*"
                    onBlur={handleBlur}
                    className={`form-control hide-video-input`}
                  />
                </div>
                <Button variant={'primary'} type={'submit'}>
                  <CloudUpload /> &nbsp; Upload
                </Button>
              </Form>
            )}
          </Formik>

          <div className={'spacer'} />
        </div>

        <div className={`exports-section ${this.props.events.event.schedule ? '' : 'disabled'}`}>
          {this.exportTypes().map((item, i) => {
            return (
              <div className={'single-export'} key={i}>
                <div className={'export-header'}>
                  <div className={'single-export-title'}>
                    <h4>{item.name}</h4>
                  </div>

                  <div className={'single-export-action'}>
                    <Button
                      variant={'primary'}
                      //   disabled={this.props.events.event.generatingData[item.id]}
                      onClick={(e) => {
                        e.preventDefault();

                        this.props.generateData(
                          this.props.match.params.id,
                          item.id,
                          this.props.auth.token._id
                        );
                      }}>
                      <Refresh /> Generate
                    </Button>
                  </div>
                </div>
                <div className={'export-body'}>
                  <ul className={'export-list'}>
                    {this.props.reports.generatedReports ? (
                      this.props.reports.generatedReports[item.id] ? (
                        this.props.reports.generatedReports[item.id].length > 0 ? (
                          this.props.reports.generatedReports[item.id]
                            .filter((x) => !isEmpty(x))
                            .map((rep, repIndex) => {
                              return (
                                <li key={repIndex}>
                                  <Button
                                    variant={'link'}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      urls.getS3Url(rep.file.path)
                                        .then((fileUrl) => {
                                            var win = window.open(fileUrl, '_blank');
                                            win.focus();
                                        })
                                        .catch((error) => {
                                        console.error('Error loading image:', error);
                                        });
                                    }}>
                                    {rep.file.name}
                                  </Button>
                                </li>
                              );
                            })
                        ) : (
                          <h4>No prints generated yet.</h4>
                        )
                      ) : (
                        <h4>No prints generated yet.</h4>
                      )
                    ) : (
                      <h4>No prints generated yet.</h4>
                    )}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  events: state.events,
  reports: state.reports
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  getSingleEvent: (id, token) => {
    dispatch(eventActions.getSingleEvent(id, token));
  },
  uploadSchedule: (id, data, token) => {
    dispatch(eventActions.uploadSchedule(id, data, token));
  },
  generateData: (id, type, token) => {
    dispatch(reportActions.generateData(id, type, token));
  },
  getGeneratedData: (id, token) => {
    dispatch(reportActions.getGeneratedData(id, token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintMaterials));

class PrintMaterialsContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default PrintMaterialsContainer;
