

import Api from '../../utils/Api';

export function getKPI(eventId, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('events/getKPI', { id: eventId }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_KPI',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function getGeneratedData(id, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('events/getGeneratedData', { id: id }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_GENERATED_REPORTS',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function generateData(id, type, token) {
  return (dispatch) => {
    const api = new Api(token);

    dispatch({
      type: 'CLEAR_GENERATED_REPORTS',
      payload: type
    });

    api.post('events/generateData', { id: id, type: type }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GENERATING_STATUS_CHANGED',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function getRevenue(eventId, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('events/getRevenue', { id: eventId }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_REVENUE',
            payload: res.data.data
          });
        }
      }
    });
  };
}

export function getCompetitions(event, token) {
  return (dispatch) => {
    const api = new Api(token);
    api.post('events/getCompetitions', { event }, (res) => {
      if (res.status === 200) {
        if (res.data.status === 200) {
          dispatch({
            type: 'GOT_COMPETITIONS',
            payload: res.data.data
          });
        }
      }
    });
  };
}
