

const defaultState = {
    competition: {},
    entries: [],
    template: {}
};

const tabulator = (state = defaultState, action) => {
    switch (action.type) {
        default:
            return {
                ...state
            };
    }
}

export default tabulator;
