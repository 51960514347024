

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import urls from "../../utils/urls";
import placeholder from "../../assets/images/placeholder1.png";
import * as singleActions from '../../store/actions/single';
import Autosuggest from 'react-autosuggest';
import Bound from './Bound';
import Button from "react-bootstrap/Button";
import {Close, Trash} from 'react-ionicons';
import Api from "../../utils/Api";


class ObjectSuggestion extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            value: '',
            items: []
        }
    }

    requestTimer = null
    onChange = (e) => {
        this.setState({
            value: e.target.value
        });

        if (this.props.onType) {
            this.props.onType(e);
        }

        if (this.props.remote) {
            let searchFields = this.props.remoteFields || ['name', 'lname', 'email'];

            let data = {
                k: searchFields,
                val: e.target.value,
                collection: this.props.remoteKey
            };

            if (this.props.remoteQuery) {
                data.query = this.props.remoteQuery;
            }

            if (this.props.urlRoute) {
                data.urlRoute = this.props.urlRoute;
            }
            if (this.props.localState) {
                this.requestTimer = this.fetch(data, this.props.auth.token._id);
            } else {
                this.requestTimer = this.props.callRemoteObjects(data, this.props.auth.token._id);

            }
        }
    };

    componentDidUpdate(props, state){
        if (state.value !== this.state.value) {
            if (this.props.remote && !this.props.disableUpdateFetch) {
                if (this.props.localState) {
                    this.requestTimer = this.fetch({
                        val: this.state.value,
                        collection: this.props.remoteKey
                    }, this.props.auth.token._id);
                }else {
                    this.requestTimer = this.props.callRemoteObjects({
                        val: this.state.value,
                        collection: this.props.remoteKey
                    }, this.props.auth.token._id);
                }
                /*
                this.requestTimer = this.props.callRemoteObjects({
                    val: value,
                    collection: this.props.remoteKey
                }, this.props.auth.token._id);

                 */
            }
        }
    }

    onSuggestionsFetchRequested = ({ value }) => {



    };

    fetch = (data, token) => {
        const api = new Api(token);

        //console.log("Searching", this.state.value);
        let query = data.query || {
            search: data.val
        };
        let or = [];
        if (data.k) {
            or = data.k.map(ke => {
                return {
                    [ke]: data.val
                }
            })
        }
        let route = data.collection;
        if (data.urlRoute) {
            route += data.urlRoute;
        }
        api.get(route, {or, query}, res => {
            if (res.status === 200) {
                console.log("Setting state", res.data.data);
                this.setState({items: res.data.data, menuOpen: true});
            }
        });
    }

    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };

    render() {
        const { value } = this.state;
        const inputProps = {
            placeholder: 'Start typing',
            value,
            onChange: this.onChange
        };

        if (this.props.items || this.state.items) {
            return (
                <Bound>
                    <div className={"object-picker-container-top"}>
                        {this.props.label ?
                            <label>{this.props.label}</label>
                            : null
                        }


                        <Autosuggest
                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                            inputProps={inputProps}
                            getSuggestionValue={(item) => item[this.props.itemValue]}
                            wrapperStyle={{display: 'block'}}
                            className={"object-picker-parent"}

                            suggestions={!this.props.localState ? this.props.items : this.state.items}
                            onMenuVisibilityChange={open => this.setState({menuOpen: open})}
                            selectOnBlur={true}
                            renderSuggestion={(item) => {
                                
                                let displayValue = [this.props.displayValue, this.props.displayValue2, this.props.displayValue3];
                                let val = [];


                                for(let i = 0; i < 3; i++) {
                                    if(displayValue[i]){
                                        if(displayValue[i].indexOf('.') > -1) {
                                            let exploded = displayValue[i].split('.');

                                            console.log("Exploded" ,exploded);
                                            if(exploded.length === 2) {
                                                val[i] = item[exploded[0]][exploded[1]];
                                            } else {
                                                val[i] = item[exploded[0]];
                                            }
                                            console.log("dp", displayValue[i])
                                        } else {
                                            val[i] = item[displayValue[i]];
                                        }
                                    }
                                }


                                return (
                                    <div key={item._id} className={`object-picker-menu-item`}
                                         onClick={e => this.setState({menuOpen: false})}>
                                        <div className={"object-menu-item-image"}
                                             style={{background: item ? item.image ? `url(${urls.getS3Url(item.image.thumbXs || item.image.thumbSm || item.image.thumb || item.image.path)})` : `url(${placeholder})` : `url(${placeholder})`}}/>
                                        <div className={"object-menu-item-label"}>{
                                            this.props.displayJoin ?
                                                item[this.props.displayJoinValue] ?
                                                    item[this.props.displayJoinValue].join(" > ")
                                                    : displayValue[1] ? val[0] + " " +  val[1] + (displayValue[2] ? " - " + val[2] : '') : val[0]
                                                : val[1] ? val[0] + " " + val[1] + (val[2] ? " - " + val[2] : '') : 
                                                val[0] ? val[0] : item[displayValue[0]]}</div>
                                    </div>
                                )
                            }

                                

                            }
                            renderInputComponent={(props) => {

                                // TODO: This should move to another location so that it can be reused
                                const getNestedValue = (path, object) => {
                                    let returnVal = "";
                                    if (path && object && Object.keys(object).length > 0) {
                                        returnVal = path.split('.').reduce((o, i) => o[i], object);
                                    }
                                    return returnVal;
                                }

                                if (this.props.items || this.props.localState) {
                                    let relevant = this.props.localState ?  this.state.items.filter(i => i[this.props.itemValue] === this.props.value[this.props.itemValue]) : this.props.items.filter(i => i[this.props.itemValue] === this.props.value[this.props.itemValue]);

                                    let topProps = {

                                    }

                                    if (this.props.id) {
                                        topProps.id = this.props.id;
                                    }

                                    if(this.props.tabIndex) {
                                        topProps.tabIndex = this.props.tabIndex;
                                    }

                                    return (
                                        <div className={"object-picker-container"}>
                                            <div className={"object-picker-image"}>
                                                <div className={"object-image"}
                                                     style={{background: relevant.length > 0 ? relevant[0].image ? `url(${urls.getS3Url(relevant[0].image.thumbXs || relevant[0].image.thumbSm || relevant[0].image.thumb || relevant[0].image.path)})` : `url(${placeholder})` : `url(${placeholder})`}}/>
                                            </div>
                                            <div className={"object-picker-input"}>
                                                <input

                                                    tabIndex={0}
                                                    onClick={e => this.setState({menuOpen: true})}
                                                    {...props}
                                                    onChange={e => this.setState({value: e.target.value})}
                                                    {...inputProps}
                                                    {...topProps}
                                                    onBlur={e => {
                                                        if (this.props.selectOnBlur) {
                                                            this.setState({menuOpen: false})
                                                            if (this.props.localState) {

                                                                if (this.state.items && this.state.items.length > 0) {
                                                                    this.props.onChange(this.state.items[0])
                                                                } else if (this.props.value) {
                                                                    // If nothing was selected and there was a previous value, reset it
                                                                    // TODO: Find a better way to do this. onChange is expecting an event and we're passing in an object
                                                                    this.props.onChange(this.props.value);
                                                                } else {
                                                                    this.props.onChange([]);
                                                                }
                                                            } else {
                                                                this.props.onChange(this.props.items[0])
                                                            }
                                                            this.setState({value: '', menuOpen: false})
                                                        }
                                                    }}
                                                    value={this.state.value ?
                                                        this.state.value
                                                        :
                                                        this.state.menuOpen && this.state.value ?
                                                            this.state.value
                                                            :
                                                            getNestedValue(this.props.displayValue, this.props.value)
                                                                ?
                                                                getNestedValue(this.props.displayValue, this.props.value) + 
                                                                (getNestedValue(this.props.displayValue2, this.props.value) ? " " + getNestedValue(this.props.displayValue2, this.props.value) : '') + 
                                                                (getNestedValue(this.props.displayValue3, this.props.value) ?
                                                                    " - " + getNestedValue(this.props.displayValue3, this.props.value) : '')
                                                                : ''}
                                                />
                                            </div>
                                            {this.props.showDelete ?
                                                <Button variant={'flat'} className={"btn-small-padding"} onClick={(e) => {

                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    this.props.onDelete(this.props.value)
                                                }}><Trash fontSize={'18px'} {...{color: 'rgba(0,0,0,.5)'}} /></Button>
                                                : null}

                                            {this.props.showCancel ?
                                                <Button variant={'flat'} className={"btn-small-padding"} onClick={(e) => {

                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    this.props.onCancel(this.props.value)
                                                }}><Close fontSize={'18px'} {...{color: 'rgba(0,0,0,.5)'}} /></Button>
                                                : null}
                                        </div>

                                    )
                                }
                            }}
                            shouldItemRender={(item, value) => {

                                if (item[this.props.displayValue]) {
                                    return item[this.props.displayValue].toLowerCase().indexOf(this.state.value.toLowerCase()) > -1
                                } else {
                                    return ''.toLowerCase().indexOf(this.state.value.toLowerCase()) > -1
                                }

                            }}

                            renderSuggestionContainer={({ containerProps, children, query }) => {

                                return (
                                    <div {...containerProps} className={"object-picker-menu"}>
                                        {children}
                                        <div>
                                            Press Enter to search <strong>{query}</strong>
                                        </div>
                                    </div>
                                );
                            }}
                            highlightFirstSuggestion={true}
                            autoHighlight={true}
                            value={this.state.value}
                            onSuggestionSelected={(e, {suggestion, suggestionValue, suggestionIndex, sectionIndex, method}) => {
                                this.setState({menuOpen: false})
                                if (this.props.localState) {

                                    if (this.state.items) {


                                        this.props.onChange(this.state.items[suggestionIndex])
                                    } else {
                                        this.props.onChange([]);
                                    }
                                } else {
                                    this.props.onChange(this.props.items[suggestionIndex])
                                }
                                this.setState({value: '', menuOpen: false})
                            }}
                        />


                    </div>
                </Bound>


            )
        } else {
            return null
        }
    }
}

const mapStateToProps = state => ({
    user: state.user,
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    callRemoteObjects: (data, token) => {
        dispatch(singleActions.callRemoteObjects(data, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ObjectSuggestion));

class ObjectSuggestionContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}
export default ObjectSuggestionContainer;
