

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../../store/actions/table';
import Form from "react-bootstrap/Form";

class Comments extends React.Component {

    render() {
        return (
            <div className={"elevated rounded ex-pad"}>
                <Row>
                    <Col md={12}>


                        <Form.Group controlId="comments">
                            <Form.Label>Comments (These are visible to the dancer)</Form.Label>
                            <Form.Control
                                type="text"
                                as={'textarea'}
                                name="comments"
                                rows={14}
                                placeholder="Enter additional comments"
                                value={this.props.values.comments}
                                onChange={this.props.handleChange}
                                onBlur={this.props.handleBlur}
                                isValid={this.props.touched.comments && !this.props.errors.comments}

                            />
                            <Form.Control.Feedback></Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Comments));

class CommentsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default CommentsContainer;