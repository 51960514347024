

const defaultState = {
    events: [],
    competition: {},
    event: {},
    result: {}
};

const publicState = (state = defaultState, action) => {
    switch (action.type) {
        case 'GOT_PUBLIC_RESULT':
            return {
                ...state,
                result: action.payload.data
            }
        case 'GOT_PUBLIC_EVENT':
            return {
                ...state,
                event: action.payload.data
            }
        case 'GOT_PUBLIC_DATA':
            return {
                ...state,
                events: action.payload.data
            }
        default:
            return {
                ...state
            };
    }
}

export default publicState;