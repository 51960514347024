

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import * as eventActions from '../../../store/actions/events';
import placeholder from '../../../assets/images/placeholder-person.png';
import urls from '../../../utils/urls';
import Button from 'react-bootstrap/Button';
import { NotificationManager } from 'react-notifications';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

class EntryInPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previews: [],
      pdfs: []
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(props, state) {
    if (props.single.single !== this.props.single.single) {
      this.init();
    }
  }

  init = () => {
    if (!this.state.setPreviews) {
      if (this.props.single.dancers) {
        if (this.props.single.dancers.length > 0) {
          this.setState({
            previews: this.props.single.dancers.map((d) => {
              return d.image
                ? d.image.thumb || d.image.path
                  ? urls.getS3Url(d.image.thumb || d.image.path)
                  : placeholder
                : placeholder;
            }),
            setPreviews: true
          });
        }
      }
    }
  };

  grades = {
    grades: 'Grades (Beginner - Prizewinner)',
    championship: 'Championship',
    adult: 'Adult'
  };

  render() {
    return (
      <div className={''}>
        <div className={'spacer'} />
        <div className={'elevated rounded ex-pad'}>
          <div dangerouslySetInnerHTML={{ __html: this.props.single.event.inPersonContent }}></div>
        </div>

        <div className={'spacer'} />
        {this.props.single.single
          ? this.props.single.single.sections
            ? this.props.single.single.sections.map((d, di) => {
                let rel = this.props.single.single.sections
                  ? this.props.single.single.sections.filter((sd) => sd.dancer === d.dancer)[0]
                  : null;
                let dancer = this.props.single.dancers
                  ? this.props.single.dancers.filter((sd) => sd._id === d.dancer)[0]
                  : null;
                if (d.level === 'teams') {
                  dancer = this.props.single.teams
                    ? this.props.single.teams.filter((sd) => sd._id === d.dancer)[0]
                    : null;
                }

                if (dancer) {
                  let comps = this.props.single.single.competitions
                    ? this.props.single.single.competitions.filter((co) => co.dancer === d.dancer)
                    : null;

                  if (comps) {
                    if (comps.length === 0) {
                      return null;
                    }

                    return (
                      <div
                        key={di}
                        className={'elevated rounded ex-pad padded dancer-upload-section mb-5'}>
                        <div className={'dancer-upload-header'}>
                          <Row>
                            <Col>
                              <h5>
                                <div className={'small-avatar'}>
                                  <div
                                    onClick={(e) => this.setState({ filePickerOpen: true })}
                                    className={'artist-img avatar'}
                                    style={{
                                      background: this.state.previews[di]
                                        ? `url(${this.state.previews[di]}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                                        : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`
                                    }}></div>
                                </div>
                                <div className={'dancer-name'}>
                                  {dancer.name} {dancer.lname}
                                </div>
                              </h5>
                              <div className={'text-hint'}>
                                <div>
                                  <label className={'dancer-label'}>Level:</label>{' '}
                                  {this.grades[dancer.grade]}
                                </div>
                              </div>
                            </Col>
                            <Col>
                              {rel.dancerPdf ? (
                                <div className={'float-right text-right'}>
                                  {!this.props.single.event.hideEntryDancerNumber ? (
                                    <h3>#{rel.dancerNumber}</h3>
                                  ) :null
                                  }

                                  {this.props.single.event.tabulators.indexOf(
                                    this.props.user.user._id
                                  ) > -1 ? (
                                    <a
                                      href={'#pdf'}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.props.generateDancerCard(
                                          this.props.single.event._id,
                                          this.props.single.single._id,
                                          rel.dancerNumber,
                                          this.props.auth.token._id
                                        );
                                      }}>
                                      Generate Competitor Card
                                    </a>
                                  ) : null}
                                  <br />
                                  {this.props.single.event ? (
                                    this.props.single.event.pdf ? (
                                      this.props.single.event.pdf.path ? (
                                        <a
                                          href={'#pdf'}
                                          onClick={(e) => {
                                            e.preventDefault();
                                            let link = urls.getS3Url(
                                              this.props.single.event.pdf.path
                                            );
                                            var win = window.open(link, '_blank');
                                            win.focus();
                                          }}>
                                          Download Syllabus
                                        </a>
                                      ) : null
                                    ) : null
                                  ) : null}
                                </div>
                              ) : null}
                            </Col>
                          </Row>

                          <div className={'divider'} />
                        </div>
                        <div className={'dancer-upload-forms'}>
                          {comps.map((com, ci) => {
                            return <h5 key={ci}>{com.name}</h5>;
                          })}
                        </div>
                      </div>
                    );
                  } else {
                    return null;
                  }
                } else {
                  return null;
                }
              })
            : null
          : null}

        <div className={'ex-pad text-center'}>
          <Button
            variant={'secondary'}
            onClick={(e) => {
              this.props.history.push('/app/');
              NotificationManager.success('Your entry has been received!');
            }}>
            Finish & return to dashboard
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  generateDancerCard: (eventId, entryId, dancerNumber, token) => {
    dispatch(eventActions.generateDancerCard(eventId, entryId, dancerNumber, token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EntryInPerson));

class EntryInPersonContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EntryInPersonContainer;
