

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as singleActions from "../../../store/actions/single";
import FormButtons from "../../Common/FormButtons";

class OnboardingComplete extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    finish = () => {
        let data = new FormData();
        let params = {complete: {complete: "complete"}};
        data.set("data", JSON.stringify(params));

        this.props.postOnboarding("users/nextStep", data, this.props.auth.token._id, (id) => {
            this.setState({disableNext: false});
        });
    }

    render() {
        return (
            <div className={''}>
                <FormButtons
                    confirm={(e) => {
                        this.handleSubmit();
                    }}
                    back={(e) => {
                        e.preventDefault();
                    }}
                    showRevert={false}
                    showPrevious={false}
                    showNext={true}
                    next={(e) => {
                        e.preventDefault();
                        this.finish();
                    }}
                    showDefault={false}
                    label={'Complete'}

                />


                <div className={"onboarding-complete"}>
                    <h4>That's all the info we need right now</h4>
                    <p>Thanks for joining Feis FWD.  We've tried to make this app as fluid and intuitive to work with as
                    possible, however, if you're ever unsure of where to go, or what to do next, <a href={"https://www.feisfwd.com/contact"} target={"_blank"} rel="noopener noreferrer">contact us</a>.
                    </p>
                    <p>Alternatively, you can email us directly at <a href={"mailto:dance@feisfwd.com"}>dance@feisfwd.com</a></p>

                    <p>We hope you enjoy the app</p>
                    <p>- Feis FWD Team</p>
                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    postOnboarding: (view, data, token, redirect) => {
        dispatch(singleActions.postNextStep(view, data, token, true, redirect));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingComplete));

class OnboardingCompleteContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default OnboardingCompleteContainer;