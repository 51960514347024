

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import urls from "../../utils/urls";
import humanizeDuration from "humanize-duration";
import { Alert } from 'react-ionicons';
import { Download } from 'react-ionicons';


const alertStyle = {
    fontSize: '64px',
    color: '#a52a2a'
}

const noteStyle = {
    fontSize: '64px',
    color: '#555555'
}

class Notification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: null
        }
    }

    componentDidMount() {
        this.init();
    }

    componentDidUpdate(props) {
        if (this.props.notification._id !== props.notification._id) {
            this.init();
        }
    }

    init = () => {

        if (this.props.notification.image) {
            if (this.props.notification.image.path) {
                this.setState({preview: urls.getS3Url(this.props.notification.image.thumbSm)})
            }
        } else if (this.state.preview) {
            this.setState({preview: ''})
        }
    }


    render() {
        return (
            <div className={`note-container animated fadeInUp ${"step" + this.props.step}`} onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                if (this.props.notification.download) {
                    let link = urls.getS3Url(this.props.notification.downloadPath);
                    //console.log("LINK", link);
                    var win = window.open(link, '_blank');
                    win.focus();
                } else if (this.props.notification.path) {
                    this.props.history.push(this.props.notification.path);
                }
                document.dispatchEvent(new MouseEvent('click'));

            }}>
                <div className={"note-icon-container"}>
                    <div className={"note-icon"}>
                        {this.props.notification.download ?
                            <Download {...noteStyle} />
                            : this.state.preview ?
                            <div style={{background: `url(${this.state.preview})`}} className={"notification-image"}/>
                            :
                            <Alert {...alertStyle} />
                        }
                    </div>
                </div>
                <div className={"note-content-container"}>
                    <div className={"note-title"}>
                        {this.props.notification.title}
                    </div>
                    <div className={"note-description"}>
                        {this.props.notification.content.length > 100 ?
                            this.props.notification.content.substr(0, 100) + "..."
                        : this.props.notification.content}
                    </div>
                    <div className={"note-timestamp"}>
                        {this.props.ts}

                        {humanizeDuration((new Date().getTime()) - (new Date(this.props.notification.createdAt).getTime()), { units: ['y', 'mo', 'w', 'd', 'h', 'm'], round: true })} ago
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({

});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Notification));

class NotificationContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default NotificationContainer;