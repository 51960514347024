

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import urls from "../../../utils/urls";
import Button from "react-bootstrap/Button";
import placeholder from "../../../assets/images/placeholder1.png";
import Nav from "react-bootstrap/Nav";
import Bound from "../../Common/Bound";
import Details from "./Profile/Details";
import Entries from "./Profile/Entries";
import Results from './Profile/Results';
import PageLoader from "../../Common/PageLoader";
import 'animate.css';

class DancerProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            preview: '',
            image: '',
        }
    }


    componentDidMount(){
        this.init();
    }

    componentDidUpdate(props, state) {
        console.log("Props = Props", props.single.single !== this.props.single.single)
        if(props.single.single !== this.props.single.single) {
            this.init();
        }
    }

    init = () => {
        console.log("Setting imaage", this.props.single.single.image)
        if (this.props.single.single.image) {
            if (this.props.single.single.image.path) {
                urls.getS3Url(this.props.single.single.image.thumb || this.props.single.single.image.path, this.props.auth.token._id)
                .then((imageUrl) => {
                    this.setState({
                    preview: imageUrl
                    });
                })
                .catch((error) => {
                    console.error('Error loading image:', error);
                });
            } else if (this.state.preview) {
                this.setState({preview: ''})

            }
        } else if (this.state.preview) {
            this.setState({preview: ''})

        }


    }

    menu = [
        {
            title: 'Details',
            path: `/app/dancers/${this.props.match.params.id}/info`,
            component: <Details />,
            dataSet: () => {

                this.props.getSingle("users", this.props.user.user._id, this.props.auth.token._id);
            }
        },
        {
            title: 'Entries',
            path: `/app/dancers/${this.props.match.params.id}/entries`,
            component: <Entries />
        },
        {
            title: 'Results',
            path: `/app/dancers/${this.props.match.params.id}/results`,
            component: <Results />
        },
    ]

    renderItem = (item, i) => {
        return (
            <Nav.Link key={i} to={item.path} as={Link} className={`tab tab-${i} ${this.props.location.pathname === item.path ? 'active' : ''}`}>{item.title}</Nav.Link>
        )
    }

    renderContent = () => {
        let menuItem = this.menu.filter(item => item.path === this.props.location.pathname);
        if (menuItem.length > 0) {
            return (
                <Bound>
                    {menuItem[0].component}
                </Bound>

            );
        } else {
            return null;
        }
    }


    render() {


        return (

            <PageLoader loaded={this.props.single.single._id === this.props.match.params.id || this.props.match.params.id === "new"}>
                <div className={"animated fadeIn"}>
                    {this.props.single.single._id === this.props.match.params.id || this.props.match.params.id === 'new' ?
                        <div>
                            <div className={"profile-header"}>
                                <div className={"artist-img avatar"} style={{background: this.state.preview ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)` : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>

                                <h2 className={"profile-title"}>{this.props.single.single.name} {this.props.single.single.lname}</h2>
                                {this.props.single.single.owners ? this.props.single.single.owners.indexOf(this.props.user.user._id) > -1 ?
                                    <Button variant={"flat"} onClick={e => this.props.history.push(`/app/dancers/${this.props.match.params.id}/edit`)}>Edit</Button>
                                    : null : null}
                            </div>
                            <div className={"profile-body"}>
                                <div className={"tab-container"}>
                                    <div className={"tab-row"}>
                                        {this.menu.map((item, i) => {
                                            return this.renderItem(item, i)
                                        })}
                                        <div className={"tab-highlight"} />
                                        <div className={"tab-highlight-track"} />
                                    </div>

                                </div>
                                <div className={"tab-content ex-pad"}>

                                    {this.renderContent()}

                                </div>
                            </div>
                        </div>
                        : null}

                </div>
            </PageLoader>


        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    single: state.single,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(DancerProfile));

class DancerProfileContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default DancerProfileContainer;
