

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';

class OnboardingSteps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={"onboarding-steps"}>
                {this.props.user.user.onboardingSteps.map((step, i) => {
                    return (
                        <div className={`onboarding-step ${this.props.user.user.step === i ? 'active' : ''} ${this.props.user.user.step === i+1 ? 'active-right' : ''}`} style={{width: `calc(100% / ${this.props.user.user.onboardingSteps.length})`}}>
                            <label>{step.title}</label>
                        </div>
                    )
                })}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(OnboardingSteps));

class OnboardingStepsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default OnboardingStepsContainer;