

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import placeholder2 from '../../../assets/images/placeholder1.png';
import * as tableActions from '../../../store/actions/table';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Add } from 'react-ionicons';
import { School } from 'react-ionicons';
import * as singleActions from '../../../store/actions/single';
import SchoolTile from '../../Common/SchoolTile';
import SimpleDeleteModalContainer from '../../Common/SimpleDelete';

class Schools extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteSchool: {},
      deleteType: 'deleteSchool',
      showDeleteModal: false
    };
  }

  dancers = [
    {
      title: 'Some Person',
      group: 'U15',
      entries: 4
    },
    {
      title: 'Some Person',
      group: 'U15',
      entries: 4
    },
    {
      title: 'Some Person',
      group: 'U15',
      entries: 4
    }
  ];

  events = [
    {
      title: 'Event 1',
      group: 'U15',
      date: 'Jan 21, 20201',
      registering: true,
      entries: 4
    },
    {
      title: 'Event 2',
      group: 'U15',
      date: 'Jan 7, 20201',
      registering: true,
      entries: 4
    },
    {
      title: 'Event 3',
      group: 'U15',
      date: 'March 13, 20201',
      registering: false,
      entries: 4
    }
  ];

  componentDidMount() {
    //this.props.getSchoolsPage(this.props.auth.token._id);
    this.props.loadData('schools');
    this.props.get(this.props.auth.token._id);
  }

  render() {
    return (
      <div>
        <SimpleDeleteModalContainer
          deleteSchool={this.state.deleteSchool}
          show={this.state.showDeleteModal}
          deleteType={this.state.deleteType}
          hideModal={(e) => {
            this.setState({
              deleteSchool: {},
              showDeleteModal: e
            });
          }}
          onDelete={(e) => {
            //console.log("Deleting", this.state.deleteSchool)
            this.props.deleteItem(this.state.deleteSchool._id, this.props.auth.token._id);
            this.setState({
              deleteSchool: {},
              showDeleteModal: e
            });
          }}
        />
        <div className={'page-header'}>
          <Row>
            <Col>
              <h1>
                <School /> Schools
              </h1>
            </Col>
            <Col>
              {this.props.user.user ? (
                this.props.user.user.roles ? (
                  this.props.user.user.roles.indexOf('teacher') > -1 ||
                  this.props.user.user.role === 'admin' ? (
                    <Col>
                      <div className={'text-right'}>
                        <Button
                          variant={'primary'}
                          onClick={(e) => {
                            this.props.setBlankSingle();
                            this.props.history.push('/app/schools/new');
                          }}>
                          <Add fontSize={'18px'} color={'#fff'} /> &nbsp; Create School
                        </Button>
                      </div>
                    </Col>
                  ) : null
                ) : null
              ) : null}
            </Col>
          </Row>
        </div>

        <div className={'section'}>
          <div className={'tile-grid'}>
            <Row>
              {this.props.table.data.length > 0 ? (
                this.props.table.data.map((d, i) => {
                  return (
                    <SchoolTile
                      {...d}
                      data={d}
                      key={i}
                      placeholder={placeholder2}
                      onClickDelete={(e, school) =>
                        this.setState({ showDeleteModal: true, deleteSchool: school })
                      }
                    />
                  );
                })
              ) : (
                <h4 className={'no-data'}>No Schools Found</h4>
              )}
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  get: (token) => {
    dispatch(tableActions.getTableData('schools', 0, 99, {}, token, 'add'));
  },
  loadData: (view) => {},
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  },
  deleteItem: (id, token) => {
    dispatch(tableActions.deleteItem('schools', id, token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Schools));

class SchoolsContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected />
      </div>
    );
  }
}

export default SchoolsContainer;
