

import React from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import LogoutModal from "../../Common/LogoutModal";
import Bound from "../../Common/Bound";
import Header from "../../Header/Header";
import Events from './Events';
import CompetitionSelector from './CompetitionSelector';
import {Container} from "react-bootstrap";
import Competition from './Competition';


class PublicResults extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            filter: ''
        }
    }

    componentDidMount(props) {
        if (this.props.table.view !== "") {
            //this.props.getTableData("", 0, 999, {number: 1}, this.props.auth.token._id, 'add');
        }
    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={`feis-page-container`}>
                <LogoutModal show={this.state.showLogoutModal} hideModal={this.setLogoutModal} />
                <Bound>
                    <Header handleThemeModal={this.handleThemeModal} toggleSidebar={this.toggleSidebar} setLogoutModal={this.setLogoutModal} />
                </Bound>



                <div className={`feis-page full-width ${this.props.auth.sidebarOpen ? 'sidebar-open' : ''} ${this.props.location.pathname.indexOf('/app/j/') > -1 ? 'judge-view' : ''}`}>


                    <Container>
                        <div className={'page-container'}>

                            <Route path={"/public/results"} exact={true} component={Events} />
                            <Route path={"/public/results/event/:event/:id"} exact={true} component={Competition} />
                            <Route path={"/public/results/event/:id"} exact={true} component={CompetitionSelector} />
                        </div>
                    </Container>

                </div>



            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicResults));

class PublicResultsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default PublicResultsContainer;