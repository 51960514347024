

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as tableActions from '../../../store/actions/table';
import EventTile from '../../Common/EventTile';
import placeholder2 from '../../../assets/images/placeholder1.png';
import Button from 'react-bootstrap/Button';
import { ArrowBack } from 'react-ionicons';
import { Add } from 'react-ionicons';
import * as singleActions from '../../../store/actions/single';

class EventSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount(props) {
    this.props.getTableDataWithQuery(
      'events',
      0,
      999,
      { number: 1 },
      { $or: [{ admins: this.props.user.user._id }, { tabulators: this.props.user.user._id }] },
      this.props.auth.token._id,
      'add'
    );
  }

  componentDidUpdate(props, state) {}

  render() {
    return (
      <div>
        <div className={'page-header'}>
          <Row>
            <Col>
              <div className={'float-right'}>
                {this.props.user.user ? (
                  this.props.user.user.roles ? (
                    this.props.user.user.roles.indexOf('organizer') > -1 ||
                    this.props.user.user.role === 'admin' ? (
                      <Col>
                        <div className={'text-right'}>
                          <Button
                            variant={'primary'}
                            onClick={(e) => {
                              this.props.setBlankSingle();
                              this.props.history.push('/app/event-dashboard/new/edit/1');
                            }}>
                            <Add fontSize={'18px'} color={'#fff'} /> &nbsp; Create Event
                          </Button>
                        </div>
                      </Col>
                    ) : null
                  ) : null
                ) : null}
              </div>
              <h1>
                {this.props.location.pathname.split('/').length > 3 ? (
                  <Button
                    variant={'flat btn-round'}
                    onClick={(e) => {
                      this.props.history.push('/app/event-dashboard');
                    }}>
                    <ArrowBack />
                  </Button>
                ) : null}
                Your Events
              </h1>
            </Col>
          </Row>
        </div>
        <Row>
          {this.props.table.view === 'events' ? (
            this.props.table.data.length === 0 ? (
              <h4 className={'no-data'}>No Events Found</h4>
            ) : (
              this.props.table.data.map((d, i) => {
                return (
                  <EventTile
                    isDash={true}
                    showModal={(event) => this.showModal(event)}
                    {...d}
                    data={d}
                    key={i}
                    placeholder={placeholder2}
                  />
                );
              })
            )
          ) : null}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EventSelector));

class EventSelectorContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EventSelectorContainer;
