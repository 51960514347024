

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import * as tableActions from '../../../../store/actions/table';
import EntryTile from "../../../Common/EntryTile";
import placeholder2 from "../../../../assets/images/placeholder1.png";

class Entries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        if (this.props.user.user.roles.indexOf('teacher') > -1) {
            this.props.getDancersEntries(this.props.match.params.id, 0, 999, {number: 1}, this.props.auth.token._id, 'add');
        }
    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={'page-container'}>
                {this.props.user.user.roles.indexOf('teacher') > -1 ?
                    <div>


                        <div className={"section"}>


                            <div className={"tile-grid"}>
                                <Row>
                                    {
                                        this.props.table.studentEntries ?
                                            this.props.table.studentEntries.length > 0 ?
                                                this.props.table.studentEntries.map((d, i) => {
                                                    return <EntryTile {...d} data={d} key={i} placeholder={placeholder2} />
                                                })
                                                : <h4 className={"no-data"}>No Entries Found</h4>
                                            :
                                            <h4 className={"no-data"}>No Entries Found</h4>
                                    }
                                </Row>
                            </div>
                        </div>
                    </div>
                    : null}


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getDancersEntries: (id, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getDancersEntries(id, skip, limit, sort, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Entries));

class EntriesContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default EntriesContainer;
