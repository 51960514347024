

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import CardList from "./CardList";
import CardForm from "./CardForm";


class Cards extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            addingCard: false
        }
    }



    render() {
        return (
            <div className={'page-container'}>

                {!this.state.addingCard ?
                    <CardList setAdding={v => this.setState({addingCard: v})} />
                    :
                    <CardForm setAdding={v => this.setState({addingCard: v})}  />
                }


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Cards));

class CardsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default CardsContainer;