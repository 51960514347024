

import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import Nav from 'react-bootstrap/Nav';
import * as singleActions from "../../../store/actions/single";
import Bound from '../../Common/Bound';
import EventGrid from './EventGrid';
import MyResults from "./MyResults";
import StudentResults from "./StudentResults";


class Results extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    menu = [
        {
            title: 'Results',
            path: '/app/results',
            component: <EventGrid />

        },
        {
            title: 'My Results',
            path: '/app/results/dancers',
            roles: ['parent', 'dancer'],
            component: <MyResults />
        },
        {
            title: 'School Results',
            path: '/app/results/schools',
            roles: ['teacher'],
            component: <StudentResults />

        }
    ]

    loadData = () => {

    }

    componentDidMount(props) {
        this.loadData();
    }

    componentDidUpdate(props, state) {
        if (props.location.pathname !== this.props.location.pathname) {
            this.loadData();
        }
    }

    renderItem = (item, i) => {
        let shouldRender = true;
        if (item.role) {
            if (this.props.user.user) {
                if (item.role === 'admin' && this.props.user.user.role !== 'admin') {
                    shouldRender = false;
                }
            } else {
                shouldRender = false;
            }
        }

        if (item.roles) {
            let matchRoles = 0;
            item.roles.map((r) => {
                if(this.props.user.user) {
                    if (this.props.user.user.roles) {
                        this.props.user.user.roles.map((ur) => {
                            if (r === ur) {
                                matchRoles += 1;
                            }

                            return ur;
                        })
                    }
                }

                return r;
            })

            if (matchRoles === 0) {
                shouldRender = false;
            }
        }

        if (shouldRender) {
            return (
                <Nav.Link key={i} to={item.path} as={Link}
                          className={`tab tab-${i} ${this.props.location.pathname === item.path ? 'active' : ''}`}>{item.title}</Nav.Link>
            )
        }else{
            return null;
        }
    }

    renderContent = () => {
        let menuItem = this.menu.filter(item => item.path === this.props.location.pathname);
        if (menuItem.length > 0) {
            if (menuItem[0].component) {
                return (
                    <Bound>
                        {menuItem[0].component}
                    </Bound>

                );
            }else {
                return null;
            }
        } else {
            return null;
        }
    }


    render() {
        return (
            <div className={'page-container elevated rounded'}>
                <div className={"tab-container"}>
                    <div className={"tab-row"}>
                        {this.menu.map((item, i) => {
                            return this.renderItem(item, i)
                        })}
                        <div className={"tab-highlight"} />
                        <div className={"tab-highlight-track"} />
                    </div>

                </div>
                <div className={"tab-content ex-pad"}>

                    {this.renderContent()}

                </div>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getSingle: (view, id, token) => {
        dispatch(singleActions.getSingle(view, id, token));
    },
    loadOnboarding: (token) => {
        dispatch(singleActions.loadOnboarding(token));
    }
});

const Connected = connect(mapStateToProps, mapDispatchToProps)(withRouter(Results));

class ResultsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default ResultsContainer;