

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as singleActions from '../../../store/actions/single';
import Select from '../../Common/Select';
import FormButtons from '../../Common/FormButtons';

const schema = yup.object({
  region: yup.object().shape({
    _id: yup.string().required('Region is required')
  })
});

class JoinOrganization extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      preview: '',
      image: '',
      disableNext: false,
      createSchool: '',
      createTeacher: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    //this.props.updateField('image', file);
    this.props.setValues({ image: file });
  };

  componentDidMount() {
    if (!this.props.onboarding) {
      this.props.setSingle(this.props.user.user);
    }
  }

  handleSubmit = (values) => {
    let data = new FormData();

    if (this.props.onboarding) {
      let params = { organization: {} };
      if (values.region._id) {
        params.organization.region = values.region._id;
      }
      if (values.organization) {
        if (values.organization._id) {
          params.organization.organization = values.organization._id;
        }
      }

      data.set('data', JSON.stringify(params));
      this.props.postOnboarding('users/nextStep', data, this.props.auth.token._id, (id) => {
        this.setState({ disableNext: false });
      });
    } else {
      let params = { user: {} };

      if (values.region._id) {
        params.user.region = values.region._id;
      }
      if (values.organization) {
        if (values.organization._id) {
          params.user.organization = values.organization._id;
        }
      }

      if (this.props.single.single._id) {
        params.user._id = this.props.single.single._id;
      } else if (this.props.single.user._id) {
        params.user._id = this.props.single.single._id;
      }

      data.set('data', JSON.stringify(params));

      this.props.patch('users', params._id, data, this.props.auth.token._id);
    }
  };

  render() {
    return (
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            region: this.props.single.user._id
              ? this.props.single.user.region
              : this.props.single.dancer.region || {},
            organization: this.props.single.user._id
              ? this.props.single.user.organization
              : this.props.single.dancer.organization || {}
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {this.props.onboarding ? (
                <FormButtons
                  confirm={(e) => {
                    handleSubmit(values);
                  }}
                  back={(e) => {
                    e.preventDefault();
                    this.step(values, 'previous');
                  }}
                  showPrevious={false}
                  showRevert={false}
                  showNext={true}
                  next={(e) => {
                    e.preventDefault();
                    handleSubmit(values);
                  }}
                  showDefault={false}
                  label={'Organization Settings'}
                />
              ) : (
                <FormButtons
                  confirm={(e) => {
                    handleSubmit(values);
                  }}
                  back={(e) => {
                    e.preventDefault();
                    this.step(values, 'previous');
                  }}
                  showPrevious={false}
                  showRevert={false}
                  showNext={false}
                  next={(e) => {
                    e.preventDefault();
                    handleSubmit(values);
                  }}
                  showDefault={true}
                  label={''}
                />
              )}

              <Row>
                <Col md={12}>
                  <div className={'field-container'}>
                    <label>Region</label>

                    <Select
                      disableTyping={true}
                      items={this.props.single.regions}
                      value={values.region || {}}
                      itemValue={'_id'}
                      onChange={(val) => {
                        setFieldValue('region', val);
                      }}
                      displayValue={'name'}
                    />
                    {errors.region && touched.region && (
                      <p className={'text-danger hint-text'}>{errors.region._id}</p>
                    )}
                  </div>
                </Col>
                {/*<Col md={12}>
                                    <div className={"field-container"}>
                                        <label>Organization</label>

                                        <Select
                                            disableTyping={true}
                                            items={this.props.single.organizations}
                                            value={values.organization}
                                            itemValue={"_id"}
                                            onChange={val => {
                                                setFieldValue('organization', val);
                                            }}
                                            displayValue={"name"}

                                        />

                                    </div>
                                </Col>
                                */}
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  post: (view, data, token, redirect) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patchFormData(view, id, data, token));
  },
  setSingle: (d) => {
    dispatch(singleActions.setSingleEntry(d));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(JoinOrganization));

class JoinOrganizationContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default JoinOrganizationContainer;
