

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import * as singleActions from '../../store/actions/single';
import Button from 'react-bootstrap/Button';
import { Close } from 'react-ionicons';

class DeleteModal extends React.Component {
  postSingle = (item) => {};

  renderContent = () => {};
  render() {
    return (
      <div className={this.props.user.user.theme}>
        <Modal
          centered
          size={'xl'}
          className={`theme-${this.props.user.user.theme} ${
            this.props.user.user ? (this.props.user.user.darkTheme ? 'dark-theme' : '') : ''
          }`}
          show={this.props.show}
          onHide={(e) => this.props.hideModal(false)}>
          <Modal.Body className={''}>
            <p>
              NOTICE: BY AGREEING TO THE TERMS AND CONDITIONS, YOU WILL WAIVE OR GIVE UP CERTAIN
              LEGAL RIGHTS, INCLUDING THE RIGHT TO SUE OR TO CLAIM COMPENSATION FOLLOWING INJURY,
              ILLNESS, LOSS OR DEATH. PLEASE READ CAREFULLY:
            </p>

            <b>1. CONFIRMATION OF HEALTH</b>
            <p>
              I confirm that I and any family members attending the event are in good physical
              health. I further confirm that neither I nor any family member in attendance have no
              underlying medical condition which may increase the risk of illness, injury, loss
              and/or death from physical activity and/or COVID-19 including, without limitation,
              heart disease, lung disease, diabetes, asthma, muscle strains or sprains. If such
              illnesses and/or conditions exist, I understand that this may increase the
              Participant’s risk for illness, injury, loss, and/or death while attending and
              participating any activities or gatherings at the event (collectively, the
              “Activities”).
            </p>

            <b>2. ACKNOWLEDGEMENT OF PANDEMIC RISKS AND VOLUNTARY ASSUMPTION OF RISK</b>
            <p>
              I am aware that COVID-19 has been declared a pandemic and that no cure exists at this
              time. I am aware that any increased social contact during this pandemic may create an
              increased risk of transmission and illness of COVID-19. I am aware that, despite
              adherence to local laws and regulations, other persons may attend the location in
              which the Activities take place and that those persons may have been exposed to
              COVID-19. I am aware of the risks, dangers and hazards associated with participating
              in the Activities and I (or on behalf of the Participant) freely accept and fully
              assume all such risks, dangers and hazards and the possibility of personal injury,
              illness, loss or death resulting therefrom.
            </p>

            <b>3. ACKNOWLEDGEMENT OF ACTIVITIES’ RISK AND VOLUNTARY ASSUMPTION OF RISK</b>
            <p>
              I understand and acknowledge that the Activities may involve risks to the Participant.
              Such risks include, without limitation, the possibility of accident or illness while
              traveling to and from the event as well as any injury, loss, death, or property damage
              arising out of participation in the Activities in or around the event. I am aware of
              the risks, dangers and hazards associated with participating in the Activities and I
              (or on behalf of the Participant) freely accept and fully assume all such risks,
              dangers and hazards and the possibility of personal injury, illness, loss or death
              resulting therefrom.
            </p>

            <b>4. WAIVER OF CLAIMS AND RELEASE OF LIABILITY</b>
            <p>
              I agree to waive any and all claims that I now or in the future may have against the
              organizer., its employees, staff, agents, representatives, volunteers, subcontractors,
              successors and/or assigns (the “Released Parties”). I further agree to release the
              Released Parties from all liability for any injury, illness, loss or death that arises
              during, or results from, my participation in the Activities due to any cause
              whatsoever. I release and forever discharge the Released Parties from all such claims.
            </p>

            <b>5. INDEMNIFICATION</b>
            <p>
              I agree to hold harmless and indemnify the Released Parties from any and all claims
              and liability for any injury, illness, loss or death, including injury, illness, loss
              or death of any third party resulting from the Participant’s participation in the
              Activities. This Agreement shall be effective and binding upon my heirs, next of kin,
              executors, administrators, assigns and representatives, in the event of my death or
              incapacity.
            </p>

            <b>6. EXPULSION / ADMISSION REFUSALS</b>
            <p>
              I understand that the committee reserves the right to refuse admission or remove any
              individual from the event for any reason, including but not limited to improper
              comportment, abuse, disruption, overcrowding, contagious illness, disorderly behavior
              or any other reason deemed necessary by the committee or the organizer board with a
              view to a safe and harmonious event.
            </p>

            <b>7. REFUNDS</b>
            <p>
              I understand that there are no refunds for fees including entries, admission and
              FeisFWD fees for any reason, including voluntary or non-voluntary non-participation,
              dancer injury, force majeure events such as acts of God, weather, disaster, Covid-19
              outbreaks or public health-related restrictions, late arrivals, travel mishaps,
              expulsion or refused admission of individuals to the event, reduced attendance
              policies, etc.
            </p>

            <b>8. RULES</b>
            <p>
              I agree to read and comply with all rules of the organizer and those rules set out in
              the syllabus and program, at all times, and acknowledge that if I neglect to comply, I
              am at risk of expulsion from the event without refund or an opportunity for further
              attendance or participation.
            </p>

            <p>
              <b>
                I CONFIRM THAT I HAVE READ AND THAT I UNDERSTAND THIS AGREEMENT. I AM AWARE THAT, BY
                SIGNING THIS AGREEMENT, I AM WAIVING CERTAIN LEGAL RIGHTS WHICH I OR MY HEIRS, NEXT
                OF KIN, EXECUTORS, ADMINISTRATORS, ASSIGNS AND REPRESENTATIVES MAY HAVE AGAINST THE
                RELEASED PARTIES. I CONFIRM THAT I AM VOLUNTARILY SIGNING THIS AGREEMENT.
              </b>
            </p>

            <div className={'text-right'}>
              <Button
                variant={'primary'}
                onClick={(e) => {
                  //this.props.history.push('/app/logout')
                  this.props.hideModal(false);
                }}>
                <Close /> Close
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user,
  single: state.single
});

const mapDispatchToProps = (dispatch) => ({
  post: (uri, item, token) => {
    dispatch(singleActions.post(uri, item, token));
  },
  postFile: (uri, fd, token) => {
    dispatch(singleActions.postFile(uri, fd, token));
  },
  patch: (uri, id, item, token) => {
    dispatch(singleActions.patch(uri, id, item, token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(DeleteModal));

class DeleteModalContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default DeleteModalContainer;
