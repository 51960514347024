

import React from 'react';
import {withRouter, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import Login from './Login';
import Signup from './Signup';
import Register from './Register';
import ForgotPassword from './ForgotPassword';
import LogoSimple from '../../../assets/images/white-logo-vert.png';
import Bound from '../../Common/Bound';
import SetPassword from './SetPassword';

class Auth extends React.Component {
    componentDidUpdate(props, state) {
        if (props.auth.token._id !== this.props.auth.token._id && this.props.auth.token._id) {
            this.props.history.push('/app');
        }
    }
    render() {
        return (
            <div className={`auth-page`}>

                <img src={LogoSimple} className={`auth-logo`} alt={"Feis Logo"} />
                <Bound>
                    <Route path={"/a/"} exact={true} render={() => <Login />} />
                </Bound>
                <Bound>
                    <Route path={"/a/login"} render={() => <Login />} />
                </Bound>
                <Bound>
                    <Route path={"/a/forgot-password"} render={() => <ForgotPassword />} />
                </Bound>

                <Bound>
                    <Route path={"/a/set-password/:email/:token"} render={() => <SetPassword />} />
                </Bound>
                <Bound>
                    <Route path={"/a/register"} exact={true} render={() => <Register />} />
                </Bound>

                <Bound>
                    <Route path={"/a/signup"} component={Signup} />
                </Bound>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Auth));

class AuthContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected/>
            </div>
        )
    }
}

export default AuthContainer;