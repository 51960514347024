

import React from 'react';
import {withRouter, Link} from 'react-router-dom';
import {connect} from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Formik} from 'formik';
import * as yup from 'yup';
import * as authActions from '../../../store/actions/auth';
import { Send } from "react-ionicons";


const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("This field is required")
});

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitting: false
        }
    }

    handleSubmit = (values) => {
        this.setState({submitting: true});
        this.props.sendForgotPassword(values.email);
    }

    componentDidMount(){
        this.props.setBlankMessage();
    }

    componentDidUpdate(props) {
        if (props.auth.attempts < this.props.auth.attempts) {
            this.setState({submitting: false});
        }
    }

    render() {
        return (
            <div className={`login-form ex-pad`}>

                <h4 className="auth-title">Forgot Password</h4>
                {this.props.auth.message ?
                <div className={"text-success text-center"}>{this.props.auth.message}</div>
                : null}
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    initialValues={{
                        email: '',
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting
                      }) => (

                        <Form noValidate onSubmit={handleSubmit}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Enter email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.email && !errors.email}

                                />
                                <Form.Control.Feedback></Form.Control.Feedback>
                            </Form.Group>
                            {errors.email && touched.email && (<p className={"text-danger hint-text"}>{errors.email}</p>)}

                            <div className={"hint-text text-center auth-hint"}>
                                Don't have an account yet?  <Link to={"/a/register"}>Register here</Link>.<br />
                                Remember your password?  <Link to={"/a/login"}>Log in here</Link>.
                            </div>
                            <div className={`text-center mb-20`}>
                                <Button variant="primary" disabled={this.state.submitting} type="submit">
                                    Send reset code &nbsp; <Send />
                                </Button>
                            </div>
                        </Form>
                    )}

                </Formik>


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    login: (credentials) => {
        dispatch(authActions.login(credentials));
    },
    setBlankMessage: () => {
        dispatch(authActions.setBlankMessage());
    },
    sendForgotPassword: (email) => {
        dispatch(authActions.sendForgotPassword(email));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ForgotPassword));

class ForgotPasswordContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected/>
            </div>
        )
    }
}

export default ForgotPasswordContainer;