

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../../store/actions/table';
import urls from "../../../../utils/urls";
import placeholder from '../../../../assets/images/placeholder1.png';
import {ListGroup, OverlayTrigger, Popover} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


class LeaderItem extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            preview: ''
        }
    }

    componentDidMount(props) {
        if (this.props.data["round" + this.props.r.toString()]) {
            if (this.props.data["round" + this.props.r.toString()].thumb && !this.state.preview) {
                this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].thumb)})
            } /*else if (!this.props.data["round" + this.props.r.toString()].path && this.state.preview) {
                this.setState({preview: ''})
            }*/
        } else if (this.state.preview && !this.props.data["round" + this.props.r.toString()].thumb) {
            this.setState({preview: ''})
        }
    }

    componentDidUpdate(props, state) {
        if (this.props.data["round" + this.props.r.toString()]) {
            if (this.props.data["round" + this.props.r.toString()].thumb && !this.state.preview) {
                //this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].thumb)})
            } /*else if(this.props.data["round" + this.props.r.toString()].path !== props.data["round" + this.props.r.toString()].path) {
                    this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].path)})

                }*/
        } else if (this.state.preview) {
            this.setState({preview: ''})
        }
        if (props.data["round" + this.props.r.toString()] && this.props.data["round" + this.props.r.toString()]) {
            if (props.data["round" + this.props.r.toString()].thumb !== this.props.data["round" + this.props.r.toString()].thumb) {
                //console.log
                this.setState({preview: '', image: null})
                //this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].path), image: null})
            }
        }
    }

    render() {
        return (

            <div className={'leader-item'} onClick={e => {
                this.props.history.push(`/app/j/${this.props.match.params.round}/${this.props.data._id}`)
            }}>
                {this.props.data.score[`round${this.props.match.params.round}Rank`] ?
                    <div className={"badge-count"}>{this.props.data.score[`round${this.props.match.params.round}Rank`]}</div>
                    : null
                }

                {this.props.data.score[`round${this.props.match.params.round - 1}Rank`] ?
                    <div className={"badge-count secondary-badge"}>{this.props.data.score[`round${this.props.match.params.round - 1}Rank`]}</div>
                    : null
                }
                <OverlayTrigger
                    trigger="hover"
                    key={"right"}
                    placement={"right-start"}
                    overlay={
                        <Popover id={`popover-positioned-right`} className={"score-popover"}>
                            <Popover.Title as="h3">Dancer #{this.props.data.dancerNumber ?
                                this.props.data.dancerNumber : this.props.data.entry.sections.length > 0 ?
                                this.props.data.entry.sections[0].dancerNumber
                                : null}</Popover.Title>
                            <Popover.Content>
                                {this.props.events.competition.competition.grade.rounds > 1 ?
                                    <strong>Round 1</strong>
                                : null}
                                <ListGroup as="ul" className={"score-modal-list"}>
                                    <ListGroup.Item as="li">
                                        <Row>
                                            <Col md={6}>
                                                <label>Score:</label>
                                            </Col>
                                            <Col md={6} classNam={"text-right"}>
                                                <div className={"text-right"}> {this.props.data.score.round1Score}</div>
                                            </Col>
                                        </Row>

                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <Row>
                                            <Col md={6}>
                                                <label>Rank:</label>
                                            </Col>
                                            <Col md={6} classNam={"text-right"}>
                                                <div className={"text-right"}> {this.props.data.score.round1Rank ?

                                                    this.props.data.score.round1Rank + "/" + this.props.items
                                                : null}</div>
                                            </Col>
                                        </Row>


                                    </ListGroup.Item>
                                    <ListGroup.Item as="li">
                                        <Row>
                                            <Col md={12}>
                                                <label>Comments:</label>
                                            </Col>
                                            <Col md={12}>
                                                <span>{this.props.data.score.round1Comments}</span>
                                            </Col>
                                        </Row>


                                    </ListGroup.Item>
                                </ListGroup>

                                {this.props.events.competition.competition.grade.rounds >= 2 ?
                                    <div>
                                        <strong>Round 2</strong>
                                        <ListGroup as="ul" className={"score-modal-list"}>
                                            <ListGroup.Item as="li">
                                                <Row>
                                                    <Col md={6}>
                                                        <label>Score:</label>
                                                    </Col>
                                                    <Col md={6} classNam={"text-right"}>
                                                        <div className={"text-right"}> {this.props.data.score.round2Score}</div>
                                                    </Col>
                                                </Row>

                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <Row>
                                                    <Col md={6}>
                                                        <label>Rank:</label>
                                                    </Col>
                                                    <Col md={6} classNam={"text-right"}>
                                                        <div className={"text-right"}> {this.props.data.score.round2Rank ?

                                                            this.props.data.score.round2Rank + "/" + this.props.items
                                                            : null}</div>
                                                    </Col>
                                                </Row>


                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <Row>
                                                    <Col md={12}>
                                                        <label>Comments:</label>
                                                    </Col>
                                                    <Col md={12}>
                                                        <span>{this.props.data.score.round2Comments}</span>
                                                    </Col>
                                                </Row>


                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>

                                : null}

                                {this.props.events.competition.competition.grade.rounds >= 3 ?
                                    <div>
                                        <strong>Round 3</strong>
                                        <ListGroup as="ul" className={"score-modal-list"}>
                                            <ListGroup.Item as="li">
                                                <Row>
                                                    <Col md={6}>
                                                        <label>Score:</label>
                                                    </Col>
                                                    <Col md={6} classNam={"text-right"}>
                                                        <div className={"text-right"}> {this.props.data.score.round3Score}</div>
                                                    </Col>
                                                </Row>

                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <Row>
                                                    <Col md={6}>
                                                        <label>Rank:</label>
                                                    </Col>
                                                    <Col md={6} classNam={"text-right"}>
                                                        <div className={"text-right"}> {this.props.data.score.round3Rank ?

                                                            this.props.data.score.round3Rank + "/" + this.props.items
                                                            : null}</div>
                                                    </Col>
                                                </Row>


                                            </ListGroup.Item>
                                            <ListGroup.Item as="li">
                                                <Row>
                                                    <Col md={12}>
                                                        <label>Comments:</label>
                                                    </Col>
                                                    <Col md={12}>
                                                        <span>{this.props.data.score.round3Comments}</span>
                                                    </Col>
                                                </Row>


                                            </ListGroup.Item>
                                        </ListGroup>
                                    </div>

                                    : null}


                            </Popover.Content>
                        </Popover>
                    }
                >
                    <div className={`leader-image ${this.props.location.pathname.indexOf(this.props.data._id) > -1 ? 'active' : ''}`} alt={"LB"} style={{background: this.state.preview ? `url(${this.state.preview})` : `url(${placeholder})`}} />

                </OverlayTrigger>
                <div className={"sm-dancer-number"}>#{
                    this.props.data.dancerNumber ?
                        this.props.data.dancerNumber :
                    this.props.data.entry.sections.length > 0 ?
                    this.props.data.entry.sections[0].dancerNumber
                : null}</div>
            </div>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(LeaderItem));

class LeaderItemContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default LeaderItemContainer;