

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import Visa from '../../../assets/images/visa.png';
import Mastercard from '../../../assets/images/mastercard.png';
import Amex from '../../../assets/images/american_express.png';
import Discover from '../../../assets/images/discover.png';
import Button from 'react-bootstrap/Button';

class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }


    render() {
        return (
            <span className={"credit-card-container"}>
                <div className={"credit-card"}>
                    <div className={"content"}>
                        <div className={"number"}>
                            ****-****-****-{this.props.data.last4}
                        </div>
                        {this.props.data.type === 'Visa' ?
                            <img src={Visa} className={"card-logo"} alt={"Visa"} />
                        : null}

                        {this.props.data.type === 'MasterCard' ?
                            <img src={Mastercard} className={"card-logo"} alt={"MasterCard"} />
                            : null}

                        {this.props.data.type === 'American Express' ?
                            <img src={Amex} className={"card-logo"} alt={"American Express"} />
                            : null}

                        {this.props.data.type === 'Discover' ?
                            <img src={Discover} className={"card-logo"} alt={"Discover"} />
                            : null}
                    </div>
                </div>
                <Button variant={"link text-danger"} onClick={e => {
                    this.props.deleteItem("cards", this.props.data._id, this.props.auth.token._id);
                }}>Delete</Button>
                <div className={"spacer"} />
            </span>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    deleteItem: (view, id, token) => {
        dispatch(tableActions.deleteItem(view, id, token))
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Card));

class CardContainer extends React.Component {
    render() {
        return (

                <Connected {...this.props} />

        )
    }
}

export default CardContainer;