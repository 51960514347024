

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import Table from "react-bootstrap/Table";
import checks from "../../../utils/checks";

class ChampResultTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    render() {

        let panelType = this.props.scoring.judge_rotation_type;
        return (
            <div>
                <div className={"spacer"} />
                <h5>Comp {this.props.competition.code} | {this.props.competition.rowTitle} {this.props.competition.columnTitle} - {this.props.title}</h5>
                {this.props.competition.totalResults ?
                    <h6>Total Competitors: {this.props.competition.totalResults}</h6>
                    : null}
                <Table responsive striped>
                    <thead>
                    <tr>
                        <th>Rank</th>
                        {this.props.isMulti ?
                            <th>Competitor</th>
                        : null}
                        {this.props.competition.results[0] ? this.props.competition.results[0].judges ? this.props.competition.results[0].judges.map((j, ji) => {
                            return (
                                <th key={ji}>{j.name}</th>
                            )

                        }) : null : null}
                        {panelType === 'ROTATING' ?
                            <>
                                <th>Total IP R1</th>
                                <th>Total IP R2</th>
                                <th>Total IP R3</th>
                            </>
                            : null
                        }
                        <th>School</th>
                        {this.props.competition.results[0].finalScore ?
                        <th>Final</th>
                            : null}
                    </tr>
                    </thead>


                    {this.props.competition.results.map((res, c) => {
                        return (
                            <tbody key={c}>
                                <tr>

                                    <td>{res.finalRank}</td>
                                    {this.props.isMulti ?
                                        <td>{`#${res.dancerNumber} ${res.dancerName ? " - " + res.dancerName : ''}`}</td>
                                        : null}
                                    {res.judges ? res.judges.map((judge, j) => {
                                        return (
                                            <td style={{width: "12.5%"}} key={j}>
                                                {judge.finalIp}
                                            </td>
                                        )
                                    }) : null}
                                    {panelType === 'ROTATING' ?
                                            <>
                                                <td>{res.totalRound1Ip}</td>
                                                <td>{res.totalRound2Ip}</td>
                                                <td>{res.totalRound3Ip}</td>
                                            </>
                                        : null
                                    }
                                    <td>{res.school ?
                                        res.school.name : null}</td>
                                    {res.finalScore ?
                                    <td>{res.finalScore}</td>
                                        : null}

                                </tr>

                                {res.judges ?
                                <tr>
                                    <td>

                                    </td>
                                    {this.props.isMulti ?
                                        <td></td>
                                        : null}
                                    {res.judges ? res.judges.map((judge, j) => {
                                        return (
                                            <>
                                            {panelType === 'ROTATING' ?
                                                <td key={j}>
                                                    {judge.round1Score ? 
                                                        <>
                                                            <b>R1: </b> {judge.round1Score}<br />
                                                        </>
                                                        :
                                                        <>
                                                            {judge.round2Score ? 
                                                                <>
                                                                    <b>R2: </b> {judge.round2Score}<br />
                                                                    </>
                                                                :
                                                                <>
                                                                    <b>R3: </b> {judge.round3Score}<br />
                                                                    </>
                                                            }
                                                        </>
                                                    }
                                                </td>
                                                :
                                                <td key={j}>
                                                    <b>R1: </b> {judge.round1Score}<br />
                                                    <b>R2: </b> {judge.round2Score}<br />
                                                    {this.props.competition.grade.rounds >= 3 ?
                                                        (<span><b>R3: </b> {judge.round3Score}<br /></span>)
                                                        : ''}
                                                    <b>Total: </b> {Math.round(((judge.round1Score ?? 0) + (judge.round2Score ?? 0) + (judge.round3Score ?? 0)) * 100) / 100}
                                                </td>
                                            }
                                            </>   
                                        )
                                    }) : null}
                                    <td>

                                    </td>
                                    <td>

                                    </td>

                                </tr>
                                    : null}

                                {/* {res.judges && !this.props.isMulti ?
                                    <tr>

                                        <td></td>
                                        {this.props.isMulti ?
                                            <td></td>
                                            : null}
                                        {res.judges ? res.judges.map((judge, j) => {
                                            return (
                                                <td style={{width: "25%"}} key={j}>
                                                    <div><b>Top Scores</b></div>
                                                    <div><b>R1: </b> {judge.topRound1 ? judge.topRound1.join(', ') : ''}</div>
                                                    <div><b>R2: </b> {judge.topRound2 ? judge.topRound2.join(', ') : ''}</div>
                                                    {this.props.competition.grade.rounds >= 3 ?
                                                        <div><b>R3: </b> {judge.topRound3 ? judge.topRound3.join(', ') : ''}</div>
                                                        : ''}
                                                </td>
                                            )
                                        }) : null}
                                        <td></td>
                                        {res.finalScore ?
                                            <td></td>
                                            : null}

                                    </tr>
                                    : null} */}
                                {res.judges ?
                                <tr>
                                    <td>

                                    </td>
                                    {this.props.isMulti ?
                                        <td></td>
                                        : null}
                                    {res.judges ? res.judges.map((judge, j) => {
                                        return (
                                            <>
                                            {panelType === 'SPLIT' ?
                                                <td key={j}>
                                                    {judge.round1Comments ? 
                                                        <>
                                                            <b>R1 Comments: </b> {judge.round1Comments}<br />
                                                            <b>R2 Comments: </b> {judge.round2Comments}<br />
                                                        </>
                                                        :
                                                        <>
                                                            {judge.round3Comments ? 
                                                                <>
                                                                    <b>R3 Comments: </b> {judge.round3Comments}<br />
                                                                    </>
                                                                :
                                                                null
                                                            }
                                                        </>
                                                    }
                                                </td>
                                                :
                                                <td key={j}>
                                                    <b>Comments:</b>
                                                    <div>{judge.round1Comments}</div>
                                                </td>
                                            }
                                            </>
                                        )
                                        }) : null
                                    }
                                    <td></td>
                                    <td></td>
                                </tr>
                                    : null}
                                {/*{res.judges ?
                                <tr>
                                    <td>

                                    </td>
                                    {this.props.isMulti ?
                                        <td></td>
                                        : null}
                                    {res.judges ? res.judges.map((judge, j) => {
                                        return (
                                            <td key={j}>
                                                <h5>R2:</h5>
                                                <div>{judge.round2Comments}</div>

                                                {judge.round2GoodJob ?
                                                    judge.round2GoodJob.length > 0 ?
                                                        <div>

                                                            <b>Good Job: </b>
                                                            {judge.round2GoodJob ?
                                                                Object.keys(judge.round2GoodJob).filter(k => {
                                                                    return judge.round2GoodJob[k];
                                                                }).map((k, ki) => {
                                                                    let check = checks.filter(ch => ch.key === k)[0];
                                                                    if (check) {
                                                                        return check.label
                                                                    } else {
                                                                        return null;
                                                                    }

                                                                }).join(', ')

                                                                : null}

                                                        </div>
                                                        : null
                                                : null}


                                                {judge.round2NeedsWork ?
                                                    judge.round2NeedsWork.length > 0 ?
                                                        <div>
                                                            <b>Needs Work: </b>
                                                            {judge.round2NeedsWork ?
                                                                Object.keys(judge.round2NeedsWork).filter(k => {
                                                                    return judge.round2NeedsWork[k];
                                                                }).map((k, ki) => {
                                                                    let check = checks.filter(ch => ch.key === k)[0];
                                                                    if (check) {
                                                                        return check.label
                                                                    } else {
                                                                        return null;
                                                                    }

                                                                }).join(', ')
                                                                : null}
                                                        </div>
                                                        : null
                                                : null}


                                            </td>
                                        )
                                    }) : null}
                                    <td></td>
                                    <td>

                                    </td>
                                </tr>
                                    : null}

                                {res.judges ?
                                <tr>
                                    <td>

                                    </td>
                                    {this.props.isMulti ?
                                        <td></td>
                                        : null}
                                    {res.judges && this.props.competition.grade.rounds >= 3 ? res.judges.map((judge, j) => {
                                        return (
                                            <td key={j}>
                                                <h5>R3:</h5>
                                                <div>{judge.round3Comments}</div>

                                                {judge.round3GoodJob ?
                                                    judge.round3GoodJob.length > 0 ?
                                                        <div>

                                                            <b>Good Job: </b>
                                                            {judge.round3GoodJob ?
                                                                Object.keys(judge.round3GoodJob).filter(k => {
                                                                    return judge.round3GoodJob[k];
                                                                }).map((k, ki) => {
                                                                    let check = checks.filter(ch => ch.key === k)[0];
                                                                    if (check) {
                                                                        return check.label
                                                                    } else {
                                                                        return null;
                                                                    }

                                                                }).join(', ')

                                                                : null}

                                                        </div>

                                                        : null

                                                : null}

                                                {judge.round3NeedsWork  ?

                                                    judge.round3NeedsWork.length > 0 ?
                                                        <div>
                                                            <b>Needs Work: </b>
                                                            {judge.round3NeedsWork ?
                                                                Object.keys(judge.round3NeedsWork).filter(k => {
                                                                    return judge.round3NeedsWork[k];
                                                                }).map((k, ki) => {
                                                                    let check = checks.filter(ch => ch.key === k)[0];
                                                                    if (check) {
                                                                        return check.label
                                                                    } else {
                                                                        return null;
                                                                    }

                                                                }).join(', ')
                                                                : null}
                                                        </div>
                                                        : null
                                                    : null}

                                            </td>
                                        )
                                    }) : null}
                                    <td></td>
                                    <td>

                                    </td>
                                </tr>

                                    : null } */}
                            </tbody>
                        )
                    })}


                </Table>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ChampResultTable));

class ChampResultTableContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default ChampResultTableContainer;