

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import * as eventActions from '../../../store/actions/events';
import JudgeEntry from './JudgeEntry';
import Button from "react-bootstrap/Button";
import { PieChart } from 'react-minimal-pie-chart';
import ScoreModal from '../../Common/ScoreModal';
import FinalizeCompetitionModal from "../../Common/FinalizeCompetitionModal";

class JudgeSingleCompetition extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            score: {},
            showSubmitModal: false
        }
    }

    componentDidMount(props) {
        this.props.getJudgeCompetition({id: this.props.match.params.competition}, this.props.auth.token._id)
    }

    componentDidUpdate(props, state) {
        if (this.props.events.competition) {
            if (this.props.events.competition.judgeEvent) {
                if (this.props.events.competition.judgeEvent.completed) {
                    if (this.props.events.competition.judgeEvent.completed.indexOf(this.props.match.params.competition) > -1) {
                        this.props.history.push(`/app/judge/${this.props.match.params.id}`);
                    }
                }
            }
        }
    }
    showModal = (score) => {
        this.setState({showModal: true, score: score});
    }

    hideModal = () => {
        this.setState({showModal: false, score: {}});
    }

    showSubmitModal = () => {
        this.setState({showSubmitModal: true});
    }

    hideSubmitModal = () => {
        this.setState({showSubmitModal: false});
    }

    submitCompetition = () => {
        this.props.submitCompetition(this.props.match.params.competition, this.props.auth.token._id);
    }


    render() {

        if (this.props.events.competition) {
            if (this.props.events.competition.competition._id === this.props.match.params.competition) {
                return (
                    <div className={'page-container'}>
                        <ScoreModal items={this.props.events.competition.entries.length || 0} entry={this.state.score} show={this.state.showModal} hideModal={this.hideModal}/>
                        <FinalizeCompetitionModal submitCompetition={this.submitCompetition}
                                                  show={this.state.showSubmitModal} hideModal={this.hideSubmitModal}/>
                        <div className={'page-header'}>
                            <Row>
                                <Col>
                                    <h1>
                                        {this.props.events.competition.competition.code} |&nbsp;
                                        {this.props.events.competition.competition.grade.title} |&nbsp;
                                        {this.props.events.competition.competition.rowTitle} |&nbsp;
                                        {this.props.events.competition.competition.columnTitle}
                                    </h1>
                                </Col>
                            </Row>
                        </div>
                        <Row>
                            <Col md={9}>
                                {this.props.events.competition ?
                                    this.props.events.competition.entries ?
                                        this.props.events.competition.entries.map ?
                                            this.props.events.competition.entries.map((comp, i) => {
                                                return (
                                                    <JudgeEntry data={comp} key={i} rounds={1} r={1}
                                                                loadScoreModal={(entry) => this.showModal(entry)}/>
                                                )
                                            })
                                            : null
                                        : null
                                    : null}
                            </Col>
                            <Col md={3}>
                                <div className={"elevated round ex-pad text-center"}>
                                    {this.props.events.competition ?
                                        this.props.events.competition.entries ?
                                            <div className={"pie-container"}>
                                                <PieChart
                                                    lineWidth={20}
                                                    data={[
                                                        {
                                                            title: '',
                                                            value: Math.floor((this.props.events.competition.entries.filter(e => e.score.complete).length / this.props.events.competition.entries.length) * 100),
                                                            color: '#19bcac'
                                                        },
                                                        {
                                                            title: '',
                                                            value: 100 - Math.floor((this.props.events.competition.entries.filter(e => e.score.complete).length / this.props.events.competition.entries.length) * 100),
                                                            color: '#dddddd'
                                                        }
                                                    ]}
                                                    startAngle={-90}
                                                />
                                                <h3>{Math.floor((this.props.events.competition.entries.filter(e => e.score.complete).length / this.props.events.competition.entries.length) * 100)}%</h3>
                                            </div>
                                            : null
                                        : null}
                                    <div className={"spacer"}/>
                                    <Button variant={"primary"} onClick={e => {
                                        this.showSubmitModal();
                                    }}>Finalize & Submit</Button>
                                </div>
                            </Col>
                        </Row>


                    </div>
                )
            } else {
                return null;
            }

        }else {
            return null;
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery("events/getJudgeSingleCompetition", 0, 999, {}, query, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getJudgeCompetition: (query, token) => {
        dispatch(eventActions.getJudgeCompetition(query, token));
    },
    submitCompetition: (id, token) => {
        dispatch(eventActions.submitCompetition(id, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(JudgeSingleCompetition));

class JudgeSingleCompetitionContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default JudgeSingleCompetitionContainer;
