

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import ObjectPicker from "./ObjectPicker";
import ObjectSuggestion from "./ObjectSuggestion";

class CategoryPicker extends React.Component {
    render() {
        if (this.props.remote) {
            return (
                <ObjectSuggestion
                    items={this.props.items}
                    value={this.props.value}
                    itemValue={this.props.itemValue}
                    onChange={val => this.props.onChange(val)}
                    displayValue={this.props.displayValue}
                    displayValue2={this.props.displayValue2}

                    showCancel={this.props.showCancel}
                    onCancel={this.props.onCancel}
                    tabIndex={this.props.tabIndex}
                    id={this.props.id}
                    displayValue3={this.props.displayValue3}
                    imageKey={this.props.imageKey}
                    label={this.props.label}
                    showDelete={this.props.showDelete}
                    onDelete={this.props.onDelete}
                    displayJoin={this.props.displayJoin}
                    displayJoinValue={this.props.displayJoinValue}
                    selectedItems={this.props.selectedItems}
                    remote={this.props.remote}
                    remoteKey={this.props.remoteKey}
                    remoteQuery={this.props.remoteQuery}
                    remoteFields={this.props.remoteFields}
                    onType={this.props.onType}
                    urlRoute={this.props.urlRoute}
                    disableTyping={this.props.disableTyping}
                    localState={this.props.localState}
                    disableUpdateFetch={this.props.disableUpdateFetch}
                    selectOnBlur={this.props.selectOnBlur}

                />
            )
        } else {


            return (
                <ObjectPicker
                    items={this.props.items}
                    value={this.props.value}
                    itemValue={this.props.itemValue}
                    onChange={val => this.props.onChange(val)}
                    displayValue={this.props.displayValue}
                    imageKey={this.props.imageKey}
                    label={this.props.label}
                    showDelete={this.props.showDelete}
                    onDelete={this.props.onDelete}
                    displayJoin={this.props.displayJoin}
                    displayJoinValue={this.props.displayJoinValue}
                    selectedItems={this.props.selectedItems}
                    remote={this.props.remote}
                    remoteKey={this.props.remoteKey}
                    disableTyping={this.props.disableTyping}

                />
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(CategoryPicker));

class CategoryPickerContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default CategoryPickerContainer;
