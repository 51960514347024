

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import * as yup from "yup";
import * as singleActions from "../../../store/actions/single";
import Button from 'react-bootstrap/Button';
import urls from "../../../utils/urls";
import {NotificationManager} from 'react-notifications';
import { CloudUpload } from 'react-ionicons';
import { Close } from 'react-ionicons';
import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import Player from '../../Common/Player';
import 'vimond-replay/index.css';
import HlsjsVideoStreamer from 'vimond-replay/video-streamer/hlsjs';
dayjs.extend(utc)

const schema = yup.object().shape({})


class UploadForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            image: null,
            name: '',
            preview: null
        }
    }

    componentDidMount(props) {
        if (this.props.data["round" + this.props.r.toString()]) {
            if (this.props.data["round" + this.props.r.toString()].path && !this.state.preview) {
                this.setState({preview: urls.getLongS3Url(this.props.data["round" + this.props.r.toString()].path)})
            } /*else if (!this.props.data["round" + this.props.r.toString()].path && this.state.preview) {
                this.setState({preview: ''})
            }*/
        } else if (this.state.preview) {
            this.setState({preview: ''})
        }
    }

    componentDidUpdate(props, state) {
        if (this.props.data["round" + this.props.r.toString()]) {
                if (this.props.data["round" + this.props.r.toString()].path && !this.state.preview) {
                    this.setState({preview: urls.getLongS3Url(this.props.data["round" + this.props.r.toString()].path)})
                } /*else if(this.props.data["round" + this.props.r.toString()].path !== props.data["round" + this.props.r.toString()].path) {
                    this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].path)})

                }*/
            } else if (this.state.preview) {
                this.setState({preview: ''})
        }
        if (props.data["round" + this.props.r.toString()] && this.props.data["round" + this.props.r.toString()]) {
            if (props.data["round" + this.props.r.toString()].path !== this.props.data["round" + this.props.r.toString()].path) {
                //console.log
                this.setState({preview: '', image: null})
                //this.setState({preview: urls.getS3Url(this.props.data["round" + this.props.r.toString()].path), image: null})
            }
        }
    }

    handleSubmit = (values) => {
        let data = new FormData();
        if (!this.state.image && this.props.r !== 3){
            NotificationManager.error("No valid file found.");
        }else {
            data.append("files", this.state.image);

            let params = {
                event: this.props.data.event,
                    entry: this.props.data.entry,
                    _id: this.props.data._id,
                round: this.props.r};

            if (this.props.r ===3) {
                params.round3Dance = values.round3Dance;
                params.round3Speed = values.round3Speed;
            }

            //console.log("Sending", params, this.state.image);
            //data.set("data", JSON.stringify(params));
            //console.log("FORM DATA", data.values());

            this.setState({disableNext: true});

            this.props.patch("entryCompetitions", this.props.data._id, data, params, this.props.auth.token._id, (id) => {
                //this.props.history.push('/admin/schools/' + id);
            });

        }
    }

    getCanUpload = () => {
        let videoEnd = dayjs.utc(this.props.entry.event.videoEnd).add(31, "hours");
        let now = dayjs();

        let diff = now.diff(videoEnd, "minutes");
        //console.log

        return diff < 0;
    }

    isIOS() {
        //console.log("Platform", navigator.userAgent);
        return [
                'iPad Simulator',
                'iPhone Simulator',
                'iPod Simulator',
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            // iPad on iOS 13 detection
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    render() {
        return (
            <div className={"upload-form"}>
                <Formik
                    validationSchema={schema}
                    onSubmit={this.handleSubmit}
                    enableReinitialize
                    initialValues={{
                        image: '',
                        round3Dance: this.props.r === 3 ? this.props.data.round3Dance || '' : '',
                        round3Speed: this.props.r === 3 ? this.props.data.round3Speed || '' : ''
                    }}
                >
                    {({
                          handleSubmit,
                          handleChange,
                          handleBlur,
                          values,
                          touched,
                          isValid,
                          errors,
                          isSubmitting,
                          setFieldValue
                      }) => (
                        <Form noValidate onSubmit={handleSubmit} className={`form-button-wrapper`}>
                            {this.props.data["round" + this.props.r.toString() + "Loading"] ?
                                <div className={"form-upload-loader-container"}>
                                    <div className={`lds-ring active form-upload-loader`}>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>

                                    </div>
                                    <div className={"progress-text"}>{this.props.data["round" + this.props.r.toString() + "Progress"] ? this.props.data["round" + this.props.r.toString() + "Progress"] < 100 ? `${this.props.data["round" + this.props.r.toString() + "Progress"]}%` : 'Processing...' : '' }</div>
                                    <div className={"progress-text-sm"}>{this.props.data["round" + this.props.r.toString() + "Progress"] ? this.props.data["round" + this.props.r.toString() + "Progress"] < 100 ? `` : '' : '' }</div>
                                    <div className={'progress-button'}>
                                        <Button variant={"flat"} onClick={e => {
                                            this.props.cancelUpload(this.props.data._id, "round" + this.props.r.toString(), this.props.auth.token._id);
                                        }}><Close /> Cancel</Button>
                                    </div>

                                </div>
                                : null
                            }
                            <div className={`field-container ${this.props.data["round" + this.props.r.toString() + "Loading"] ? 'loading' : ''}`}>

                                <h5>{this.props.data.name} {this.props.data.rounds > 1 ? `(Round ${this.props.r})` : ''}
                                    {this.props.entry.owners.indexOf(this.props.user.user._id) === -1 ?
                                        this.state.preview ?
                                            ' - (UPLOADED)'
                                            :
                                            ' - (NOT UPLOADED)'
                                        : null}

                                    {this.props.data.rounds > 1 && this.props.type === "championship" ?
                                        this.props.r === 1 ?
                                            ` (Hard Shoe)` :
                                            this.props.r === 2 ?
                                                ' (Soft Shoe)' :
                                                this.props.r === 3 ?
                                                    ' (Set Dance)'
                                                    : null
                                        : this.props.data.rounds > 1 && this.props.type !== "championship" ?
                                            ' (Refer to syllabus)'
                                            : null

                                    }
                                </h5>


                                {this.state.preview ?
                                    this.props.entry.owners.indexOf(this.props.user.user._id) > -1 || this.props.entry.teacherAccess ?
                                        this.isIOS() ?
                                            <video controls>
                                                <source src={this.state.preview} />
                                            </video>
                                            :
                                        <div>
                                        <Player
                                            options={{
                                                bitrateFix: true,
                                                controls: {
                                                    includeControls: [
                                                        'playPauseButton',
                                                        'timeline',
                                                        'timeDisplay',
                                                        'volume',
                                                        'qualitySelector',
                                                        'fullscreenButton',
                                                        'pipButton'
                                                    ],
                                                    qualitySelectionStrategy: 'fix-bitrate'
                                                },
                                                videoStreamer: {
                                                    hlsjs: {
                                                        customConfiguration: {
                                                            capLevelToPlayerSize: true,
                                                            maxBufferLength: 1
                                                        }
                                                    }
                                                }
                                            }}
                                            source={this.state.preview} initialPlaybackProps={{ isPaused: true }}>
                                            <HlsjsVideoStreamer />
                                        </Player>
                                            <br />
                                        </div>
                                        /*
                                        <video controls className={"video-tile"}>
                                            <source src={this.state.preview} />
                                        </video>*/
                                        : null
                                    :
                                    this.props.entry.owners.indexOf(this.props.user.user._id) === -1 ?
                                        'Video not submitted'
                                        : null
                                }

                                {this.props.entry.owners.indexOf(this.props.user.user._id) > -1 && this.getCanUpload() ?
                                    <div>
                                        <div className={"video-upload"}>
                                            <div className={"video-upload-name"}>
                                                <div><span className={"btn btn-flat btn-video-upload"}>Select File</span> &nbsp; {this.state.name || 'No File Selected'}</div>
                                                </div>

                                            <Form.Control
                                            type="file"
                                            name="image"
                                            placeholder="Upload file"
                                            onChange={(e) => {
                                                if(e.target.files[0]) {
                                                    if (e.target.files[0].size * 0.000001 > 299) {
                                                        NotificationManager.error("File is too large");
                                                    } else if (e.target.files[0].type.indexOf('video/') === -1) {
                                                        NotificationManager.error("Not a valid file type");
                                                    } else {
                                                        this.setState({
                                                            //preview: URL.createObjectURL(e.target.files[0]),
                                                            image: e.target.files[0],
                                                            name: e.target.files[0].name
                                                        })
                                                        e.target.value = null;
                                                    }
                                                }
                                            }}
                                            accept="video/*"
                                            onBlur={handleBlur}
                                            className={`form-control hide-video-input`}
                                        />
                                        </div>
                                <div className="text-hint">*Maximum 300mb per video</div>
                                <br />
                                {this.props.r === 3 ?
                                    <div>
                                        <Row>
                                            <Col>
                                                <div className={"field-container"}>
                                                    <Form.Group controlId="lname">
                                                        <Form.Label>Dance Type</Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            name="round3Dance"
                                                            placeholder="Enter dance name"
                                                            value={values.round3Dance}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isValid={touched.round3Dance && !errors.round3Dance}
                                                            className={`form-control ${touched.round3Dance ? errors.round3Dance ? 'is-invalid' : 'is-valid' : ''}`}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div className={"field-container"}>
                                                    <Form.Group controlId="lname">
                                                        <Form.Label>Dance Speed</Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            name="round3Speed"
                                                            placeholder="Enter speed in bpm"
                                                            value={values.round3Speed}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            isValid={touched.round3Speed && !errors.round3Speed}
                                                            className={`form-control ${touched.round3Speed ? errors.round3Speed ? 'is-invalid' : 'is-valid' : ''}`}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Col>
                                        </Row>

                                    </div>
                                : null
                                }


                                    <div>
                                <Button variant={"primary"} type={"submit"}><CloudUpload /> &nbsp; Save & Upload</Button>
                                &nbsp;
                                {this.state.preview ?
                                    <Button variant={"flat text-danger"} onClick={e => {
                                        e.preventDefault();
                                        // Remove video frm comp, return comp
                                        this.props.postDelete("entryCompetitions",{id: this.props.data._id, field: "round"+this.props.r.toString()}, this.props.auth.token._id, (id) => {

                                        });
                                    }}><Close className={"text-danger"} /> &nbsp; Remove</Button>
                                    : null
                                }
                                    </div>

                                    </div>
                                    : null}
                                <br />

                                <div className={"ex-pad"}></div>
                                <div className={"separator"} />
                                <div className={"divider"} />
                                <div className={"separator"} />
                            </div>
                        </Form>
                        )}
                </Formik>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    patch: (view, id, data, params, token) => {
        dispatch(singleActions.postFormDataNested(view, id, data, params, token));
    },
    postDelete: (view, data, token) => {
        dispatch(singleActions.postDelete(view, data, token));
    },
    cancelUpload: (entry, round, token) => {
        dispatch(singleActions.cancelUpload(entry, round, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadForm));

class UploadFormContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default UploadFormContainer;
