

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as singleActions from '../../../store/actions/single';
import urls from '../../../utils/urls';
import ResultTable from './ResultTable';
import ChampResultTable from './ChampResultTable';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';

class ResultView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grade: ''
    };
  }

  componentDidMount(props) {
    this.props.get(
      this.props.match.params.id,
      this.props.match.params.type,
      this.props.auth.token._id
    );
  }

  render() {
    if (
      this.props.single.result?.event &&
      this.props.single.result.event._id === this.props.match.params.id
    ) {
      return (
        <div className={'page-container'}>
          <div className={'rounded elevated ex-pad'}>
            <div>
              <Row>
                <Col sm={6}>
                  <h2>{this.props.single.result.event.name}</h2>
                </Col>
                <Col sm={6} className={'text-right'}>
                  {this.props.single.result.event ? (
                    <select
                      value={this.state.grade}
                      className="form-control form-control-inline float-left"
                      onChange={(e) => {
                        this.setState({ grade: e.target.value });
                      }}>
                      <option value="">All</option>
                      {this.props.single.result.event.grades.map((grade, i) => {
                        return (
                          <option key={i} value={grade._id}>
                            {grade.title}
                          </option>
                        );
                      })}
                    </select>
                  ) : null}
                  {this.props.single.result.event.level === 1 ? (
                    <Button
                      variant={'primary'}
                      className="print-hide mt-2"
                      onClick={(e) => {
                        e.preventDefault();
                        let win;
                        if (this.props.match.params.type === 'championship') {
                          let link = urls.getS3Url(this.props.single.result.event.champPdf.path);
                          win = window.open(link, '_blank');
                          win.focus();
                        } else if (this.props.match.params.type === 'grades') {
                          let link = urls.getS3Url(this.props.single.result.event.gradesPdf.path);
                          win = window.open(link, '_blank');
                          win.focus();
                        } else if (this.props.match.params.type === 'championshipspecial') {
                          let link = urls.getS3Url(
                            this.props.single.result.event.champSpecialPdf.path
                          );
                          win = window.open(link, '_blank');
                          win.focus();
                        }
                      }}>
                      Save
                    </Button>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col>
                  <a href={'/public/results'}>Chart View</a>
                </Col>
              </Row>
              <div>
                {this.props.single.result.event.grades
                  .filter((g) => (this.state.grade !== '' ? g._id === this.state.grade : true))
                  .map((grade, g) => {
                    return grade.competitions.map((comp, c) => {
                      if (comp.results?.length > 0) {
                        if (grade.rounds === 3 || (grade.rounds === 2 && grade.judges === 3)) {
                          return (
                            <ChampResultTable
                              isMulti={true}
                              key={c}
                              competition={comp}
                              title={grade.title}
                              scoring={grade.scoring}
                            />
                          );
                        } else {
                          return (
                            <ResultTable
                              isMulti={true}
                              key={c}
                              competition={comp}
                              title={grade.title}
                              rounds={grade.rounds}
                            />
                          );
                        }
                      } else {
                        return null;
                      }
                    });
                  })}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  single: state.single
});

const mapDispatchToProps = (dispatch) => ({
  get: (id, type, token) => {
    dispatch(singleActions.getFullResults(id, type, token));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(ResultView));

class ResultViewContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default ResultViewContainer;
