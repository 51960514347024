


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import configureStore from './store/configureStore';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './components/App';
import {BrowserRouter} from "react-router-dom";
import './assets/css/swag.min.css';

const {persistor, store} = configureStore();

ReactDOM.render(


      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <BrowserRouter>

                <App />
              </BrowserRouter>
          </PersistGate>
      </Provider>

,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
