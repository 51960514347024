

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import * as tableActions from '../../../../../store/actions/table';
import GeneralTile from "../../../../Common/GeneralTile";
import placeholder from "../../../../../assets/images/placeholder-person.png";
import FormButtons from "../../../../Common/FormButtons";

class Dancers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        if (this.props.user.user.roles.indexOf('teacher') > -1) {
            this.props.getAdminDancers(this.props.match.params.id, this.props.auth.token._id);
        }
    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={'page-container'}>
                <FormButtons
                    confirm={(e) => {
                        //this.handleSubmit(values);
                    }}
                    back={(e) => {
                        e.preventDefault();
                        //this.step(values, "previous");
                    }}
                    showPrevious={false}
                    showRevert={false}
                    showNext={false}
                    next={(e) => {
                        e.preventDefault();
                        //this.handleSubmit(values);
                    }}
                    showDefault={false}
                    showSave={false}
                    showBack={true}
                    onBack={e => {
                        this.props.history.push('/app/admin/users');
                    }}
                    label={''}

                />
                    <div>

                        <div className={"section"}>

                            <div className={"tile-grid"}>
                                <Row>
                                    {
                                        this.props.table.students ?
                                            this.props.table.students.map((d, i) => {
                                                return <GeneralTile collection={"dancers"} {...d} key={i} data={d} placeholder={placeholder} />
                                            }) : <h4 className={"no-data"}>No Students</h4>}
                                </Row>
                            </div>
                        </div>
                    </div>



            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getStudents: (token) => {
        dispatch(tableActions.getStudents(0, 99, {}, token, "add"));
    },
    getAdminDancers: (userId, token) => {
        dispatch(tableActions.getAdminDancers(0, 99, {}, userId, token, "add"));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Dancers));

class DancersContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default DancersContainer;
