

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import * as singleActions from '../../store/actions/single';
import Table from "react-bootstrap/Table";


class LeaderboardModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            preview: ''
        }
    }
    hideModal = () => {
        this.props.handleModal(false)
    }

    componentDidMount() {

    }

    componentDidUpdate(props) {

    }


    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.hideModal(false)}>
                    <Modal.Body>
                        <div className={"spacer"} />
                        <h5>
                            #{this.props.events.competition.single.entry.sections[0].dancerNumber ? this.props.events.competition.single.entry.sections[0].dancerNumber : ''} |&nbsp;
                            {this.props.events.competition.single.event ? this.props.events.competition.single.event.name : ''} |&nbsp;
                            {this.props.events.competition.single.name ? this.props.events.competition.single.name : ''}
                            {this.props.events.competition.single.competition.grade.type === "championship" ? ` | R${this.props.match.params.round}` : ''}
                        </h5>
                        <div className={"spacer"} />
                        <Table responsive>
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>R1</th>
                                <th>R2</th>
                                {this.props.events.competition.single.rounds >= 3 ?
                                <th>Subtotal</th>
                                    : null}
                                {this.props.events.competition.single.rounds >= 3 ?
                                <th>R3</th>
                                    : null}
                                <th>Total</th>
                                {this.props.events.competition.competition.grade.scoring.ip ?
                                <th>IP</th>
                                    : null}
                                <th>Rank</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.events.competition.entries.map((d, i) => {
                                return (
                                    <tr key={i}>
                                        <td>{d.dancerNumber ? d.dancerNumber : d.entry.sections[0] ? d.entry.sections[0].dancerNumber : ''}</td>
                                        <td>{d.score.round1Score ? d.score.round1Score : '-'}</td>
                                        <td>{d.score.round2Score ? d.score.round2Score : '-'}</td>
                                        {this.props.events.competition.single.rounds >= 3 ?
                                        <td>{d.score.round1Score && d.score.round2Score ? d.score.round1Score + d.score.round2Score : '-'}</td>
                                            : null}
                                        {this.props.events.competition.single.rounds >= 3 ?
                                            <td>{d.score.round3Score ? d.score.round3Score : '-'}</td>
                                        : null}

                                        {this.props.events.competition.single.rounds >= 3 ?
                                            <td>{d.score.round1Score && d.score.round2Score && d.score.round3Score ? d.score.round1Score + d.score.round2Score + d.score.round3Score : '-'}</td>

                                            :

                                            <td>{d.score.round1Score && d.score.round2Score ? d.score.round1Score + d.score.round2Score : '-'}</td>
                                        }

                                        {this.props.events.competition.competition.grade.scoring.ip ?

                                            <td className={"text-success"}>{d.score.finalScore ? d.score.finalScore  : '-'}</td>
                                            : null}
                                        <td>
                                            {d.score.round3Rank ?
                                                d.score.round3Rank
                                                : d.score.round2Rank ?
                                                    d.score.round2Rank
                                                    : d.score.round1Rank ?
                                                        d.score.round1Rank
                                                        : '-'
                                            }
                                        </td>
                                    </tr>
                                )
                            })}

                            </tbody>
                        </Table>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(LeaderboardModal));

class LeaderboardModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default LeaderboardModalContainer;
