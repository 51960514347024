

const defaultState = {
    user: {},
    onboardingLoading: false
};

const user = (state = defaultState, action) => {
    switch (action.type) {
        case 'GOT_ONBOARDING':
            return {
                ...state,
                user: action.payload.user ? action.payload.user : state.user
            }
        case 'LOGOUT':
            return defaultState;
        case 'SET_LOADING':
            return {
                ...state,
                onboardingLoading: action.payload
            }
        case 'UPDATED_USER':
            console.log("DDD", action.payload);
            return {
                ...state,
                user: action.payload.data._id === state.user._id ? action.payload.data : state.user,
                onboardingLoading: false
            }
        case 'REMOVE_FAVORITE':
            return {
                ...state,
                user: {
                    ...state.user,
                    favorites: state.user.favorites.filter(f => f._id !== action.payload)
                }
            }
        case 'ADDED_FAVORITE':
            return {
                ...state,
                user: {
                    ...state.user,
                    favorites: state.user.favorites.concat(action.payload)
                }
            }
        case 'LOGIN':
            return {
                ...state,
                user: action.payload.data.data.user
            }
        case 'CHANGE_THEME':
            return {
                ...state,
                user: {
                    ...state.user,
                    theme: action.payload
                }
            }
        default:
            return state
    }
}

export default user;
