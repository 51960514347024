

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

class Revenue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={'revenue-container'}>
                {

                <HighchartsReact
                    highcharts={Highcharts}
                    options={this.props.reports.revenue}
                />
                }

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    reports: state.reports
});

const mapDispatchToProps = dispatch => ({

});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Revenue));

class RevenueContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default RevenueContainer;
