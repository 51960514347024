


const defaultState = {
    token: {},
    attempts: 0,
    sidebarOpen: false,
    error: '',
    message: '',
    validCode: false
};

const auth = (state = defaultState, action) => {
    switch(action.type) {
        case 'VALID_CODE':

            return {
                ...state,
                validCode: action.payload,
                message: action.payload ? '' : 'This code is invalid'
            }
        case 'SET_FORGOT_PASSWORD_MESSAGE':
            return {
                ...state,
                message: action.payload
            }
        case 'SET_ERROR':
            return {
                ...state,
                error: action.payload.message,
                attempts: state.attempts + 1
            }
        case 'LOGOUT':
            return defaultState;
        case 'LOGIN':
            //console.log
            return {
                ...state,
                token: action.payload.data.data.token
            };
        case 'TOGGLE_SIDEBAR':
            return {
                ...state,
                sidebarOpen: !state.sidebarOpen
            }
        case 'SET_SIDEBAR':
            return {
                ...state,
                sidebarOpen: action.payload
            }
        case 'LOGIN_FAILED':
            return {
                ...state,
                attempts: state.attempts + 1
            }
        default:
            return state
    }
};

export default auth;