

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import { ArrowBack } from "react-ionicons";
import * as eventActions from '../../../store/actions/events';
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import * as yup from "yup";
import { ArrowForward } from "react-ionicons";
import { Add } from 'react-ionicons';
import { Trash } from 'react-ionicons';
import SplitConfirmationModal from "../../Common/SplitConfirmationModal";
import SplitResultModal from "../../Common/SplitResultModal";
import Table from "react-bootstrap/Table";
import TableHeader from "../PDF/PDFTableHeader";
import Select from "../../Common/Select";
import CategoryPicker from "../../Common/CategoryPicker";

const ages = [
    {
        id: "all",
        "name": "All Ages"
    },
    {
        "id": "only5",
        "name": "5 years"
    },
    {
        "id": "only6",
        "name": "6 years"
    },
    {
        "id": "only7",
        "name": "7 years"
    },
    {
        "id": "only8",
        "name": "8 years"
    },
    {
        "id": "only9",
        "name": "9 years"
    },
    {
        "id": "only10",
        "name": "10 years"
    },
    {
        "id": "only11",
        "name": "11 years"
    },
    {
        "id": "only12",
        "name": "12 years"
    },
    {
        "id": "only13",
        "name": "13 years"
    },
    {
        "id": "only14",
        "name": "14 years"
    },
    {
        "id": "only15",
        "name": "15 years"
    },
    {
        "id": "only16",
        "name": "16 years"
    },
    {
        "id": "only17",
        "name": "17 years"
    },
    {
        "id": "only18",
        "name": "18 years"
    },
    {
        "id": "only19",
        "name": "19 years"
    },
    {
        "id": "only20",
        "name": "20 years"
    },
    {
        "id": "only21",
        "name": "21 years"
    },
    {
        "id": "U5",
        "name": "Under 5"
    },
    {
        "id": "U6",
        "name": "Under 6"
    },
    {
        "id": "U7",
        "name": "Under 7"
    },
    {
        "id": "U8",
        "name": "Under 8"
    },
    {
        "id": "U9",
        "name": "Under 9"
    },
    {
        "id": "U10",
        "name": "Under 10"
    },
    {
        "id": "U11",
        "name": "Under 11"
    },
    {
        "id": "U12",
        "name": "Under 12"
    },
    {
        "id": "U13",
        "name": "Under 13"
    },
    {
        "id": "U14",
        "name": "Under 14"
    },
    {
        "id": "U15",
        "name": "Under 15"
    },
    {
        "id": "U16",
        "name": "Under 16"
    },
    {
        "id": "U17",
        "name": "Under 17"
    },
    {
        "id": "U18",
        "name": "Under 18"
    },
    {
        "id": "U19",
        "name": "Under 19"
    },
    {
        "id": "U20",
        "name": "Under 20"
    },
    {
        "id": "U21",
        "name": "Under 21"
    },
    {
        "id": "8&O",
        "name": "8 & Over"
    },
    {
        "id": "9&O",
        "name": "9 & Over"
    },
    {
        "id": "10&O",
        "name": "10 & Over"
    },
    {
        "id": "11&O",
        "name": "11 & Over"
    },
    {
        "id": "12&O",
        "name": "12 & Over"
    },
    {
        "id": "13&O",
        "name": "13 & Over"
    },
    {
        "id": "14&O",
        "name": "14 & Over"
    },
    {
        "id": "15&O",
        "name": "15 & Over"
    },
    {
        "id": "16&O",
        "name": "16 & Over"
    },
    {
        "id": "17&O",
        "name": "17 & Over"
    },
    {
        "id": "18&O",
        "name": "18 & Over"
    },
    {
        "id": "19&O",
        "name": "19 & Over"
    },
    {
        "id": "20&O",
        "name": "20 & Over"
    },
    {
        "id": "21&O",
        "name": "21 & Over"
    },
    {
        "id": "22&O",
        "name": "22 & Over"
    },
    {
        "id": "23&O",
        "name": "23 & Over"

    },
    {
        "id": "5-6",
        "name": "5 and 6"
    },
    {
        "id": "6-7",
        "name": "6 and 7"
    },
    {
        "id": "7-8",
        "name": "7 and 8"
    },
    {
        "id": "8-9",
        "name": "8 and 9"
    },
    {
        "id": "9-10",
        "name": "9 and 10"
    },
    {
        "id": "10-11",
        "name": "10 and 11"
    },
    {
        "id": "11-12",
        "name": "11 and 12"
    },
    {
        "id": "12-13",
        "name": "12 and 13"
    },
    {
        "id": "13-14",
        "name": "13 and 14"
    },
    {
        "id": "14-15",
        "name": "14 and 15"
    },
    {
        "id": "15-16",
        "name": "15 and 16"
    },
    {
        "id": "16-17",
        "name": "16 and 17"
    },
    {
        "id": "17-18",
        "name": "17 and 18"
    },
    {
        "id": "18-19",
        "name": "18 and 19"
    },
    {
        "id": "19-20",
        "name": "19 and 20"
    },
    {
        "id": "20-21",
        "name": "20 and 21"
    },
    {
        "id": "21-22",
        "name": "21 and 22"
    },
    {
        "id": "5-7",
        "name": "5 to 7"
    },
    {
        "id": "6-8",
        "name": "6 to 8"
    },
    {
        "id": "7-9",
        "name": "7 to 9"
    },
    {
        "id": "8-10",
        "name": "8 to 10"
    },
    {
        "id": "9-11",
        "name": "9 to 11"
    },
    {
        "id": "10-12",
        "name": "10 to 12"
    },
    {
        "id": "11-13",
        "name": "11 to 13"
    },
    {
        "id": "11-14",
        "name": "11 - 14",
    },
    {
        "id": "12-14",
        "name": "12 to 14"
    },
    {
        "id": "13-15",
        "name": "13 to 15"
    },
    {
        "id": "14-16",
        "name": "14 to 16"
    },
    {
        "id": "15-17",
        "name": "15 to 17"
    },
    {
        "id": "16-18",
        "name": "16 to 18"
    },
    {
        "id": "17-19",
        "name": "17 to 19"
    },
    {
        "id": "18-20",
        "name": "18 to 20"
    },
    {
        "id": "19-21",
        "name": "19 to 21"
    },
    {
        "id": "20-22",
        "name": "20 to 22"
    },
    {
        "id": "21-23",
        "name": "21 to 23"
    }
];
class Split extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            showSubmitModal: false,
            showResultModal: false,
            submitted: false
        }
    }

    componentDidMount(props) {
        this.props.getSingleEvent(this.props.match.params.id, this.props.auth.token._id);
    }

    componentDidUpdate(props, state) {
        console.log("Updating", state, this.state);
        if (props.events.event) {
            if (props.events.event._id !== this.props.events.event._id) {
                console.log("Setting event");
                this.props.getSingleEvent(this.props.match.params.id, this.props.auth.token._id);
            }
        }
    }

    schema = yup.object({
        name: yup.string().required(),
        lname: yup.string().required(),
        birthdate: yup.string().required(),
        grade: yup.object().shape({
            name: yup.string().required(),
            id: yup.string().required("Level is required"),
        }),
        region: yup.object().shape({
            _id: yup.string().required("Region is required"),
        }),
        school: yup.object().shape({
            _id: yup.string().required("School is required"),
        })
    });

    handleSubmit = (values) => {

        //console.log("submitting ", values);

        this.setState({submitted: true});


        let params = {comps: values.comps, eventId: this.props.match.params.id};

        this.props.post('events/split', params, this.props.auth.token.token._id);
    }

    submitSplit = (values) => {

        this.setState({showSubmitModal: false});

        let obj = JSON.parse(JSON.stringify(values));
        console.log("OBJ", obj);
        obj.comps.forEach(item => {
            item[0] = item[0].code;
        })


        this.handleSubmit(obj);
    }


    render() {
        return (
            <div>

                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>
                                {this.props.location.pathname.split("/").length > 3 ?
                                    <Button variant={"flat btn-round"} onClick={e => {

                                        this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}`);
                                    }}>
                                        <ArrowBack />
                                    </Button>
                                    : null}

                                Split Competitions</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                Split competitions by using the form below.  In the first column, enter the competition code (<b>must be typed exactly as it appears in the event dashboard</b>) of the competition from where competitors will be moved.
                                <br /><br />
                                In the second column, enter the competition code of the competition you would like them moved to.
                                <br /><br />

                                <div className={"divider"} />
                                <br />


                                <b className={"text-danger"}>THIS OPERATION CAN NOT BE REVERSED!  PLEASE DOUBLE CHECK YOUR ENTERED INFORMATION BEFORE CLICKING SUBMIT.</b>
                                <br /><br />
                                <div className={"divider"} />
                                <br />

                            </p>
                        </Col>
                    </Row>
                    <Formik
                        validationSchema={this.schema}
                        onSubmit={this.handleSubmit}
                        enableReinitialize
                        ref={this.form}
                        initialValues={{
                            comps: [['', '', [{
                                code: '',
                                title: '',
                                rowTitle: '',
                                columnTitle: '',
                                columnCode: ''
                            }]]],
                            showSubmitModal: false
                        }}
                    >
                        {({
                              handleSubmit,
                              handleChange,
                              handleBlur,
                              values,
                              touched,
                              isValid,
                              errors,
                              isSubmitting,
                              setFieldValue
                          }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <SplitConfirmationModal submitSplit={this.submitSplit} values={values}
                                                        show={values.showSubmitModal} hideModal={() => setFieldValue('showSubmitModal', false)} />

                                <SplitResultModal values={values} data={this.props.events.splitResponse}
                                                  show={this.state.submitted} hideModal={() => this.setState({submitted: false})} />

                                <Table>
                                    <thead>
                                    <tr>
                                        <th>
                                            From
                                        </th>
                                        <th>
                                            =>
                                        </th>
                                        <th>
                                            To
                                        </th>
                                        <th>
                                            Level
                                        </th>
                                        <th>
                                            Dance
                                        </th>
                                        <th>
                                            Age Group
                                        </th>
                                        <th>
                                            Age Group Name
                                        </th>
                                        <th>
                                            Actions
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {values.comps.map((item, i) => {
                                        return (
                                            <React.Fragment>
                                                <tr>
                                                    <td>
                                                        <CategoryPicker
                                                            value={item[0] || ''}
                                                            itemValue={"_id"}
                                                            onChange={val => {
                                                                console.log("Val", val);

                                                                setFieldValue('comps', values.comps.map((c, ci) => {
                                                                    if (ci === i) {
                                                                        return {
                                                                            ...c,
                                                                            0: val,
                                                                            2: c[2].map(it => {
                                                                                it.title = val.grade.title;
                                                                                it.rowTitle = it.rowTitle || val.rowTitle;
                                                                                it.columnTitle = it.columnTitle || val.columnTitle;
                                                                                return it;
                                                                            })
                                                                        }
                                                                    } else {
                                                                        return c;
                                                                    }
                                                                }))



                                                                //this.props.updateJudge(this.props.match.params.round, n - 1, val);
                                                                /*
                                                                setFieldValue('template', {
                                                                    ...this.props.events.competition.template,
                                                                    rounds: {
                                                                        ...this.props.events.competition.template.rounds,
                                                                        [this.props.match.params.round.toString()]: {
                                                                            ...this.props.events.competition.template.rounds[this.props.match.params.round.toString()],
                                                                            judges: this.props.events.competition.template.rounds[this.props.match.params.round.toString()].judges.map((item, i) => {
                                                                                if (i === n - 1) {
                                                                                    return val;
                                                                                } else {
                                                                                    return item;
                                                                                }
                                                                            })
                                                                        }
                                                                    }
                                                                })
                                                                */
                                                            }}
                                                            remote={true}
                                                            remoteKey={''}
                                                            remoteFields={['code', 'rowTitle', 'columnTitle']}
                                                            urlRoute={`events/competitionSearch?eventId=${this.props.match.params.id}`}
                                                            displayValue={"rowTitle"}
                                                            displayValue3={"code"}
                                                            imageKey={"image"}
                                                            localState={true}
                                                            displayValue2={"columnTitle"}
                                                            showDelete={false}


                                                        />
                                                        {/*
                                                    <Form.Control
                                                        type="text"
                                                        name="lname"
                                                        placeholder="Competition #"
                                                        value={item[0]}
                                                        onChange={(val) => {
                                                            setFieldValue('comps', values.comps.map((c, ci) => {
                                                                if (ci === i) {
                                                                    return {
                                                                        ...c,
                                                                        0: val.target.value
                                                                    }
                                                                } else {
                                                                    return c;
                                                                }
                                                            }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={`form-control`}
                                                    />
                                                    */}
                                                    </td>
                                                    <td>
                                                        =>
                                                    </td>
                                                    <td>
                                                        <Select

                                                            items={[
                                                                {id: '', name: 'Select split method'},
                                                                {id: 'random', name: 'Split randomly'},
                                                                {id: 'age', name: 'Split by age'}
                                                            ]}
                                                            value={item[1] ? {id: item[1]} : {id: '', name: 'Select split method'} || {id: '', name: 'Select split method'}}
                                                            itemValue={"id"}
                                                            onChange={val => {
                                                                setFieldValue('comps', values.comps.map((c, ci) => {
                                                                    if (ci === i) {
                                                                        return {
                                                                            ...c,
                                                                            1: val.id
                                                                        }
                                                                    } else {
                                                                        return c;
                                                                    }
                                                                }))
                                                            }}
                                                            displayValue={"name"}

                                                        />

                                                        {/*
                                                    <Form.Control
                                                        type="text"
                                                        name="lname"
                                                        placeholder="Competition #"
                                                        value={item[1]}
                                                        onChange={(val) => {
                                                            setFieldValue('comps', values.comps.map((c, ci) => {
                                                                if (ci === i) {
                                                                    return {
                                                                        ...c,
                                                                        1: val.target.value
                                                                    }
                                                                } else {
                                                                    return c;
                                                                }
                                                            }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={`form-control`}
                                                    />
                                                    */}
                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>


                                                    </td>
                                                    <td>

                                                    </td>
                                                    <td>
                                                        <Button variant={"flat"} className={`btn btn-flat raised ${isSubmitting ? 'disabled': ''}`} onClick={e =>
                                                        {
                                                            e.preventDefault();
                                                            setFieldValue('comps', values.comps.filter((c, ci) => ci !== i));

                                                        }}>

                                                            <Trash  />
                                                        </Button>
                                                    </td>
                                                </tr>
                                                {item[2].map((it, iti) => {
                                                    return (
                                                        <tr>
                                                            <td>

                                                                {/*
                                                    <Form.Control
                                                        type="text"
                                                        name="lname"
                                                        placeholder="Competition #"
                                                        value={item[0]}
                                                        onChange={(val) => {
                                                            setFieldValue('comps', values.comps.map((c, ci) => {
                                                                if (ci === i) {
                                                                    return {
                                                                        ...c,
                                                                        0: val.target.value
                                                                    }
                                                                } else {
                                                                    return c;
                                                                }
                                                            }))
                                                        }}
                                                        onBlur={handleBlur}
                                                        className={`form-control`}
                                                    />
                                                    */}
                                                            </td>
                                                            <td>
                                                                =>
                                                            </td>
                                                            <td>


                                                                <Form.Control
                                                                    type="text"
                                                                    name="lname"
                                                                    placeholder="Competition #"
                                                                    value={it.code}
                                                                    onChange={(val) => {
                                                                        setFieldValue('comps', values.comps.map((c, ci) => {
                                                                            if (ci === i) {
                                                                                return {
                                                                                    ...c,
                                                                                    2: c[2].map((c2,c2i) => {
                                                                                        if (c2i === iti) {
                                                                                            return {
                                                                                                ...c2,
                                                                                                code: val.target.value
                                                                                            }
                                                                                        } else {
                                                                                            return c2
                                                                                        }
                                                                                    })
                                                                                }

                                                                            } else {
                                                                                return c;
                                                                            }
                                                                        }))
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    className={`form-control`}
                                                                />

                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="level"
                                                                    placeholder="Level"
                                                                    value={it.title || ''}
                                                                    onChange={(val) => {


                                                                        setFieldValue('comps', values.comps.map((c, ci) => {
                                                                            if (ci === i) {
                                                                                return {
                                                                                    ...c,
                                                                                    2: c[2].map((c2,c2i) => {
                                                                                        if (c2i === iti) {
                                                                                            return {
                                                                                                ...c2,
                                                                                                title: val.target.value
                                                                                            }
                                                                                        } else {
                                                                                            return c2
                                                                                        }
                                                                                    })
                                                                                }

                                                                            } else {
                                                                                return c;
                                                                            }
                                                                        }))

                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    className={`form-control`}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="rowTitle"
                                                                    placeholder="Dance"
                                                                    value={it.rowTitle || ''}
                                                                    onChange={(val) => {
                                                                        setFieldValue('comps', values.comps.map((c, ci) => {
                                                                            if (ci === i) {
                                                                                return {
                                                                                    ...c,
                                                                                    2: c[2].map((c2,c2i) => {
                                                                                        if (c2i === iti) {
                                                                                            return {
                                                                                                ...c2,
                                                                                                rowTitle: val.target.value
                                                                                            }
                                                                                        } else {
                                                                                            return c2
                                                                                        }
                                                                                    })
                                                                                }

                                                                            } else {
                                                                                return c;
                                                                            }
                                                                        }))
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    className={`form-control`}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Select

                                                                    items={ages}
                                                                    value={it.columnCode ? {id: it.columnCode} : {} || {}}
                                                                    itemValue={"id"}
                                                                    onChange={val => {
                                                                        setFieldValue('comps', values.comps.map((c, ci) => {
                                                                            if (ci === i) {
                                                                                return {
                                                                                    ...c,
                                                                                    2: c[2].map((c2,c2i) => {
                                                                                        if (c2i === iti) {
                                                                                            return {
                                                                                                ...c2,
                                                                                                columnCode: val.id
                                                                                            }
                                                                                        } else {
                                                                                            return c2
                                                                                        }
                                                                                    })
                                                                                }

                                                                            } else {
                                                                                return c;
                                                                            }
                                                                        }))
                                                                    }}
                                                                    displayValue={"name"}

                                                                />

                                                            </td>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    name="columnCode"
                                                                    placeholder="Age Group Name"
                                                                    value={it.columnTitle || ''}
                                                                    onChange={(val) => {
                                                                        setFieldValue('comps', values.comps.map((c, ci) => {
                                                                            if (ci === i) {
                                                                                return {
                                                                                    ...c,
                                                                                    2: c[2].map((c2,c2i) => {
                                                                                        if (c2i === iti) {
                                                                                            return {
                                                                                                ...c2,
                                                                                                columnTitle: val.target.value
                                                                                            }
                                                                                        } else {
                                                                                            return c2
                                                                                        }
                                                                                    })
                                                                                }

                                                                            } else {
                                                                                return c;
                                                                            }
                                                                        }))
                                                                    }}
                                                                    onBlur={handleBlur}
                                                                    className={`form-control`}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Button variant={"flat"} className={`btn btn-flat raised ${isSubmitting ? 'disabled': ''}`} onClick={e =>
                                                                {
                                                                    e.preventDefault();
                                                                    //setFieldValue('comps', values.comps.filter((c, ci) => ci !== i));
                                                                    setFieldValue('comps', values.comps.map((c, ci) => {
                                                                        if (ci === i) {
                                                                            return {
                                                                                ...c,
                                                                                2: c[2].filter((c3, c3i) => c3i !== iti)
                                                                            }
                                                                        } else {
                                                                            return c
                                                                        }
                                                                    }));
                                                                }}>

                                                                    <Trash  />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                                <tr>
                                                    <td colSpan={7}>

                                                    </td>
                                                    <td>
                                                        <Button variant={"flat"} className={`btn btn-flat raised ${isSubmitting ? 'disabled': ''}`} onClick={e =>
                                                        {
                                                            e.preventDefault();
                                                            //setFieldValue('comps', values.comps.filter((c, ci) => ci !== i));
                                                            setFieldValue('comps', values.comps.map((c, ci) => {
                                                                if (ci === i) {
                                                                    return {
                                                                        ...c,
                                                                        2: c[2].concat({
                                                                            code: '',
                                                                            title: '',
                                                                            rowTitle: '',
                                                                            columnTitle: '',
                                                                            columnCode: ''
                                                                        })
                                                                    }
                                                                } else {
                                                                    return c
                                                                }
                                                            }));

                                                        }}>

                                                            <Add  />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            </React.Fragment>




                                        )
                                    })}
                                    </tbody>


                                </Table>


                                <Row>
                                    <Col>
                                        <div className={"text-left"}>
                                            <br />
                                            <br />
                                            <div className={"separator"}></div>
                                            <Button variant={"flat"} className={`btn btn-flat raised ${isSubmitting ? 'disabled': ''}`} onClick={e =>
                                            {
                                                e.preventDefault();
                                                setFieldValue('comps', values.comps.concat([['', '', [{
                                                    code: '',
                                                    title: '',
                                                    rowTitle: '',
                                                    columnTitle: '',
                                                    columnCode: ''
                                                }]]]));

                                            }}>

                                                Add Row  &nbsp; <Add  />
                                            </Button>
                                            <br /><br />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className={"divider"} />
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <div className={"text-right"}>
                                            <br />
                                            <br />
                                            <div className={"separator"}></div>
                                            <Button variant={"primary"} className={`${isSubmitting ? 'disabled': ''}`} onClick={e =>
                                            {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (!isSubmitting) {
                                                    setFieldValue('showSubmitModal', true)
                                                    //this.setState({showSubmitModal: true});
                                                }

                                            }}>

                                                Submit  &nbsp; <ArrowForward color={"#fff"} />
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        )}
                    </Formik>


                </div>
                <div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getSingleEvent: (id, token) => {
        dispatch(eventActions.getSingleEvent(id, token));
    },
    post: (view, data, token) => {
        dispatch(eventActions.postSplit(view, data, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Split));

class SplitContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default SplitContainer;
