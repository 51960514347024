

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';

class EntrySteps extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    steps = () => [
            {title: 'Competition Data'},
            {title: 'Payment'},
            {title: this.props.single.event.inPerson ? 'Event Details' : 'Video Submission'},
        ]


    render() {
        let steps = this.steps();
        return (
            <div className={"onboarding-steps event-steps mt-0"}>
                {!isNaN(this.props.single.single.step) ?
                    steps.map((step, i) => {
                        return (
                            <div key={i} className={`onboarding-step ${this.props.single.single.step === i ? 'active' : ''} ${this.props.single.single.step === i+1 ? 'active-right' : ''}`} style={{width: `calc(100% / ${this.steps().length})`}}>
                                <label>{step.title}</label>
                            </div>
                        )
                    })
                    : null}
            </div>
        )
    }

}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EntrySteps));

class EntryStepsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default EntryStepsContainer;
