

const defaultState = {
    sidebarOpen: false
};

const app = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return defaultState;
        default:
            return defaultState
    }
}

export default app;