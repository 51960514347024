

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as tableActions from '../../../store/actions/table';
import EntryTile from '../../Common/EntryTile';
import placeholder2 from '../../../assets/images/placeholder1.png';
import * as singleActions from '../../../store/actions/single';
import { Camera } from 'react-ionicons';

class Entries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount(props) {
    this.props.getTableData('entries', 0, 999, { number: 1 }, this.props.auth.token._id, 'add');
    if (this.props.user.user.roles.indexOf('teacher') > -1) {
      this.props.getStudentEntries(0, 999, { number: 1 }, this.props.auth.token._id, 'add');
    }
  }

  componentDidUpdate(props, state) {
    if (
      (this.props.table.data.length !== this.state.items.length ||
        this.props.table.updateCount > props.table.updateCount ||
        this.props.table.data.length !== this.state.items.length) &&
      this.props.table.view === 'events'
    ) {
      this.setState({ items: this.props.table.data });
    }
  }

  render() {
    return (
      <div className={'page-container-slim'}>
        <div className={'page-header'}>
          <Row>
            <Col>
              <h1>
                <Camera /> Entries
              </h1>
            </Col>
            <Col>
              {this.props.user.user ? (
                this.props.user.user.roles ? (
                  this.props.user.user.roles.indexOf('organizer') > -1 ||
                  this.props.user.user.role === 'admin' ? (
                    <Col></Col>
                  ) : null
                ) : null
              ) : null}
            </Col>
          </Row>
        </div>

        <div className={'section'}>
          <div className={'tile-grid'}>
            <Row>
              {this.props.table.data.length > 0 ? (
                this.props.table.data.map((d, i) => {
                  return <EntryTile {...d} data={d} key={i} placeholder={placeholder2} />;
                })
              ) : (
                <h4 className={'no-data'}>No Entries Found</h4>
              )}
            </Row>
          </div>
        </div>

        {this.props.user.user.roles.indexOf('teacher') > -1 ? (
          <div>
            <div className={'page-header'}>
              <Row>
                <Col>
                  <h1>
                    <Camera /> My Students Entries
                  </h1>
                </Col>
                <Col></Col>
              </Row>
            </div>

            <div className={'section'}>
              <div className={'tile-grid'}>
                <Row>
                  {this.props.table.studentEntries ? (
                    this.props.table.studentEntries.length > 0 ? (
                      this.props.table.studentEntries.map((d, i) => {
                        return <EntryTile {...d} data={d} key={i} placeholder={placeholder2} />;
                      })
                    ) : (
                      <h4 className={'no-data'}>No Entries Found</h4>
                    )
                  ) : (
                    <h4 className={'no-data'}>No Entries Found</h4>
                  )}
                </Row>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  getStudentEntries: (skip, limit, sort, token, mode) => {
    dispatch(tableActions.getStudentEntries(skip, limit, sort, token, mode));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Entries));

class EntriesContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EntriesContainer;
