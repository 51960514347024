

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import * as yup from 'yup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as singleActions from '../../../store/actions/single';
import Select from '../../Common/Select';
import FormButtons from '../../Common/FormButtons';

const schema = yup.object({
  name: yup.string().required('This field is required')
});

class EventReview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fileSelectField: '',
      filePickerOpen: false,
      selectingFileType: 'image',
      preview: '',
      image: '',
      createModerator: '',
      createAdmin: ''
    };
  }

  handleFormModal = (e) => {
    this.setState({ filePickerOpen: false });
  };

  selectFile = (file) => {
    //this.props.updateField('image', file);
    this.props.setValues({ image: file });
  };

  handleSubmit = (values, direction) => {
    let view = 'events';

    let data = new FormData();
    data.append('files', this.state.image);

    let params = { publish: values, eventId: this.props.single.single._id, direction: direction };

    data.set('data', JSON.stringify(params));

    if (params.event?._id || params.eventId) {
      this.props.patch(view, params.event?._id ?? params.eventId, data, this.props.auth.token._id);
    } else {
      this.props.post(view, data, this.props.auth.token._id, (id) => {
        this.props.history.push('/app/events/' + id + '/edit/4');
      });
    }
  };

  render() {
    return (
      <div>
        <Formik
          validationSchema={schema}
          onSubmit={this.handleSubmit}
          enableReinitialize
          initialValues={{
            published: this.props.single.single.published || false
          }}>
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <FormButtons
                back={(e) => {
                  e.preventDefault();
                  this.handleSubmit(values, 'subtract');
                }}
                onBack={(e) => {
                  if (this.props.single.single._id) {
                    this.props.history.push(`/app/event-dashboard/${this.props.single.single._id}`);
                  } else {
                    this.props.history.push(`/app/event-dashboard`);
                  }
                }}
                showBack={true}
                showPrevious={false}
                showRevert={false}
                showNext={false}
                confirm={(e) => {
                  e.preventDefault();
                  this.handleSubmit(values);
                }}
                showDefault={true}
              />
              <Row>
                <Col md={6}>
                  <div className={'field-container'}>
                    <label>Status</label>

                    <Select
                      items={[
                        { id: false, name: 'Unpublished' },
                        { id: true, name: 'Published' }
                      ]}
                      value={values.published ? { id: true } : { id: false }}
                      itemValue={'id'}
                      onChange={(val) => {
                        setFieldValue('published', val.id);
                      }}
                      displayValue={'name'}
                    />
                  </div>
                </Col>
              </Row>

              <div className={'spacer'} />
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  single: state.single,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  post: (view, data, token, redirect) => {
    dispatch(singleActions.postFormData(view, data, token, true, redirect, true));
  },
  postOnboarding: (view, data, token, redirect) => {
    dispatch(singleActions.postNextStep(view, data, token, true, redirect));
  },
  patch: (view, id, data, token) => {
    dispatch(singleActions.patchFormData(view, id, data, token, false, null, true));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('events', id, token));
  },
  setBlankSingle: () => {
    dispatch(singleActions.setSingleEntry({ step: 0 }));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(EventReview));

class EventReviewContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default EventReviewContainer;
