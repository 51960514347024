

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Add } from 'react-ionicons';
import Button from 'react-bootstrap/Button';
import { Build } from 'react-ionicons';
import * as singleActions from '../../store/actions/single';
import Bound from './Bound';
import urls from '../../utils/urls';

class SchoolTile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: ''
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(props) {
    if (props.data !== this.props.data) {
      this.init();
    } else {
      if (
        (!this.state.preview && this.props.data.image) ||
        this.props.data.image !== props.data.image
      ) {
        this.init();
      }
    }
  }

  init = () => {
    if (this.props.data) {
      if (this.props.data.image) {
        if (this.props.data.image.path) {
          this.setState({
            preview: urls.getS3Url(this.props.data.image.thumb || this.props.data.image.path)
          });
        }
      } else if (this.state.preview) {
        this.setState({ preview: '' });
      }
    }
  };

  render() {
    return (
      <Bound>
        {this.props.more ? (
          <div
            className={'tile-container'}
            onClick={(e) => this.props.history.push(`/app/schools`)}
            style={{ background: `rgba(0,0,0,.4)` }}>
            <div className={'tile-gradient'}></div>
            <div className={' text-center tile-symbol'}>
              <Add fontSize={'64px'} />
              <h3 className={'more'}>{this.props.more} More</h3>
            </div>
          </div>
        ) : (
          <div
            className={'tile-container'}
            onClick={(e) => {
              if (this.props.onClick) {
                this.props.onClick(e);
              } else {
                this.props.history.push(`/app/schools/${this.props.data._id}`);
              }
            }}
            style={{
              background: `url(${
                this.state.preview ? this.state.preview : this.props.placeholder
              }), rgba(0,0,0,.2)`
            }}>
            {this.props.data ? (
              this.props.data.admins ? (
                this.props.data.admins.indexOf(this.props.user.user._id) > -1 ? (
                  <Button
                    variant={'flat'}
                    className={'floating-edit-button'}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.props.history.push(`/app/schools/${this.props.data._id}/edit`);
                    }}>
                    <Build />
                  </Button>
                ) : null
              ) : null
            ) : null}
            <div className={'tile-gradient'}></div>

            <div className={'tile-content'}>
              <h4>{this.props.name}</h4>
              <h5>{this.props.date}</h5>
            </div>

            {this.props.data ? (
              this.props.data.admins ? (
                this.props.data.admins.indexOf(this.props.user.user._id) > -1 ? (
                  <div className={'tile-delete'}>
                    <Button
                      variant={'link text-danger'}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.onClickDelete(e, this.props.data);
                      }}>
                      Delete
                    </Button>
                  </div>
                ) : null
              ) : null
            ) : null}
          </div>
        )}
      </Bound>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  user: state.user
});

const mapDispatchToProps = (dispatch) => ({
  setSingle: (d) => {
    dispatch(singleActions.setSingleEntry(d));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SchoolTile));

class SchoolTileContainer extends React.Component {
  render() {
    return <Connected {...this.props} />;
  }
}

export default SchoolTileContainer;
