

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import * as tableActions from '../../../../store/actions/table';
import GeneralTile from "../../../Common/GeneralTile";
import placeholder from "../../../../assets/images/placeholder-person.png";

class Students extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        if (this.props.user.user.roles.indexOf('teacher') > -1) {
            this.props.getStudents(this.props.auth.token._id);
        }
    }

    componentDidUpdate(props, state) {

    }

    render() {

        let shouldDisable = false;
        if (!this.props.onboarding) {
            if (this.props.single.single) {
                if (this.props.single.single.admins) {
                    if (this.props.single.single.admins.filter(item => item._id === this.props.user.user._id).length === 0) {
                        shouldDisable = true;
                    }
                }
            }
        }


        if (shouldDisable) {

            return false;
        } else {
            return (
                <div className={'page-container'}>
                    {this.props.user.user.roles.indexOf('teacher') > -1 ?
                        <div>

                            <div className={"section"}>

                                <div className={"tile-grid"}>
                                    <Row>
                                        {
                                            this.props.table.students ?
                                                this.props.table.students.map((d, i) => {
                                                    return <GeneralTile collection={"dancers"} {...d} key={i} data={d}
                                                                        placeholder={placeholder}/>
                                                }) : <h4 className={"no-data"}>No Students</h4>}
                                    </Row>
                                </div>
                            </div>
                        </div>
                        : null}


                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getStudents: (token) => {
        dispatch(tableActions.getStudents(0, 99, {}, token, "add"));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Students));

class StudentsContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default StudentsContainer;
