

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as singleActions from '../../../store/actions/single';
import urls from "../../../utils/urls";
import ResultTable from './ResultTable';
import ChampResultTable from "./ChampResultTable";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";


class FullResultView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.get(this.props.match.params.id, this.props.auth.token._id);
        this.init();
    }

    componentDidUpdate(props) {
        if(props.single.result !== this.props.single.result) {
            this.init();
        }
    }

    init = () => {
        if(this.props.single.result) {
            if(this.props.single.result.dancer) {
                if (this.props.single.result.dancer.image) {
                    if (this.props.single.result.dancer.image.path) {
                        this.setState({preview: urls.getS3Url(this.props.single.result.dancer.image.thumb || this.props.single.result.dancer.image.path)})
                    }
                }

            }
        }


    }

    render() {
        if (this.props.single.result) {
            if (this.props.single.result.dancer) {
                if (this.props.single.result.dancer._id === this.props.match.params.id) {
                    return (
                        <div className={'page-container'}>
                            <div className={"rounded elevated ex-pad"}>
                                <div className={"circle-tile print-hide"}>
                                <div>
                                    <div className={"tile-container"} style={{background: `url(${this.state.preview ? this.state.preview : this.props.placeholder}), rgba(0,0,0,.2)`}}>

                                    </div>

                                    <div className={"tile-content"}>
                                        <h4>{this.props.single.result.dancer.name || ''}</h4>
                                        <h5>{this.props.single.result.dancer.school ? this.props.single.result.dancer.school.name : ''}</h5>
                                    </div>

                                </div>
                                </div>
                                <h2 className={"print-only"}>{this.props.single.result.dancer.name + " " + this.props.single.result.dancer.lname || ''}</h2>

                                <div className={"result-container"}>
                                    {this.props.single.result.events.map((event, e) => {
                                        return (
                                            <div>
                                                <div className={"divider"} />
                                                <div className={"spacer"} />

                                                <Row>
                                                    <Col sm={6}>
                                                        <h2>{event.name}</h2>
                                                    </Col>
                                                    {/*
                                                    <Col sm={6} className={"text-right"}>
                                                        <Button variant={"primary"} className="print-hide" onClick={e => {
                                                            window.print();
                                                        }}>Save</Button>
                                                    </Col>
                                                    */}
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <p>DETAILED RESULTS</p>
                                                        <p>To see the dancers who placed in this competition, please refer to the <a href={"https://app.feisfwd.com/public/results"} target={"_blank"} rel="noopener noreferrer">public result page</a></p>
                                                    </Col>
                                                </Row>
                                                <div>
                                                    {
                                                        event.grades.map((grade, g) => {
                                                            return grade.competitions.map((comp, c) => {
                                                                if (grade.rounds === 3) {
                                                                    return <ChampResultTable key={c} competition={comp} title={grade.title} scoring={grade.scoring} />
                                                                } else {
                                                                    return <ResultTable key={c} competition={comp} title={grade.title} />

                                                                }
                                                            })

                                                        })
                                                    }
                                                </div>
                                            </div>
                                        )

                                    })}
                                </div>

                            </div>
                        </div>
                    )
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } else {
            return null;
        }

    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    get: (id, token) => {
        dispatch(singleActions.getSingleResult(id, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(FullResultView));

class FullResultViewContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default FullResultViewContainer;