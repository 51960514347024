

import React, {Suspense} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import * as singleActions from '../../store/actions/single';


import RegionForm from '../Pages/Admin/Regions/RegionForm';
import SchoolForm from '../Pages/Schools/SchoolForm';


class FormModal extends React.Component {
    hideModal = () => {
        this.props.handleFormModal(false)
    }

    postSingle = (item) => {
        let uri_fragments = this.props.location.pathname.split('/');
        let uri = uri_fragments[uri_fragments.length - 1];


        if (this.props.location.pathname.indexOf('/music') > -1) {

            if (uri_fragments.length >= 4) {
                item.parent = uri;
            }

            if (this.props.single.single._id) {
                this.props.patch('categories', this.props.single.single._id, item, this.props.auth.token._id);
            } else {
                this.props.post('categories', item, this.props.auth.token._id);
            }
        } else if (this.props.location.pathname.indexOf('files') > -1) {
            this.props.postFile(uri, item, this.props.auth.token._id);
        } else if (this.props.single.single._id) {
            this.props.patch(uri, this.props.single.single._id, item, this.props.auth.token._id);
        } else {

                this.props.post(uri, item, this.props.auth.token._id);

        }
    }

    renderContent = () => {
        switch (this.props.location.pathname) {
            case '/app/admin/regions':
                return <RegionForm hideModal={this.hideModal} post={item => this.postSingle(item)}/>

            case '/app/admin/schools':
                return <SchoolForm hideModal={this.hideModal} post={item => this.postSingle(item)}/>
            default:
                return null
        }
    }
    render() {
        return (
            <div className={this.props.user.user.theme}>
                <Modal centered size={'lg'} className={`theme-${this.props.user.user.theme} ${this.props.user.user ? this.props.user.user.darkTheme ? 'dark-theme' : '' : ''}`} show={this.props.show} onHide={e => this.props.handleFormModal(false)}>
                    <Modal.Body>
                        <Suspense fallback={'e'}>
                            {this.renderContent()}
                        </Suspense>

                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    user: state.user,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    post: (uri, item, token) => {
        dispatch(singleActions.post(uri, item, token));
    },
    postFile: (uri, fd, token) => {
        dispatch(singleActions.postFile(uri, fd, token));
    },
    patch: (uri, id, item, token) => {
        dispatch(singleActions.patch(uri, id, item, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(FormModal));

class FormModalContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default FormModalContainer;
