

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../store/actions/table';
import urls from "../../utils/urls";
import placeholder from "../../assets/images/placeholder-person.png";

class AccountCircle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: null
        }
    }

    componentDidMount(props) {
        this.init();
    }

    componentDidUpdate(props, state) {
        if (this.props.user !== props.user) {
            this.init();
        }
    }

    init = () => {
        this.setState({image: urls.getS3Url(this.props.user.user.image.thumb || this.props.user.user.image.path)})
    }

    render() {
        return (
            <div className={"artist-img avatar header-img"} style={{background: this.state.image ? `url(${this.state.image}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)` : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>

        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountCircle));

class AccountCircleContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default AccountCircleContainer;