

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import placeholder2 from "../../../assets/images/placeholder1.png";
import { Clipboard } from 'react-ionicons';
import JudgeEventTile from "../../Common/JudgeEventTile";


class TabulatorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.getTabulatorPage(this.props.auth.token._id);
    }


    render() {
        return (
            <div className={'page-container'}>


                {this.props.user.user.roles ?
                    this.props.user.user.roles.indexOf('judge') > -1 ?
                        this.props.table.judgeEvents ?

                            <div>
                                <div className={"page-header"} >
                                    <Row>
                                        <Col>
                                            <h1><Clipboard /> Events I'm Tabulating</h1>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={"section pt-1"}>
                                    <div className={"tile-grid"}>
                                        <Row>

                                            {this.props.table.judgeEvents.length === 0 ?
                                                <h4 className={"no-data"}>No Events Found</h4>
                                                : null}
                                            {this.props.table.judgeEvents.map((d, i) => {
                                                return <JudgeEventTile isJudge={false} isTabulator={true} showModal={(event) => this.showModal(event)} {...d} data={d} key={i} placeholder={placeholder2} />
                                            })}


                                        </Row>
                                    </div>
                                </div>
                            </div>
                            : null
                        : null
                    : null}

            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getTabulatorPage: (token) => {
        dispatch(tableActions.getTabulatorPage(token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(TabulatorPage));

class TabulatorPageContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default TabulatorPageContainer;
