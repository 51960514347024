

const defaultState = {
    player: {
        open: false,
        audio: null,
        playing: false,
        volume: 1
    }
};

const player = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return defaultState;
        case 'UPDATE_PLAYER_DATA':
            return {
                ...state,
                player: {
                    ...state.player,
                    [action.payload.field]: action.payload.value
                }
            }
        case 'SET_PLAYER_DATA':
            return {
                ...state,
                player: {
                    playing: true,
                    audio: action.payload,
                    open: true,
                    volume: state.player ? state.player.volume || 1 : 1
                }
            }
        case 'SET_PLAYER_OPEN':
            return {
                ...state,
                player: {
                    ...state.player,
                    open: false,
                    playing: false
                }
            }
        default:
            return {
                ...state
            };
    }
}

export default player;