

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as tableActions from '../../../store/actions/table';

import SchoolForm from './SchoolForm';
import * as singleActions from '../../../store/actions/single';
import SchoolProfile from './SchoolProfile';

class Single extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    };
  }

  componentDidMount(props) {
    this.init();
  }

  componentDidUpdate(props, state) {
    if (this.props.match.params.id !== props.match.params.id) {
      this.init();
    }
  }

  init = () => {
    if (this.props.match.params.id === 'new') {
      this.props.setSingle({});
    } else {
      this.props.get(this.props.match.params.id, this.props.auth.token._id);
    }
  };

  render() {
    return (
      <div className={'page-container elevated padded rounded school-profile profile-page'}>
        {this.props.match.params.subview ? (
          this.props.match.params.subview === 'edit' ? (
            <SchoolForm />
          ) : this.props.match.params.id === 'new' ? (
            <SchoolForm />
          ) : (
            <SchoolProfile />
          )
        ) : this.props.match.params.id === 'new' ? (
          <SchoolForm />
        ) : (
          <SchoolProfile />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  table: state.table,
  single: state.single
});

const mapDispatchToProps = (dispatch) => ({
  getTableData: (view, skip, limit, sort, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
  },
  getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
    dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
  },
  get: (id, token) => {
    dispatch(singleActions.getSingle('schools', id, token));
  },
  setSingle: (d) => {
    dispatch(singleActions.setSingleEntry(d));
  }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Single));

class SingleContainer extends React.Component {
  render() {
    return (
      <div>
        <Connected {...this.props} />
      </div>
    );
  }
}

export default SingleContainer;
