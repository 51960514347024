

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {withFormik} from "formik";
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import { ArrowBack } from "react-ionicons";
import CategoryPicker from "../../Common/CategoryPicker";
import * as eventActions from '../../../store/actions/events';


class AddJudges extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {

    }

    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div className={'page-container'}>
                <div className={'page-header'}>
                    <Row>
                        <Col>
                            <h1>
                                {this.props.location.pathname.split("/").length > 3 ?
                                    <Button variant={"flat btn-round"} onClick={e => {

                                        this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/judges`);
                                    }}>
                                        <ArrowBack />
                                    </Button>
                                    : null}

                                Add Judges</h1>
                        </Col>
                    </Row>
                </div>
                <Row>
                    <Col md={12}>
                        <div className={"field-container"}>

                            {this.props.values.judges.map((c, i) => {
                                if (c) {
                                    return (
                                        <CategoryPicker
                                            key={c.email}
                                            items={this.props.single.users}
                                            value={c}
                                            itemValue={"_id"}
                                            selectedItems={this.props.values.judges.map(item => item._id)}
                                            onChange={val => this.props.setFieldValue('judges', this.props.values.judges.map((cats, cati) => {
                                                if (cati === i) {
                                                    let relevant = this.props.single.users.filter(c => c._id === val._id);
                                                    return relevant[0] || {};
                                                } else {
                                                    return cats
                                                }
                                            }))}
                                            remote={true}
                                            urlRoute={"/search"}
                                            remoteKey={'users'}
                                            displayValue={"email"}
                                            imageKey={"image"}
                                            showDelete={true}
                                            onDelete={val => {
                                                this.props.setFieldValue('judges', this.props.values.judges.filter(c => c.email !== val.email))
                                            }}
                                            label={`Judge ${i + 1}`}

                                        />
                                    )
                                }else {
                                    return null;
                                }
                            })}
                        </div>
                    </Col>
                    <Col md={12}>
                        <CategoryPicker
                            items={this.props.single.users}
                            value={''}
                            itemValue={"_id"}
                            selectedItems={this.props.values.judges.map(item => item._id)}
                            onChange={val => {
                                this.setState({createAdmin: ''})
                                this.props.setFieldValue('judges', this.props.values.judges.concat(val));
                            }}
                            remote={true}
                            urlRoute={"/search"}
                            remoteKey={'users'}
                            displayValue={"email"}
                            displayJoinValue={"path"}
                            displayJoin={true}
                            imageKey={"image"}
                            onType={e => {
                                this.setState({createAdmin: e.target.value})
                            }}

                        />
                    </Col>

                    <div className={"spacer"} />
                    <div className={"divider"}/>
                    <div className={"spacer"} />
                </Row>


                <Row>
                    <Col md={12}>
                        <div className={"text-right"}>
                            <Button variant={"secondary"} type={"submit"} onClick={e => this.props.handleSubmit(this.props.values)}>Save</Button>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    events: state.events,
    single: state.single
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    save: (id, d, token) => {
        //console.log
        dispatch(eventActions.patch("events/simplePatch", id, d, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(withFormik({
    mapPropsToValues: (props) => ({
        judges: props.events.event ? props.events.event.judges ? props.events.event.judges : [] : []
    }),
    validate: values => {

    },
    handleSubmit: (values, {props, setSubmitting}) => {
        //console.log
        props.save(props.match.params.id, values, props.auth.token._id)
    }
})(AddJudges)));

class AddJudgesContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default AddJudgesContainer;