

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import GeneralTile from '../../Common/GeneralTile';
import Row from "react-bootstrap/Row";
import placeholder from '../../../assets/images/placeholder-person.png';
import * as tableActions from '../../../store/actions/table';
import { PersonOutline } from 'react-ionicons';
import Col from "react-bootstrap/Col";
import PageLoader from "../../Common/PageLoader";


class Dancers extends React.Component {

    dancers = [
        {
            title: 'Some Person',
            group: 'U15',
            entries: 4
        },
        {
            title: 'Some Person',
            group: 'U15',
            entries: 4
        },
        {
            title: 'Some Person',
            group: 'U15',
            entries: 4
        }
    ]

    events = [
        {
            title: 'Event 1',
            group: 'U15',
            date: 'Jan 21, 20201',
            registering: true,
            entries: 4
        },
        {
            title: 'Event 2',
            group: 'U15',
            date: 'Jan 7, 20201',
            registering: true,
            entries: 4
        },
        {
            title: 'Event 3',
            group: 'U15',
            date: 'March 13, 20201',
            registering: false,
            entries: 4
        }
    ]

    componentDidMount(){
        //this.props.getDancersPage(this.props.auth.token._id);
        this.props.loadData('dancers');
        this.props.get(this.props.auth.token._id);
        if (this.props.user.user.roles.indexOf('teacher') > -1) {
            this.props.getStudents(this.props.auth.token._id);
        }
    }

    render() {
        return (
            <div className={"page-container-slim"}>
                <PageLoader loaded={!this.props.table.tableLoading}>
                    <div className={'page-header'}>
                        <Row>
                            <Col>

                                <h1><PersonOutline /> My Dancer Profiles</h1>
                            </Col>
                            <Col>

                            </Col>
                        </Row>
                    </div>
                    <div className={"section"}>

                        <div className={"tile-grid"}>
                            <Row>
                                {this.props.table.data.map((d, i) => {
                                    return <GeneralTile collection={"dancers"}  index={i} {...d} key={i} data={d} placeholder={placeholder} />
                                })}
                                <GeneralTile new={true} index={this.props.table.data.length} onAddNew={e => this.props.history.push('/app/dancers/new')} />
                            </Row>
                        </div>
                    </div>

                    {this.props.user.user.roles.indexOf('teacher') > -1 ?
                        <div>
                            <div className={'page-header'}>
                                <Row>
                                    <Col>

                                        <h1><PersonOutline /> My Students</h1>
                                    </Col>
                                    <Col>

                                    </Col>
                                </Row>
                            </div>
                            <div className={"section"}>

                                <div className={"tile-grid"}>
                                    <Row>
                                        {
                                            this.props.table.students ?
                                                this.props.table.students.map((d, i) => {
                                                    return <GeneralTile collection={"dancers"} {...d} key={i} index={i + this.props.table.data.length} data={d} placeholder={placeholder} />
                                                }) : <h4 className={"no-data"}>No Students</h4>}
                                    </Row>
                                </div>
                            </div>
                        </div>
                        : null}
                </PageLoader>



            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    get: (token) => {
        dispatch(tableActions.getTableData("dancers", 0, 99, {}, token, "add"));
    },
    getStudents: (token) => {
        dispatch(tableActions.getStudents(0, 99, {}, token, "add"));
    },
    loadData: (view) => {

    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Dancers));

class DancersContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected/>
            </div>
        )
    }
}

export default DancersContainer;
