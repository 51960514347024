

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import Button from "react-bootstrap/Button";
import { ArrowBack } from "react-ionicons";
import Select from "../../Common/Select";
import {Scrollbar} from "react-scrollbars-custom";
import * as reportActions from '../../../store/actions/reports';
import Dancer from "./Dancers/Dancer";


class Dancers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            title: '',
            sort: null,
            filter: null,
            showModal: false,
            data: {},
            search: '',
            grade: {},
            competition: {},
            initialized: false
        }
    }

    maxDepth = 0;

    init = () => {
        this.setState({grade: this.props.reports.grades[0], competition: this.props.reports.competitions.filter(c => c.grade === this.props.reports.grades[0]._id)[0]})
    }

    componentDidMount(props){
        let page = 1;
        let itemsPerPage = 10;
        let skip = (itemsPerPage * (page - 1));

        //console.log("Getting items for", view, this.props.location.pathname);
        //if (this.props.table.view !== view) {


        this.props.getTableDataWithQuery("events/dancers", skip, itemsPerPage, {createdAt: -1}, {event: this.props.match.params.id}, this.props.auth.token._id, 'add');
        this.props.getCompetitions(this.props.match.params.id, this.props.auth.token._id);
        if (this.props.reports.grades.length > 0 && this.props.reports.competitions.length > 0) {
            this.init();
        }
        //}
        //}
    }

    componentDidUpdate(props, state) {

        if (state.sort !== this.state.sort || state.filter !== this.state.filter || this.state.grade._id !== state.grade._id || this.state.competition._id !== state.competition._id) {
            let query = {event: this.props.match.params.id};
            let sort = {code: 1};
            if (this.state.grade._id) {
                query.grade = this.state.grade._id;
            }

            if (this.state.competition._id) {
                query.competition = this.state.competition._id;
            }

            this.props.getTableDataWithQuery("events/dancers", 0, 10, sort, query, this.props.auth.token._id, 'add');


        }

    }

    dragEnd = (res) => {
        //console.log("DROPPED ", res);
    }

    remove = (id) => {
        this.props.deleteItem(this.props.table.view, id, this.props.auth.token._id);
    }

    edit = (id) => {
        if (this.props.table.data.filter(d => d._id === id).length > 0) {
            this.props.handleFormModalWithData(this.props.table.data.filter(d => d._id === id)[0]);
        }
    }

    save = (e, items) => {
        let page = this.props.match.params.page ? this.props.match.params.page : 1;
        this.props.reorder(this.props.table.view, page, items, this.props.auth.token._id);
    }

    markTop = (e, item) => {
        //console.log("Marking", item, e.target);
        let filteredItems = this.props.table.data.filter(d => d._id === item);
        if (filteredItems.length > 0) {
            let item = filteredItems[0];
            item.topStory = !item.topStory;
            this.props.updateTableItem("news", item, this.props.auth.token._id);
        }


    }

    loadMore = () => {
        let query = {event: this.props.match.params.id};
        let sort = {createdAt: -1};
        if (this.state.sort) {
            sort = this.state.sort.sort;
        }

        if (this.state.grade._id) {
            query.grade = this.state.grade._id;
        }

        if (this.state.competition._id) {
            query.competition = this.state.competition._id;
        }

        this.props.getTableDataWithQuery("events/dancers", this.props.table.data.length, 10, sort, query, this.props.auth.token._id, 'update');

    }

    handleAboutToReachBottom = () => {
        if (this.props.table.hasMore) {
            this.loadMore();
        }
    }
    handleUpdate = (values) => {
        const { scrollTop, scrollHeight, clientHeight } = values;
        const pad = 100; // 100px of the bottom
        // t will be greater than 1 if we are about to reach the bottom
        const t = ((scrollTop + pad) / (scrollHeight - clientHeight));
        //console.log(t, this.props.table.hasMore);
        if (t > 1) this.handleAboutToReachBottom();
    }

    search = () => {
        let query = {event: this.props.match.params.id};
        let sort = {createdAt: -1};
        if (this.state.sort) {
            sort = this.state.sort.sort;
        }

        if (this.state.filter) {
            query.filter = this.state.filter.id;
        }

        if (this.state.search) {
            query.search = this.state.search;
        }

        this.props.getTableDataWithQuery("events/dancers", 0, 10, sort, query, this.props.auth.token._id, 'add');

    }

    filters = [
        {
            id: 'all',
            name: 'All',
        },
        {
            id: 'completed',
            name: 'Completed',
        },
        {
            id: 'incompleted',
            name: 'Incomplete',
        }
    ]

    sort = [

        {
            id: 'compdesc',
            name: 'Newest First',
            sort: {createdAt: -1}
        },
        {
            id: 'compasc',
            name: 'Oldest First',
            sort: {createdAt: 1}
        }
    ]

    render() {
        return (
            <div>
                <div className={'page-header'}>
                    <Row>
                        <Col sm={2} className={"pt-3"}>
                            <h1>
                                {this.props.location.pathname.split("/").length > 3 ?
                                    <Button variant={"flat btn-round"} onClick={e => {

                                        this.props.history.push(`/app/event-dashboard/${this.props.match.params.id}/stats`);
                                    }}>
                                        <ArrowBack />
                                    </Button>
                                    : null}
                            </h1>
                        </Col>
                        <Col sm={4}>


                        </Col>

                        <Col sm={3}>
                            <Select
                                disableTyping={true}
                                items={this.props.reports.grades}
                                value={this.state.grade || {}}
                                itemValue={"_id"}
                                onChange={val => {
                                    this.setState({grade: val});
                                    //this.setState({sort: val});
                                }}
                                displayValue={"title"}
                                label={"Grades"}

                            />
                        </Col>

                        <Col sm={3}>
                            <Select
                                disableTyping={true}
                                items={this.state.grade._id ? this.props.reports.competitions.filter(c => c.grade === this.state.grade._id) : this.props.reports.competitions}
                                value={this.state.competition || ''}
                                itemValue={"_id"}
                                onChange={val => {
                                    this.setState({competition: val});
                                }}
                                displayValue={"code"}
                                label={"Competitions"}

                            />
                        </Col>
                    </Row>
                </div>

                {this.props.table.data ?

                    <Scrollbar className={'feis-page-scroller'} noScrollX={true} onUpdate={this.handleUpdate}
                               style={{height: 'calc(100vh - 290px)'}}>
                        {this.props.table.view === "events/dancers" && this.props.table.data ?
                            this.props.table.data.map ?
                                this.props.table.data.map((d, i) => {
                                    return <Dancer data={d} key={i} />
                                })
                                : null
                            : null
                        }
                        {this.props.table.hasMore ?
                            <div className={"text-center"}>
                                <Button onClick={this.loadMore}>Load More</Button>
                            </div>

                            : null}

                    </Scrollbar>
                    : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    reports: state.reports
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    getCompetitions: (event, token) => {
        dispatch(reportActions.getCompetitions(event, token));
    }
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Dancers));

class DancersContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default DancersContainer;
