

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import * as tableActions from '../../../store/actions/table';
import FormButtons from "../../Common/FormButtons";
import Card from './Card';
import Bound from '../../Common/Bound';


class CardList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: []
        }
    }

    componentDidMount(props) {
        this.props.getTableData("cards", 0, 999, {number: 1}, this.props.auth.token._id, 'add');
    }
    componentDidUpdate(props, state) {

    }

    render() {
        return (
            <div>
                <FormButtons
                    confirm={(e) => {
                        //this.handleSubmit(values);
                    }}
                    back={(e) => {
                        e.preventDefault();
                        //this.step(values, "previous");
                        this.setState({addingCard: false})
                    }}
                    showPrevious={this.state.addingCard}
                    showRevert={false}
                    showNext={false}
                    next={(e) => {
                        e.preventDefault();
                        //this.handleSubmit(values);
                    }}
                    showDefault={this.state.addingCard}
                    showPlus={!this.state.addingCard}
                    add={e => {
                        this.props.setAdding(true)
                    }}
                    label={'Payment Methods'}

                />



                    {this.props.table.data ?
                        this.props.table.data.map((d, i) => {
                            return (
                                <Bound key={i}>
                                    <Card data={d} key={i} />
                                </Bound>


                            )
                        })
                    : null}


            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(CardList));

class CardListContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default CardListContainer;