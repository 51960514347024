

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from "react-bootstrap/Col";
import * as tableActions from '../../../store/actions/table';
import urls from "../../../utils/urls";
import dayjs from "dayjs";
import * as singleActions from "../../../store/actions/single";
import { ArrowBack } from "react-ionicons";
import Button from "react-bootstrap/Button";

class SingleUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            preview: ''
        }
    }

    componentDidMount(props) {
        this.init();
        if(this.props.single.single.user) {
            if (this.props.single.single.user.image) {
                this.setState({preview: urls.getS3Url(this.props.single.single.user.image.thumb || this.props.single.single.user.image.path)})
            }
        }

    }

    componentDidUpdate(props, state) {
        if (this.props.single.single._id !== this.props.match.params.id) {
            //this.init();
        }
    }

    init = () => {
        this.props.get(this.props.match.params.id, this.props.auth.token.token._id);
    }


    getDances = () => {

    }

    render() {
        return this.props.single.single._id === this.props.match.params.id ? (
            <div className={'elevated rounded ex-pad'}>
                <div className={"page-header"}>
                    <Row>
                        <Col md={6}>
                            <h1 className={"text-capitalize"}>
                                <Button variant={"flat btn-round"} onClick={e => {

                                    this.props.history.push("/app/updates");
                                }}>
                                    <ArrowBack />
                                </Button>
                                {this.props.single.single.type} version {this.props.single.single.version} Released</h1>
                        </Col>
                        <Col sm={6} className={"text-right"}>
                            {this.props.user.user.role === 'admin' ?
                                <Button variant={"flat"} onClick={e => {

                                    this.props.history.push(`/app/updates`);
                                }}>Delete</Button>
                                : null}
                                &nbsp;
                            {this.props.user.user.role === 'admin' ?
                                <Button variant={"primary"} onClick={e => {
                                    this.props.history.push(`/app/updates/edit/${this.props.match.params.id}`);
                                }}>Edit Update</Button>
                                : null}
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row>
                        <Col md={12} className={"text-hint text-left price-container"}>
                            Posted on {dayjs(this.props.single.single.createdAt).format("MMM DD YYYY @ hh:mm A")}<br />
                            Updated on {dayjs(this.props.single.single.updatedAt).format("MMM DD YYYY @ hh:mm A")}


                            <div>
                                {/*this.props.single.single.user ?
                                <div className={'mini-tag'}>
                                    <div className={"news-item-img mini-tag-image"} style={{background: this.state.preview ? `url(${this.state.preview}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)` : `url(${placeholder}), linear-gradient(0deg, rgba(0,0,0,.6), rgba(0,0,0,.4), rgba(0,0,0,.2), transparent)`}}></div>
                                    <div className={'mini-tag-title'}>{this.props.single.single.user.name} {this.props.single.single.user.lname}</div>
                                </div>
                                    : null*/}
                            </div>

                        </Col>

                    </Row>
                    <Row>
                        <Col md={12}>
                            <div className={"spacer"}/>
                            <div className={'divider'} />
                            <div className={'spacer'} />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h5>Summary</h5>
                            <div>
                                {this.props.single.single.description || 'N/A'}
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className={"spacer"}/>
                            <div className={'divider'} />
                            <div className={'spacer'} />
                        </Col>
                        <Col md={12}>
                            <h5>Changelog</h5>
                            <div dangerouslySetInnerHTML={{__html: this.props.single.single.content}}>

                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
        ) : null;
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    table: state.table,
    single: state.single,
    user: state.user
});

const mapDispatchToProps = dispatch => ({
    getTableData: (view, skip, limit, sort, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, {}, token, mode));
    },
    getTableDataWithQuery: (view, skip, limit, sort, query, token, mode) => {
        dispatch(tableActions.getTableDataWithQuery(view, skip, limit, sort, query, token, mode));
    },
    get: (id, token) => {
        dispatch(singleActions.getSingle('updates', id, token));
    },
});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(SingleUpdate));

class SingleUpdateContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default SingleUpdateContainer;
