

const defaultState = {
    files: []
};

const files = (state = defaultState, action) => {
    switch (action.type) {
        case 'LOGOUT':
            return defaultState;
        case 'ADD_FILES':
            return {
                ...state,
                files: state.files.concat(action.payload)
            }
        default:
            return state
    }
}

export default files;