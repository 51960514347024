

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Autocomplete from 'react-autocomplete';
import placeholder from "../../assets/images/placeholder1.png";
import { Trash } from 'react-ionicons';
import Button from "react-bootstrap/Button";
import Bound from './Bound';
import Visa from '../../assets/images/visa.png';
import Mastercard from '../../assets/images/mastercard.png';
import Amex from '../../assets/images/american_express.png';
import Discover from '../../assets/images/discover.png';


const menuStyle = {
    boxShadow: '0 5px 8px rgba(0,0,0,.3)',
    background: 'rgba(255, 255, 255, 0.9)',
    padding: '2px 0',
    fontSize: '90%',
    position: 'fixed',
    overflow: 'auto',
    maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
    minWidth: '220px',
    marginLeft: '-36px',
    marginTop: '14px',
    borderRadius: '10px'
};

const logos = {
    Visa: Visa,
    MasterCard: Mastercard,
    "American Express": Amex,
    "Discover": Discover
}

class Select extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            menuOpen: false,
            value: '',
        }
    }


    render() {
        if (this.props.items) {
            return (
                <Bound>
                    <div className={"object-picker-container-top"}>
                        {this.props.label ?
                            <label>{this.props.label}</label>
                            : null
                        }


                        <Autocomplete
                            getItemValue={(item) => item[this.props.itemValue]}
                            wrapperStyle={{display: 'block'}}
                            className={"object-picker-parent"}
                            items={this.props.selectedItems ? this.props.items.filter(item => this.props.selectedItems.indexOf(item._id) === -1) : this.props.items}
                            onMenuVisibilityChange={open => this.setState({menuOpen: open})}
                            selectOnBlur={true}
                            renderItem={(item, isHighlighted, ind) =>

                                <div key={item._id || item[this.props.itemValue]} className={`object-picker-menu-item ${isHighlighted ? 'hovered' : ''}`}
                                     onClick={e => this.setState({menuOpen: false})}>
                                    <div className={"object-menu-item-image"}
                                         style={{background: item ? item.type ? `url(${logos[item.type]})` : `url(${placeholder})` : `url(${placeholder})`}}/>
                                    <div className={"object-menu-item-label"}>{"****-****-****-" + item[this.props.displayValue]}</div>
                                </div>

                            }
                            renderInput={(props) => {
                                if (this.props.items) {
                                    let relevant = [];
                                    if (this.props.itemValue && this.props.value) {
                                        relevant = this.props.items.filter(i => i[this.props.itemValue] === this.props.value[this.props.itemValue]);
                                    }
                                    return (
                                        <div className={"object-picker-container"}>
                                            <div className={"object-picker-image"}>
                                                <div className={"object-image"}
                                                     style={{background: relevant.length > 0 ? relevant[0].type ? `url(${logos[relevant[0].type]})` : `url(${placeholder})` : `url(${placeholder})`}}/>
                                            </div>
                                            <div className={"object-picker-input"}>
                                                <input
                                                    onClick={e => this.setState({menuOpen: true})} {...props}
                                                    onChange={e => {
                                                        if (!this.props.disableTyping) {
                                                            this.setState({value: e.target.value})
                                                        }
                                                    }}
                                                    value={relevant.length > 0 ? "****-****-****-" + relevant[0][this.props.displayValue] : ''}/>
                                            </div>
                                            {this.props.showDelete ?
                                                <Button variant={'flat'} className={"btn-small-padding"} onClick={() => this.props.onDelete(this.props.value)}><Trash fontSize={'18px'} {...{color: 'rgba(0,0,0,.5)'}} /></Button>
                                                : null}
                                        </div>

                                    )
                                }
                            }}

                            renderMenu={(items, value, style) => {
                                return (
                                    <div className={`object-picker-menu ${this.state.menuOpen ? 'open' : ''}`}
                                         style={{...style, ...menuStyle}} children={items}/>
                                )
                            }}
                            autoHighlight={true}
                            value={this.state.value}
                            onChange={(e) => {
                                this.props.onChange(e.target.value);
                                this.setState({value: e.target.value})
                            }}
                            onSelect={(val) => {
                                let relevant = [];
                                if (this.props.itemValue) {
                                    relevant = this.props.items.filter(i => i[this.props.itemValue] === val);
                                }
                                if (relevant.length > 0) {
                                    this.props.onChange(relevant[0])
                                }
                                this.setState({value: ''})
                            }}
                        />


                    </div>
                </Bound>


            )
        } else {
            return null
        }
    }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({

});

const Connected = withRouter(connect(mapStateToProps, mapDispatchToProps)(Select));

class SelectContainer extends React.Component {
    render() {
        return (
            <div>
                <Connected {...this.props} />
            </div>
        )
    }
}

export default SelectContainer;