

const defaultState = {
    socket: null
};

const socket = (state = defaultState, action) => {
    switch (action.type) {
        case 'CONNECTED_SOCKET':
            return {
                ...state,
                socket: action.payload
            }
        case 'LOGOUT':
            return {
                ...defaultState
            }
        default:
            return {
                ...state
            };
    }
}

export default socket;